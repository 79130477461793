
type WangCompanies = {
  [key: string]: string;
}

// WANG 
// HACER QUE ESTO SEA UN ENDPOINT Y TAMO GUCCI
export const WANG_COMPANIES: WangCompanies = {
	'2': 'Electrónica',
	'3': 'Comestible',
	'4': 'Mascotas',
	'5': 'Maquillaje',
	'6': 'Librería',
	'7': 'Bijouterie',
	'8': 'Decoración y Bazar'
};
