import React, {useContext } from 'react';
import { Box, Button, Text, Flex } from '@chakra-ui/react';

import '@fontsource/work-sans';
import { CartContext } from '../../contexts/CartContext';
import { useTranslation } from '../../contexts/TranslationContext';

interface Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  state: number;
}

export default function CartFooter(props: Props) {

	const cartContext = useContext(CartContext);
	const { translations, language } = useTranslation();

	return (
		<Flex position={'fixed'} justify={'space-between'} top={'90%'} w={'100%'} backgroundColor={'white'} py={'5%'} boxShadow="0px -4px 4px -2px rgba(0, 0, 0, 0.2)" paddingX={'4%'}>
			<Box>
				<Text fontFamily={'Work sans'} fontSize={'16'}>{translations['Total']?.[language] || 'Total'}</Text>
				<Text fontFamily={'Work sans'} fontWeight={'bold'}>{cartContext.getCartCurrency()} {cartContext.totalPrice.toLocaleString()}</Text>
			</Box>

			{ props.state === 1 ?
				<Button
					onClick={() => props.handleNextStep()}
					width={'20%'}
					paddingY={'5%'}
					fontWeight={'normal'}
					fontFamily={'Work sans'}
					colorScheme='#4C7D2C'
					bg={'#4C7D2C'}
				> {translations['Next']?.[language] || 'Siguiente'} </Button>
				:
				<Flex justifyContent={'space-between'}>
					<Button
						onClick={() => props.handlePreviousStep()}
						width={props.state === 3 ? '40%' : '45%'}
						paddingY={'5%'}
						fontWeight={'normal'}
						fontFamily={'Work sans'}
						colorScheme='#4C7D2C'
						bg={'#4C7D2C'}
					> {translations['Previous']?.[language] || 'Anterior'} </Button>
					<Button
						type='submit'
						onClick={() => props.handleNextStep()}
						width={props.state === 3 ? '50%' : '45%'}
						paddingY={'5%'}
						fontWeight={'normal'}
						fontFamily={'Work sans'}
						colorScheme='#4C7D2C'
						bg={'#4C7D2C'}
					> { props.state === 3 ? translations['Send order']?.[language] || 'Enviar pedido' : translations['Next']?.[language] || 'Siguiente'} </Button>
				</Flex>
			}
		</Flex>
	);
}
