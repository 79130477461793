import {useState} from 'react';
import { Box } from '@chakra-ui/react';

import { useMediaQuery } from 'react-responsive';
import '@fontsource/work-sans';

import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';

import CartMobile from '../components/Cart/CartMobile';
import CartDesktop from '../components/Cart/CartDesktop';
import PostOrder from '../components/PostOrderComponent';
import NavBar from '../components/NavBar';


export default function Cart() {

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	const [success, setSuccess] = useState(false);
	const [createdOrder, setCreatedOrder] = useState({});

	return (
		<Box backgroundColor={'#F5F3F3'} zIndex={0}>
			<NavBar isHome={false}/>
			{
				!success ? (
					(isMobile || isTablet || isSmallScreen) ? <CartMobile setSuccess={setSuccess} setCreatedOrder={setCreatedOrder}/> : (isLargeScreen && <CartDesktop setSuccess={setSuccess} setCreatedOrder={setCreatedOrder}/>)
				) : <PostOrder setSuccess={setSuccess} createdOrder={createdOrder} />
			}
		</Box>
	);
}
