export const termsAndConditions = {
	spanish: {
		title: 'TÉRMINOS Y CONDICIONES DE LA TIENDA ONLINE',
		introduction: 'Bienvenido a WANG.AR. Al utilizar nuestra plataforma de venta online, usted acepta y se compromete a cumplir con los siguientes Términos y Condiciones.',
		definitions: {
			title: 'DEFINICIONES',
			product: '"Producto" se refiere a cualquier artículo ofrecido en la Tienda, incluyendo accesorios tecnológicos, comestibles y otros productos.',
			price: '"Precio" se refiere al costo del Producto más cualquier cargo adicional, como envío y manejo.',
			order: '"Pedido" se refiere a la solicitud de compra de uno o más Productos realizada por el cliente.',
			customer: '"Cliente" se refiere a la persona que realiza un Pedido en la Tienda.'
		},
		purchaseConditions: {
			title: 'CONDICIONES DE COMPRA',
			age: '1. Edad y capacidad: Debe tener al menos 18 años y tener capacidad legal para realizar compras en línea.',
			registration: '2. Registro: Debe registrarse en la Tienda antes de realizar un Pedido de no ser así se le solicitará obligatoriamente el número de dni para poder realizar la compra.',
			accurateInfo: '3. Información precisa: Debe proporcionar información precisa y actualizada al registrarse y realizar un Pedido.',
			payment: '4. Pago: Debe pagar el Precio total del Pedido antes de que se procese la orden.'
		},
		shippingPolicy: {
			title: 'POLÍTICA DE ENVÍO',
			shippingTime: '1. Tiempo de envío: Los Pedidos se envían dentro de los 2-5 días hábiles después de la confirmación del pago.',
			shippingCost: '2. Costo de envío: El costo de envío se calcula según el peso y destino del Pedido.',
			tracking: '3. Seguimiento: Se proporciona un número de seguimiento para rastrear el Pedido.'
		},
		returnPolicy: {
			title: 'POLÍTICA DE DEVOLUCIONES Y REEMBOLSOS',
			returnPeriod: '1. Plazo de devolución: Los Productos pueden devolverse dentro de los 7 a 15 días después de la recepción.',
			returnConditions: '2. Condiciones de devolución: Los Productos deben estar en su condición original y con etiquetas intactas.',
			refund: '3. Reembolso: Se reembolsa el Precio del Producto, menos cualquier cargo de envío.'
		},
		privacyPolicy: {
			title: 'POLÍTICA DE PRIVACIDAD',
			dataProtection: '1. Protección de datos: La Tienda protege la información personal del Cliente según la ley aplicable.',
			dataUsage: '2. Uso de datos: La Tienda utiliza la información del Cliente para procesar Pedidos y mejorar la experiencia del usuario.'
		},
		liability: {
			title: 'LIMITACIÓN DE RESPONSABILIDAD',
			damages: '1. Daños y perjuicios: La Tienda no se responsabiliza por daños o perjuicios resultantes del uso de los Productos.',
			errors: '2. Errores: La Tienda no se responsabiliza por errores en la descripción de los Productos o en el proceso de Pedido.'
		},
		applicableLaw: {
			title: 'LEYES APLICABLES',
			law: 'Estos Términos y Condiciones se rigen por las leyes de ARGENTINA. Cualquier disputa se resolverá a través de la mediación o arbitraje.'
		},
		changes: {
			title: 'CAMBIOS EN LOS TÉRMINOS Y CONDICIONES',
			modification: 'La Tienda se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento.'
		},
		acceptance: {
			title: 'ACEPTACIÓN',
			acceptanceText: 'Al utilizar la Tienda, usted acepta y se compromete a cumplir con estos Términos y Condiciones.'
		},
		contact: {
			title: 'CONTACTO',
			contactText: 'Para cualquier consulta o reclamo, por favor contacte con nosotros a través de wang.ar710@gmail.com'
		},
		version: {
			title: 'VERSIÓN',
			versionText: 'Versión 1.0, 01/10/2024'
		},
		confirm: {
			text: 'Al hacer clic en "Aceptar", usted confirma que ha leído y aceptado estos Términos y Condiciones.'
		}
	},
	chinese: {
		title: '在线商店的条款和条件',
		introduction: '欢迎访问WANG.AR。使用我们在线销售平台即表示您接受并同意遵守以下条款和条件。',
		definitions: {
			title: '定义',
			product: '“产品” 指的是在商店中提供的任何商品，包括科技配件、食品和其他产品。',
			price: '“价格” 指的是产品的成本以及任何附加费用，如运费和手续费。',
			order: '“订单” 指的是客户提出购买一个或多个产品的请求。',
			customer: '“客户” 指的是在商店中下订单的人。'
		},
		purchaseConditions: {
			title: '购买条件',
			age: '1. 年龄和能力：您必须年满18岁，并且具备法律能力进行在线购买。',
			registration: '2. 注册：您在下订单之前必须在商店注册，否则必须提供身份证号码才能进行购买。',
			accurateInfo: '3. 准确的信息：您在注册和下订单时必须提供准确且最新的信息。',
			payment: '4. 支付：您必须在订单处理之前支付订单的全部价格。'
		},
		shippingPolicy: {
			title: '运输政策',
			shippingTime: '1. 发货时间：订单将在支付确认后2-5个工作日内发货。',
			shippingCost: '2. 运费：运费根据订单的重量和目的地计算。',
			tracking: '3. 追踪：提供追踪号码以跟踪订单。'
		},
		returnPolicy: {
			title: '退货和退款政策',
			returnPeriod: '1. 退货期限：产品可在收到后7至15天内退货。',
			returnConditions: '2. 退货条件：产品必须保持原始状态，并且标签完好。',
			refund: '3. 退款：将退还产品的价格，但不包括任何运费。'
		},
		privacyPolicy: {
			title: '隐私政策',
			dataProtection: '1. 数据保护：商店根据适用法律保护客户的个人信息。',
			dataUsage: '2. 数据使用：商店使用客户的信息来处理订单并改善用户体验。'
		},
		liability: {
			title: '责任限制',
			damages: '1. 损害赔偿：商店不对因使用产品而导致的损害或损失负责。',
			errors: '2. 错误：商店不对产品描述或订单处理中的错误负责。'
		},
		applicableLaw: {
			title: '适用法律',
			law: '本条款和条件受阿根廷法律管辖。任何争议将通过调解或仲裁解决。'
		},
		changes: {
			title: '条款和条件的更改',
			modification: '商店保留随时修改本条款和条件的权利。'
		},
		acceptance: {
			title: '接受',
			acceptanceText: '使用商店即表示您接受并同意遵守这些条款和条件。'
		},
		contact: {
			title: '联系方式',
			contactText: '如有任何问题或投诉，请通过wang.ar710@gmail.com联系我们。'
		},
		version: {
			title: '版本',
			versionText: '版本1.0，2024年10月1日'
		},
		confirm: {
			text: '点击“接受”即表示您确认已阅读并接受这些条款和条件。'
		}
	}
};
  