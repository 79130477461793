import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, Container, Flex, Image, useDisclosure, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';


import NavBar from '../components/NavBar';
import OrderStatus from '../components/Orders/OrderStatus';
import MobileHeader from '../components/MobileHeader';

import noOrdersFound from '../assets/noOrdersFound.png';
import noOrdersFoundMobile from '../assets/noOrdersFoundMobile.png';

import { getDataLocal } from '../helpers/LocalStorage';
import { getOrderTime, getOrderDate } from '../helpers/TimeParser';
import { useTranslation } from '../contexts/TranslationContext';

import { SwrOrderList } from '../interfaces/interfaces';
import { getOrdersByUser, updateOrderStatus } from '../api/orders';

import { fireAlert } from '../helpers/Tich/TichAlert';

export default function Orders() {

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	const { translations, language } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [orders, setOrders] = useState<any[]>([]);
	const [orderId, setOrderId] = useState<number>(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [orderIsCanceled, setOrderIsCanceled] = useState(false);
	const navigate = useNavigate();

	const getOrders = async () => {
		const user = await getDataLocal('user');
		// console.log("user ", user);

		const userOrders = await getOrdersByUser(user.id);
		if (userOrders.data) {
			setOrders(userOrders.data);
		}
		setIsLoading(false);
	};

	const handdleCancelOrderButton = (orderId: number) => {
		setOrderId(orderId);
		onOpen();
	};

	const updateOrderState = async (orderId: number, newStatus: number) => {
		const updatedOrder = await updateOrderStatus(orderId, newStatus);
		if (updatedOrder) {
			fireAlert(translations['The order has been cancelled']?.[language] || 'El pedido ha sido cancelado', 'success');
			setOrderId(0);
			setOrderIsCanceled(!orderIsCanceled);
		}
		onClose();
	};

	const OrderUpdateVerifyModal = () => {
		return(
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent w={'xs'} mt={'10%'}>
					<ModalHeader fontSize={22}>{translations['Cancel order']?.[language] || 'Cancelar pedido'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontSize={19} textAlign={'center'}>{translations['Are you sure you want to cancel the order?']?.[language] || '¿Esta seguro que quiere cancelar el pedido?'}</Text>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={() => updateOrderState(orderId, 6)}>
							{translations['Confirm']?.[language] || 'Confirmar'}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	useEffect(() => {
		getOrders();
	}, [orderIsCanceled]);

	useEffect(() => {
		setIsLoading(true);
		getOrders();
	}, []);

	const desktopFront = () => {
		return (
			<Box zIndex={0} minHeight={'100vh'} bg={'#F5F5F5'} paddingBottom={'5%'}>
				<NavBar isHome={false} />

				<Container pt={'20vh'} maxW={'155vh'}>
					<Text pb={'12'} fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'} color='#1E1E1E'>{translations['My orders']?.[language] || 'Mis pedidos'}</Text> {/* Mis pedidos */}

					<VStack spacing={5}>
						{
							isLoading ?
								<Flex justifyContent={'center'} mt='10%'>
									<Spinner size={'xl'} />
								</Flex>
								:
								orders.length > 0 ? orders.map((order: SwrOrderList) => {
									return (

										<Box key={order.id} w={'100%'} p={5} borderRadius={5} bg={'#FFFFFF'}>
											<Flex py='2' direction={'row'} justifyContent={'space-between'}>
												<Flex direction={'column'} alignItems={'flex-start'} cursor={'pointer'} onClick={() => navigate(`/orderDetails/${order.id}`, { state: order })}>
													<Text mb={'3'} fontWeight={'semibold'} fontFamily={'Work sans'} color='#1E1E1E'>{getOrderDate(order.date)} {getOrderTime(order.time)}</Text>
													<Text mb={'8'} fontWeight={'semibold'} fontFamily={'Work sans'} color='#1E1E1E'>{translations['Order']?.[language] || 'Pedido'} #{((order.companyId).toString()).padStart(4, '0') + '-' + ((order.requestId).toString()).padStart(6, '0')} </Text>
													<Text fontWeight={'medium'} fontFamily={'Work sans'} color='#1E1E1E'>{translations['Total products']?.[language] || 'Total de productos'}: {order.totalProducts}</Text>
												</Flex>
												<Flex direction={'column'} alignItems={'flex-end'}>
													<OrderStatus orderStatus={order.status} />
													<Text mb={'3'} fontWeight={'bold'} fontSize={'22'} fontFamily={'Work sans'} color='#1E1E1E'>ARS ${order.totalPrice}</Text>
													{
														order.status != 6 &&
													<Box cursor={'pointer'}>
														<Text fontWeight={'bold'} fontSize={'14'} color={'#FD5756'} fontFamily={'Work sans'} onClick={() => handdleCancelOrderButton(order.id)}>{translations['Cancel order']?.[language] || 'Cancelar pedido'}</Text>
													</Box>
													}
												</Flex>
											</Flex>
										</Box>
									);
								
								
								}) :
							
									<Box>
										<Flex justify={'center'} align={'center'}>
											<Image src={noOrdersFound} h={'30vh'}/>
										</Flex>
										<Text marginY={'5%'} textAlign={'center'} fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'3vh'}>{translations['You have no orders yet']?.[language] || 'Aún no tiene ningún pedido'}</Text>
										<Text textAlign={'center'} fontFamily={'Work sans'} fontSize={'2.3vh'}>{translations['Add all the items you want to your shopping cart and follow the steps to complete the purchase']?.[language] || 
										'Añada todos los artículos que desee a su carrito de compras y siga los pasos para completar la compra'}</Text>
										{/* <Text textAlign={'center'} fontFamily={'Work sans'} fontSize={'2.3vh'}>siga los pasos para completar la compra</Text> */}
									</Box>
						}
					</VStack>

				</Container>
			</Box>
		);
	};

	const mobileFront = () => {
		return (
			<Box zIndex={0} minHeight={'100vh'} bg={'#F5F5F5'} paddingBottom={'10%'}>
				<NavBar isHome={false} />
				<MobileHeader buttonName='' buttonColor='' buttonAction={() => console.log('nada')} title={translations['My orders']?.[language] ||'Mi pedido'} goBackTo='/' screenID={1} />

				<Container pt={'20vh'} maxW={'155vh'}>
					<VStack spacing={5}>
						{

							isLoading ?
								<Flex justifyContent={'center'} mt='10%'>
									<Spinner size={'xl'} />
								</Flex>
								:
								orders.length > 0 ? orders.map((order: SwrOrderList) => {

									return (
										<Box key={order.id} backgroundColor={'#FFFFFF'} width={'90%'} borderRadius={5} paddingX={'3%'} paddingY={'2%'}>
											<Flex justify={'space-between'} flex={0.3}>

												<Box onClick={() => navigate(`/orderDetails/${order.id}`, { state: order })}>
													<Flex justify={'space-between'} marginBottom={'3%'}>
														<Text fontWeight={'bold'} fontFamily={'Work sans'}>{getOrderDate(order.date)}</Text>
														<Text fontWeight={'bold'} fontFamily={'Work sans'}>{getOrderTime(order.time)}</Text>
													</Flex>

													<Text fontWeight={'bold'} fontFamily={'Work sans'}> {translations['Order number']?.[language] || 'Número de pedido'} #{((order.companyId).toString()).padStart(4, '0') + '-' + ((order.requestId).toString()).padStart(6, '0')} </Text>
													<Text fontFamily={'Work sans'} marginTop={'5%'}>{translations['Total products']?.[language] || 'Total de productos'}: {order.totalProducts}</Text>
												</Box>

												<Box flex={0.7}>
													<OrderStatus orderStatus={order.status} />
													<Text onClick={() => navigate(`/orderDetails/${order.id}`, { state: order })} fontFamily={'Work sans'} fontWeight={'bold'} marginTop={'10%'} fontSize={'2vh'}>ARS ${order.totalPrice}</Text>
													{
														order.status != 6 &&
													<Box cursor={'pointer'}>
														<Text fontWeight={'bold'} fontSize={'14'} color={'#FD5756'} fontFamily={'Work sans'} onClick={() => handdleCancelOrderButton(order.id)}>{translations['Cancel order']?.[language] || 'Cancelar pedido'}</Text>
													</Box>
													}
												</Box>

											</Flex>
										</Box>
									);
								})

									:

									<Box>
										<Flex justify={'center'} align={'center'}>
											<Image src={noOrdersFoundMobile} h={'20vh'}/>
										</Flex>
										<Text marginY={'5%'} textAlign={'center'} fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'2.7vh'}>{translations['You have no orders yet']?.[language] || 'Aún no tiene ningún pedido'}</Text>
										<Text textAlign={'center'} fontFamily={'Work sans'} fontSize={'2.0vh'}>{translations['Add all the items you want to your shopping cart and follow the steps to complete the purchase']?.[language] || 
										'Añada todos los artículos que desee a su carrito de compras y siga los pasos para completar la compra'}</Text>
										{/* <Text textAlign={'center'} fontFamily={'Work sans'} fontSize={'2.0vh'}>siga los pasos para completar la compra</Text> */}
									</Box>
						}

					</VStack>
				</Container>
			</Box>
		);
	};


	return (
		<Box w="100%" backgroundColor="white" borderBottomWidth={'2px'}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
			<OrderUpdateVerifyModal/>
		</Box>
	);
}
