import { toast } from 'react-toastify';

// type: success / error
export function fireAlert(title: string, type: string, isHome?: boolean) {
	if (type !== 'success' && type !== 'error') return;
	if (type === 'success') {
		toast.success(title, {
			position: isHome ? 'bottom-center' :'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: 'light',
		});
		return;
	}
	toast.error(title, {
		position: isHome ? 'bottom-center' :'top-center',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
		theme: 'light',
	});
}
