import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Flex, Text, Stack, Button, HStack, Link} from '@chakra-ui/react';

import '@fontsource/work-sans';

import { useTranslation } from '../../contexts/TranslationContext';
import { CartContext } from '../../contexts/CartContext';
import CartProductComponent from '../Product/CartProductComponent';
import CartStepperComponent from '../CartStepperComponent';
import { WANG_COMPANIES } from '../../variables/companyNamesById';
import CartFormDesktop from './CartFormDesktop';
import AlertComponent from '../AlertComponent';
import { ruleValidationPasses } from '../../helpers/Tich/TichRulesHelper';
import { InfoProvided } from '../../interfaces/interfaces';
import tichMessageTypes from '../../variables/Tich/tichMessageTypes';
import { useNavigate } from 'react-router-dom';

interface Props {
	update: boolean,
	setUpdate: React.Dispatch<React.SetStateAction<boolean>>
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>
	setCreatedOrder: React.Dispatch<React.SetStateAction<object>>
}

export default function CartStepper(props: Props) {

	const navigate = useNavigate();
	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);
	const cartList = cartContext.cartList;

	type StepperState = {
		currentStep: number;
		totalSteps: number;
	};

	const initialState: StepperState = {
		currentStep: 1,
		totalSteps: 3,
	};

	const [state, setState] = useState(initialState);

	const handleNextStep = async () => {
		if (state.currentStep === 2) {
			const successfullValidation = await cartContext.validateBusinessRules(true);
			if (!successfullValidation) return;
		}
		setState((prevState) => ({
			...prevState,
			currentStep: Math.min(prevState.currentStep + 1, prevState.totalSteps),
		}));
	};

	const handlePreviousStep = () => {
		setState((prevState) => ({
			...prevState,
			currentStep: Math.max(prevState.currentStep - 1, 1),
		}));
	};

	useEffect(() => {
		cartContext.cartExtraInfo();
	}, []);

	return (
		<Box height={'100%'} borderRadius={9} >

			<Box backgroundColor={'white'} borderRadius='8px' borderWidth={1} borderColor={'#D7D7D7'} height={'100%'} paddingBottom={'5%'}>

				<CartStepperComponent currentStep={state.currentStep} totalSteps={state.totalSteps} setState={setState} nextStep={handleNextStep} previousStep={handlePreviousStep} />
				{
					state.currentStep === 1 &&
					<Box marginTop={'2%'}>
						{
							cartContext.cartInfo.map((info: InfoProvided, pos: number) =>(
								!ruleValidationPasses(info.type, cartContext.currentCartRules) ?
									<Box key={pos} my={'1vh'} mx={'3vh'}>
										<AlertComponent
											message={info.message}
											type={tichMessageTypes[info.type as keyof typeof tichMessageTypes]}
											canOpen={!info.important}
											hasHTMLMessage={info.htmlFormat}
										/>
									</Box>
									: null
							))
						}
						<Flex width={'30vh'} justify={'space-between'} align={'center'} marginLeft={'3%'} marginTop={'2%'}>
							<Text fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'2vh'}> {translations['Cart']?.[language] || 'Carrito'} ({cartContext.numberOfProducts})</Text>
							<Text
								onClick={() => { cartContext.emptyCart(); props.setUpdate(!props.update); }}
								fontFamily={'Work sans'}
								color={'#8150E2'}
								fontWeight={'bold'}
								fontSize={'1.7vh'}
								cursor={'pointer'}
							>
								{translations['Clear cart'][language]}
							</Text>
						</Flex>
						<Box paddingX={'3%'} marginTop={'4%'} paddingBottom={'5%'}>
							{
								Object.keys(cartList).map((companyId: string, index) => {
									if(cartList[companyId].length === 0) return null;
									return ( 
										<Box key={companyId}>
											<Text fontFamily={'Work sans'} fontSize={'2vh'} fontWeight={'bold'}> {WANG_COMPANIES[companyId]} </Text>
											{
												cartList[companyId].map((product, index) => (
													<CartProductComponent key={product.product.id} item={product} index={index} setUpdate={props.setUpdate} update={props.update} />
												))
											}
											{
												index < Object.keys(cartList).length - 1 && <Divider my='5' borderWidth="1.5px" />
											}
										</Box>
									);
								})
							}
						</Box>
					</Box>
				}
				{
					//Se puede optimizar meter esto en un json y su tipo de gravedad tmb
					state.currentStep === 2 &&
					<Stack px={'5'} spacing={3} my='15px'>
						{
							cartContext.preOrderInfo.map((info: InfoProvided, pos: number) =>(
								<Box key={pos}>
									<AlertComponent
										message={info.message}
										type={tichMessageTypes[info.type as keyof typeof tichMessageTypes]}
										canOpen={!info.important}
										hasHTMLMessage={info.htmlFormat}
									/>
								</Box>
							))
						}
						<Box height={0.5} borderWidth={1} ></Box>
						<Link color="#0993ff" onClick={() => navigate('/refund')} textDecor="underline" style={{ cursor: 'pointer' }}>
							{translations['Refund Policy']?.[language] || 'Política de reembolso'}
						</Link>
					</Stack>
				}

				{
					state.currentStep === 3 &&
					<CartFormDesktop previousStep={handlePreviousStep} setCreatedOrder={props.setCreatedOrder} setSuccess={props.setSuccess} />
				}

				<Flex justify={'flex-end'} px={'3%'} align={'center'}>
					{
						state.currentStep === 1 &&
						<Box mr={'5%'} minW={'20vh'}>
							<Text fontFamily={'Work sans'} color={'#747474'} fontWeight={'bold'} fontSize={'2vh'}> {translations['Total']?.[language] || 'Total'} </Text>
							<Text fontWeight={'bold'} fontFamily={'Work sans'} fontSize={'2.5vh'}>{cartContext.getCartCurrency()} {cartContext.totalPrice.toLocaleString()} </Text>
						</Box>
					}
					{
						state.currentStep !== 3 &&
						<HStack justifyContent={'center'} borderWidth={'0px'} >
							{
								state.currentStep !== 1 &&
								<Button
									onClick={() => handlePreviousStep()}
									// w={'25%'}
									py={'6'}
									fontWeight={'normal'}
									fontFamily={'Work sans'}
									colorScheme='#4C7D2C'
									bg={'#4C7D2C'}
								> {translations['Previous']?.[language] || 'Anterior'} </Button>
							}
							<Button
								onClick={() => handleNextStep()}
								// w={'40%'}
								py={'6'}
								fontWeight={'normal'}
								fontFamily={'Work sans'}
								colorScheme='#4C7D2C'
								bg={'#4C7D2C'}
							> {translations['Next']?.[language] || 'Siguiente'} </Button>
						</HStack>
					}
				</Flex>
			</Box>
		</Box>
	);

}
