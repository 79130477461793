import { Box, Button, Divider, Flex, FormErrorMessage, FormLabel, Input, FormControl, VStack, HStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { getDataLocal } from '../../helpers/LocalStorage';
// import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { useTranslation } from '../../contexts/TranslationContext';
import { fireAlert } from '../../helpers/Tich/TichAlert';
import { sendOrder } from '../../helpers/OrderHelper';
import { CartUserIds } from '../../interfaces/interfaces';

interface Props {
	previousStep: () => void;
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>
	setCreatedOrder: React.Dispatch<React.SetStateAction<object>>
}

export default function CartFormDesktop(props: Props) {
	// const navigate = useNavigate();
	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);
	const userLocal = getDataLocal('user');
	
	const userInfo = userLocal && userLocal.isLogged ? userLocal : null;
	const {
		handleSubmit,
		register,
		clearErrors,
		formState: { errors }
	} = useForm();

	async function onSubmit(formValues: any) {
		const tempId = getDataLocal('tempId');
		const userIds: CartUserIds = {};
		
		if(userLocal && userLocal.isLogged) userIds.userId = userLocal.id;
		if(tempId) userIds.tempId = tempId;
		const orderCreated: any = await sendOrder(formValues,userIds);
		if (orderCreated) {
			props.setCreatedOrder(orderCreated);
			cartContext.emptyCart();
			fireAlert(translations['The order has been successfully shipped']?.[language], 'success');
			props.setSuccess(true);
			// navigate('/');
		} else {
			fireAlert(translations['An error occurred while trying to send the order. Please try again']?.[language], 'error');
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Flex paddingX='20px' paddingTop='20px' flexDirection='column' >
				<VStack align='stretch' spacing={5} mt='2vh'>
					<Flex direction='row' justifyContent='space-between'>
						<Box backgroundColor={'white'} flex="1" mr='1vw'>
							<FormControl isInvalid={Boolean(errors.username)} isRequired >
								<FormLabel htmlFor="username" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> { translations['Name']?.[language] || 'Nombre'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>  {/* Nombre de empresa */}
								<Input required id='username' fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px'
									defaultValue={userInfo?.username}
									onInput={() => clearErrors('createOrder')}
									{...register('username', {
										required: true
									})}
								/>
								{errors.username && (
									<FormErrorMessage>
										{translations['Please'][language] + ', ' + translations['Enter a valid name'][language]}
									</FormErrorMessage>
								)}
							</FormControl>
						</Box>

						<Box flex="1">
							<FormControl isInvalid={Boolean(errors.taxId)} isRequired>
								<FormLabel htmlFor="taxId" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> CUIL/CUIT/DNI ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
								<Input required id="taxId" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px'
									defaultValue={userInfo?.taxId}
									onInput={() => clearErrors('createOrder')}
									{...register('taxId', {
										required: true
									})}
								/>
								{errors.taxId && (
									<FormErrorMessage>
										{translations['Please']?.[language] + ', ' + translations['Enter a valid CUIL/CUIT/DNI']?.[language]}
									</FormErrorMessage>
								)}
							</FormControl>
						</Box>
					</Flex>

					<Box backgroundColor={'white'} flex="1" ml='1vw'>
						<FormControl isInvalid={Boolean(errors.address)} isRequired>
							<FormLabel htmlFor="address" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['Address']?.[language] || 'Dirección'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
							<Input required id='address' fontFamily={'Work Sans'} min={4} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px'
								defaultValue={userInfo?.address}
								onInput={() => clearErrors('createOrder')}
								{...register('address', {
									required: true
								})}
							/>
							{errors.address && (
								<FormErrorMessage>
									{translations['Required field']?.[language] + translations['Please']?.[language] + ', ' + translations['Enter a valid address']?.[language]}
								</FormErrorMessage>
							)}
						</FormControl>
					</Box>

					<Box flex="1" mr='5px'>
						<FormControl isInvalid={Boolean(errors.location)} isRequired>
							<FormLabel htmlFor="location" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['City/Province']?.[language] || 'Localidad/Provincia'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
							<Input required id="location" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px' 
								defaultValue={userInfo?.location}
								onInput={() => clearErrors('createOrder')}
								{...register('location', {
									required: true
								})}
							/>
							{errors.location && (
								<FormErrorMessage>
									{translations['Required field']?.[language] + translations['Please']?.[language] + ', ' + translations['Enter a valid city or province']?.[language]}
								</FormErrorMessage>
							)}
						</FormControl>
					</Box>

					<Flex direction='row' justifyContent='space-between'>
						<Box flex="1" mr='1vw'>
							<FormControl isInvalid={Boolean(errors.phone)} isRequired>
								<FormLabel htmlFor="phone" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['Cellphone number']?.[language] || 'Número de celular'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
								<Input required id="phone" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px'
									defaultValue={userInfo?.phone}
									onInput={() => clearErrors('createOrder')}
									{...register('phone', {
										required: true
									})}
								/>
								{errors.phone && (
									<FormErrorMessage>
										{translations['Required field']?.[language] + translations['Please']?.[language] + translations['Enter a valid cellphone number']?.[language]}
									</FormErrorMessage>
								)}
							</FormControl>
						</Box>
						<Box flex="1">
							<FormControl isInvalid={Boolean(errors.postalCode)} isRequired>
								<FormLabel htmlFor="postalCode" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['Postal code']?.[language] || 'Código postal'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
								<Input required id="postalCode" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px'
									defaultValue={userInfo?.postalCode}
									onInput={() => clearErrors('createOrder')}
									{...register('postalCode', {
										required: true
									})}
								/>
								{errors.postalCode && (
									<FormErrorMessage>
										{translations['Required field']?.[language] + translations['Please']?.[language] + translations['Enter a valid postal number']?.[language]}
									</FormErrorMessage>
								)}
							</FormControl>
						</Box>
					</Flex>

					<Box flex="1">
						<FormControl isInvalid={Boolean(errors.email)} isRequired>
							<FormLabel htmlFor="email" color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['Email']?.[language] || 'Correo electrónico'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
							<Input required id="email" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='text' borderWidth='1.5px'
								defaultValue={userInfo?.email}
								onInput={() => clearErrors('createOrder')}
								{...register('email', {
									required: true
								})}
							/>
							{errors.email && (
								<FormErrorMessage>
									{translations['Required field']?.[language] + translations['Please']?.[language] + translations['Enter a valid email']?.[language]}
								</FormErrorMessage>
							)}
						</FormControl>
					</Box>

					<Divider borderWidth='1px' borderColor='#D7D7D7' alignContent='center' my='20px' />

					{/* <Text> WhatsApp 或 微信 (任选一个填写，外省客户只用WhatsApp 联系) </Text> */}
					{/* <Flex direction='row' justifyContent='space-between' alignItems='end'> */}
					{/* <Box flex="1" mr='1vw'>
							<FormControl isInvalid={Boolean(errors.wechat)}>
								<FormLabel htmlFor="wechat" fontFamily={'Work sans'} noOfLines={2} fontWeight='500'> 微信ID号 </FormLabel>
								<Input id="wechat" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='tel' borderWidth='1.5px'
									defaultValue={userInfo?.wechat}
									onInput={() => clearErrors('createOrder')}
									{...register('wechat')}
								/>
								{errors.wechat && (
									<FormErrorMessage>
									</FormErrorMessage>
								)}
							</FormControl>
						</Box> */}
					{/* </Flex> */}
					<Box flex="1" ml='1vw'>
						<FormControl isInvalid={Boolean(errors.whatsapp)} isRequired>
							<FormLabel htmlFor="whatsapp" color={'#5F5F5F'} fontFamily={'Work sans'} noOfLines={2} fontWeight='500'> WhatsApp ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
							<Input required id="whatsapp" fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='number' borderWidth='1.5px'
								defaultValue={userInfo?.whatsapp}
								onInput={() => clearErrors('createOrder')}
								{...register('whatsapp',{
									required: true
								})}
							/>
							{errors.whatsapp && (
								<FormErrorMessage>
									{translations['Please']?.[language] + ', ' + translations['Enter a valid WhatsApp number']?.[language]}
								</FormErrorMessage>
							)}
						</FormControl>
					</Box>

					<Divider borderWidth='1px' borderColor='#D7D7D7' alignContent='center' my='40px' />

					<Flex direction='row' justifyContent='space-between'>
						<Box flex="1" mr='1vw'>
							<FormControl isInvalid={Boolean(errors.transport)}>
								<FormLabel htmlFor='transport' color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['Transport company']?.[language] || 'Empresa de transporte'} </FormLabel>
								<Input id='transport' fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} type='tel' borderWidth='1.5px'
									defaultValue={userInfo?.transport}
									onInput={() => clearErrors('createOrder')}
									{...register('transport')}
								/>
								{errors.transport && (
									<FormErrorMessage>
										{/* Por favor, ingrese un valor valido */}
									</FormErrorMessage>
								)}
							</FormControl>
						</Box>
						<Box flex="1">
							<FormControl isInvalid={Boolean(errors.description)}>
								<FormLabel htmlFor='description' color={'#5F5F5F'} fontFamily={'Work sans'} fontWeight='500'> {translations['Description']?.[language] || 'Descripción'} </FormLabel>
								<Input id='description' fontFamily={'Work sans'} height={'3.5vh'} borderColor={'#8E8E8E'} borderWidth='1.5px'
									defaultValue={userInfo?.description}
									onInput={() => clearErrors('createOrder')}
									{...register('description')}
								/>
								{errors.description && (
									<FormErrorMessage>
										{/* Por favor, ingrese un nombre valido */}
									</FormErrorMessage>
								)}
							</FormControl>
						</Box>
					</Flex>

				</VStack>
				<HStack py={6} justifyContent={'end'} borderWidth={'0px'}>
					<Button
						onClick={() => props.previousStep()}
						py={'6'}
						fontWeight={'normal'}
						fontFamily={'Work sans'}
						colorScheme='#4C7D2C'
						bg={'#4C7D2C'}
					> {translations['Previous']?.[language] || 'Anterior'} </Button>
					<Button
						type='submit'
						w={'25%'}
						py={'6'}
						fontWeight={'normal'}
						fontFamily={'Work sans'}
						colorScheme='#4C7D2C'
						bg={'#4C7D2C'}
						marginTop={'3vh'}
						alignSelf='flex-end'
					> {translations['Send order']?.[language] || 'Enviar pedido'} </Button>
				</HStack>
			</Flex>
		</form>
	);
}
