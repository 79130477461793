/* eslint-disable array-callback-return */
import React, { ReactNode, createContext, useState, useEffect } from 'react';
import { addToCart, getCart, deleteProductFromCart, updateProductFromCart, removeAllCartProducts, validateCartRules, getCartExtraInfo } from '../api/cart';
import { getDataLocal, removeDataLocal, saveDataLocal } from '../helpers/LocalStorage';
import { CartContextProps, CartProductDB, CartRules, CompanyProducts, InfoProvided, Product, UserCartByCompany } from '../interfaces/interfaces';
import { fireAlert } from '../helpers/Tich/TichAlert';
import { showRulesAlert } from '../helpers/Tich/TichRulesHelper';


const defaultValue: CartContextProps = {
	cartList: {},
	numberOfProducts: 0,
	totalPrice: 0,
	currentCartRules: {allRulesOK: true, rulesResult: []},
	cartInfo: [{message: 'Loading...', type: 'default', important: true, htmlFormat: false}],
	preOrderInfo: [{message: 'Loading...', type: 'default', important: true, htmlFormat: false}],
	addItem: async () => { return false; },
	removeItem: () => { return; },
	emptyCart: () => { return; },
	addOneToProductQuantity: () => { return; },
	updateItemQuantity: () => Promise.resolve(false),
	validateBusinessRules: async () => Promise.resolve(false),
	calculateTotalPrice: () => { return; },
	getCartCurrency: () => '',
	getUserCart: async () => { return; },
	deleteLocalCartInfo: () => { return; },
	cartExtraInfo: async () => {return;},
	localCart: {}
};

const CartContext = createContext<CartContextProps>(defaultValue);


type Props = {
	children: ReactNode;
}

export default function CartProvider({ children }: Props) {

	// ESTRUCTURA DEL CART EN BACK
	const [cartList, setCartList] = useState<UserCartByCompany>(defaultValue.cartList);

	// ESTRUCTURA CART LOCAL
	const [localCart, setLocalCart] = useState<CompanyProducts>(defaultValue.localCart);

	//Reglas
	const [currentCartRules, setCurrentCartRules] = useState<CartRules>(defaultValue.currentCartRules);

	const [numberOfProducts, setNumberOfProducts] = useState<number>(defaultValue.numberOfProducts);
	const [totalPrice, setTotalPrice] = useState<number>(defaultValue.totalPrice);
	const [cartInfo, setCartInfo] = useState<InfoProvided[]>(defaultValue.cartInfo);
	const [preOrderInfo, setPreOrderInfo] = useState<InfoProvided[]>(defaultValue.preOrderInfo);

	// useEffect(() => {
	// 	loadLocalCart();
	// }, []);

	//Se llama cuando cambia el cart (desde la pantalla de cart, desde otra no se debe llamar)
	useEffect(() => {
		setCartData();
	}, [localCart]);


	function setCartData() {
		calculateNumberOfProducts();
		calculateTotalPrice();
	}

	const calculateNumberOfProducts = () => {
		let total = 0;
		// console.log('cart par acalcular number es', localCart);

		Object.keys(localCart).map((companyId) => {
			Object.values(localCart[companyId]).map((prodQty: number) => {
				total += prodQty;
			});
		});
		setNumberOfProducts(total);
	};

	// 1) Itero los companyId de localCart (o cartList, es lo mismo)
	// 2) Itero los productos de cartList para obtener el price (cartList es array, localCart es object asi que mejor iterar cartList)
	// 3) En cada iteracion obtengo el priceWholesale y accedo al qty del localCart
	// Esto funca SOLO para tich (ya que solo contempla el priceWholesale), hay que optimizarlo desde back para revenderlo
	const calculateTotalPrice = () => {
		let totalPrice = 0;

		//Itera companies
		// console.log('local cart es',localCart);
		
		Object.keys(localCart).forEach((companyId: any) => {
			// La prox linea es porque si agregas desde home el producto, no lo tiene el cartList. 
			// Igual este total nunca se muestra, porque al entrar a la vista de cart se recalcula bien

			//itera productos
			if(!cartList[companyId]) return;
			cartList[companyId].forEach((product: CartProductDB) => {
				// La prox linea es un || 0 porque si agregas desde home el producto, no lo tiene el cartList. 
				// Igual este total nunca se muestra, porque al entrar a cart se recalcula bien
				const productPriceWholesale = product.product?.priceWholesale || 0;
				totalPrice = totalPrice + (productPriceWholesale * localCart[companyId][product.product.id]);
			});
		});
		setTotalPrice(totalPrice);
		// console.log('total', totalPrice);
	};

	// Esto ver cuando usar, es un get al pedo por ahora

	// const loadLocalCart = async () => {
	// 	const userDataLocal = await getDataLocal('user');
	// 	const tempId = await getDataLocal('tempId');
	// 	const cartWithId: any = {};
	// 	if ((!userDataLocal && !tempId) || (userDataLocal && tempId)) {
	// 		fireAlert('Hubo un error al obtener su carrito', 'error');
	// 		return;
	// 	}

	// 	if (userDataLocal && !tempId) {
	// 		cartWithId.userId = userDataLocal.id;
	// 	}
	// 	if (!userDataLocal && !tempId) {
	// 		cartWithId.tempId = tempId;
	// 	}

	// 	const cartAux = await getCart(cartWithId);
	// 	if (!cartAux.ok || cartAux.data === undefined) return;

	// 	const cart = cartAux.data;

	// 	const localCartAux: CompanyProducts = {};


	// 	Object.keys(cart).map(companyID => {

	// 		localCartAux[companyID] = {};

	// 		cart[companyID].map(cartItem => {
	// 			localCartAux[companyID][cartItem.product.id] = cartItem.quantity;
	// 		});
	// 	});

	// 	setLocalCart(localCartAux);
	// 	const totalProds = calculateNumberOfProducts(cart);
	// 	setNumberOfProducts(totalProds);
	// };

	const loadLocalCart = async (cart: UserCartByCompany) => {
		const localCartAux: CompanyProducts = {};
		Object.keys(cart).map(companyID => {

			localCartAux[companyID] = {};

			cart[companyID].map(cartItem => {
				localCartAux[companyID][cartItem.product.id] = cartItem.quantity;
			});
		});

		setLocalCart(localCartAux);
	};

	// Carga cartList del back, y el cartLocal con las qty. Se llama de momento en cart en el effect
	const getUserCart = async () => {
		const userDataLocal = await getDataLocal('user');
		const tempId = await getDataLocal('tempId');

		const cartWithId: any = {};
		//Caso que el usuario no logueado nunca creo nada en cart por ende ni tiene tempId
		if(!userDataLocal && !tempId) return;

		//No deberia pasar
		if (userDataLocal && tempId) {
			fireAlert('No se pudo cargar el carrito. Por favor intente nuevamente', 'error');
			return;
		}

		if (userDataLocal && !tempId) {
			cartWithId.userId = userDataLocal.id;
		}
		if (!userDataLocal && tempId) {
			cartWithId.tempId = tempId;
		}

		const cartAux = await getCart(cartWithId);
		if (!cartAux.ok || cartAux.data === undefined || Object.keys(cartAux.data).length <= 0) return;

		// console.log('cartAux.data',cartAux.data);
		
		setCartList(cartAux.data);
		loadLocalCart(cartAux.data);
		validateBusinessRules(false);
	};

	const addItem = async (item: Product, quantity: number) => {  // TODO: AGREGAR FETCH AL BACK QUE ACTUALICE EL CARRITO Y RECUPERARLO EN CART SCREEN

		const userDataLocal = await getDataLocal('user');
		const tempId = await getDataLocal('tempId');

		let addProductWithId: any = { productId: item.id, quantity };


		if ((userDataLocal && tempId)) {
			fireAlert('No se pudo cargar el carrito. Por favor intente nuevamente', 'error',true);
			return false;
		}

		if (userDataLocal && !tempId) {
			addProductWithId = { productId: item.id, quantity, userId: userDataLocal.id };
		}
		if (!userDataLocal && tempId) {
			addProductWithId = { productId: item.id, quantity, tempId: tempId };
		}
		console.log('addProductWithId', addProductWithId);

		const addResponse: any = await addToCart(addProductWithId);
		if(!addResponse.ok){
			if(addResponse.data?.allRulesOK === false) showRulesAlert(addResponse.data);
			return false;
		}
		if (addResponse.data?.product.tempId) {
			saveDataLocal('tempId', addResponse.data.product.tempId);
		}

		// ESTO SE HACE SI FUNCIONO EL UPDATE DE CART EN BACK
		const companyID = item.companyId;
		const localCartCopy = { ...localCart };
		if (companyID in localCartCopy) {  // si ya existe la company revisar si existe el product id y si no existe agregarlo, y si existe cambiarle la cantidad
			const companyData = localCartCopy[companyID];

			//esto esta por si  llega un quantity = 0, equivale a borrarlo del carrito (nod eberia llegar mas, se catchea por front pero lo dejo x las dudas)
			if (quantity === 0) delete companyData[item.id];
			companyData[item.id] = quantity;

		} else {
			if (quantity === 0) return true;
			localCartCopy[companyID] = {};
			localCartCopy[companyID][item.id] = quantity;
		}
		const cartListCopy: any = { ...cartList };
		if (cartListCopy[companyID] === undefined) cartListCopy[companyID] = [];

		cartListCopy[companyID][cartListCopy[companyID].length] = {
			product: item, 
			quantity: quantity
		};

		setCartList(cartListCopy);
		setLocalCart(localCartCopy);
		validateBusinessRules(false);
		fireAlert('Agregado al carrito','success',true);
		return true;
	};

	const validateBusinessRules = async (isPreOrder: boolean) => {

		const userDataLocal = await getDataLocal('user');
		const tempId = await getDataLocal('tempId');

		const validateWithId: any = {};

		if (tempId && !userDataLocal) {
			validateWithId['tempId'] = tempId;
		}

		if (userDataLocal?.isLogged) {
			validateWithId['userId'] = userDataLocal.id;
		}

		const validationResponse = await validateCartRules(validateWithId);
		// console.log('rules',validationResponse);
		
		if(!validationResponse.ok || !validationResponse.data) {
			fireAlert('Error al validar el carrito. Por favor intente nuevamente','error',false);
			return false;
		}
		setCurrentCartRules(validationResponse.data);
		if(!validationResponse.data.allRulesOK) {
			if(isPreOrder) showRulesAlert(validationResponse.data);
			return false;
		}
		return true;
	};

	const removeItem = async (item: Product) => {

		const userDataLocal = await getDataLocal('user');
		const tempId = await getDataLocal('tempId');

		const companyID = item.companyId;
		const localCartCopy = { ...localCart };
		const cartListCopy = { ...cartList };

		const deleteProductWithId: any = { productId: item.id };

		if (tempId) {
			deleteProductWithId['tempId'] = tempId;
		}

		if (userDataLocal?.isLogged) {
			deleteProductWithId['userId'] = userDataLocal.id;
		}

		const deleted = await deleteProductFromCart(deleteProductWithId);

		if (!deleted.ok) {
			fireAlert('No se pudo borrar el producto. Por favor intente nuevamente', 'error');
		}
		delete localCartCopy[companyID][item.id];
		// console.log('pre borrado',cartListCopy[companyID]);
		cartListCopy[companyID] = cartListCopy[companyID].filter((prod: CartProductDB) => prod.product.id !== item.id);	
		// console.log('post borrado',cartListCopy[companyID]);
		
		if (localCartCopy[companyID] !== undefined && Object.keys(localCartCopy[companyID]).length === 0) { // El cart del company esta vacio asi que lo borro de ambos carts
			delete localCartCopy[companyID];
			if (cartList[companyID] !== undefined) {
				delete cartList[companyID];
			}
		}
		// console.log('localCartCopy post agregado',localCartCopy);
		// console.log('cartListCopy', cartList);
	
		if (Object.keys(localCartCopy).length === 0){
			setCartList({});
		}  else {
			setCartList(cartListCopy);
			validateBusinessRules(false);
		}
		setLocalCart(localCartCopy);
		fireAlert('Carrito actualizado', 'success');
		return;
	};

	const emptyCart = async () => {

		const userDataLocal = await getDataLocal('user');
		const tempId = await getDataLocal('tempId');

		const emptyCartWithId: any = {};

		if (tempId && !userDataLocal) {
			emptyCartWithId['tempId'] = tempId;
		}

		if (userDataLocal?.isLogged) {
			emptyCartWithId['userId'] = userDataLocal.id;
		}

		removeAllCartProducts(emptyCartWithId);
		setCartList({});
		setLocalCart({});
		setCurrentCartRules(defaultValue.currentCartRules);
		setNumberOfProducts(0);
		removeDataLocal('tempId');
	};

	const updateItemQuantity = async (item: Product, quantity: number, isHome: boolean) => {

		const userDataLocal = await getDataLocal('user');
		const tempId = await getDataLocal('tempId');
		const companyID = item.companyId;

		const updateWithId: any = { productId: item.id, quantity };

		if (tempId && !userDataLocal) {
			updateWithId['tempId'] = tempId;
		}

		if (userDataLocal?.isLogged) {
			updateWithId['userId'] = userDataLocal.id;
		}
		// console.log('el update es', updateWithId);

		const dbCartUpdate = await updateProductFromCart(updateWithId);

		if (!dbCartUpdate.ok) {
			fireAlert('No se pudo actualizar el carrito', 'error',isHome);
			return false;
		}
		const localCartCopy = { ...localCart };
		// console.log('local cart es', localCartCopy);

		if (localCartCopy[companyID] === undefined) {
			fireAlert('No se pudo actualizar la cantidad del producto. Por favor recargue la página.', 'error',isHome);
			return false;
		}
		console.log('localCartCopy', localCartCopy);

		Object.keys(localCartCopy[companyID]).map(productID => {
			if (productID.toString() === item.id.toString()) {
				localCartCopy[companyID][productID] = quantity;
			}
		});
		setLocalCart(localCartCopy);
		fireAlert('Carrito actualizado', 'success',isHome);
		validateBusinessRules(false);
		return true;
	};

	const deleteLocalCartInfo = () => {
		setLocalCart({});
		setCartList({});
		setNumberOfProducts(0);
		setTotalPrice(0);
	};

	const addOneToProductQuantity = (item: Product) => {
		const companyID = item.companyId;
		const companyData = localCart[companyID];
		companyData[item.id] = companyData[item.id] + 1;
	};

	const getCartCurrency = () => {
		if (Object.keys(cartList).length > 0) {
			// console.log('cartList currency',cartList);
			let currency = ' ?? ';
			Object.keys(cartList).some(companyid => {
				if(cartList[companyid].length !== 0) {
					currency = cartList[companyid][0].product.currency;
					return true;
				} 
			});
			return currency;
		} else {
			return ' ?? ';
		}
	};

	const cartExtraInfo = async () => {
		const responseExtraInfo = await getCartExtraInfo();
		if(!responseExtraInfo.ok || !responseExtraInfo.data){
			fireAlert('No se pudo obtener la información del carrito','error',false);
			return;
		}
		// console.log('responseExtraInfo',responseExtraInfo);
		
		setCartInfo(responseExtraInfo.data.cartInfoProvided);
		setPreOrderInfo(responseExtraInfo.data.preOrderInfoProvided);
	};

	return (
		<CartContext.Provider value={{ cartInfo, preOrderInfo, cartList, numberOfProducts, totalPrice, addItem, removeItem, emptyCart, addOneToProductQuantity, updateItemQuantity, validateBusinessRules, calculateTotalPrice, getCartCurrency, localCart, getUserCart, deleteLocalCartInfo, cartExtraInfo, currentCartRules }}>
			{children}
		</CartContext.Provider>
	);
}
export { CartContext };
