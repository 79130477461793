/* eslint-disable @typescript-eslint/no-unused-vars */
import { saveDataLocal } from '../helpers/LocalStorage';
import { APIResponse, IError, RegisterProps, User, UserProfile } from '../interfaces/interfaces';
import { LoginEndpoint, RegisterEndpoint, UpdateUserEndpoint } from '../variables/Connections';

const possible_errors = [400,404,500];

export async function login(phone: string, password: string): Promise< null | APIResponse> {
	try {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ phone, password }),
		};

		const response = await fetch(LoginEndpoint, requestOptions);
		
		if (response.status === 200) {
			const user = await response.json();
			user.data.isLogged = true;

			saveDataLocal('user', JSON.stringify(user.data));
			saveDataLocal('business', JSON.stringify({ companyID:2 }));  // para cambiar de empresa, cambiar el companyID
			return user;
		} else {
			if(possible_errors.includes(response.status)) {
				const user = await response.json();
				return user;
			}
			return null;
		}

	} catch (error) {
		console.log('error login ', error);
		return null;
	}
}




export async function register(props: RegisterProps): Promise<APIResponse | null> {
	try {

		const jsonBody: RegisterProps = {
			username: props.username,
			password: props.password,
			roleId: props.roleId,
			address: props.address,
			province: props.province,
			taxId: props.taxId,  //CUIL/CUIT o DNI
			phone: props.phone,
			languageId: props.languageId
		};

		if (props.whatsapp !== '') jsonBody.whatsapp = props.whatsapp;
		if (props.wechat !== '') jsonBody.wechat = props.wechat;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(jsonBody),
		};

		const response = await fetch(RegisterEndpoint, requestOptions);

		if (response.ok) {
			const user = await response.json();
			user.data.isLogged = true;

			saveDataLocal('user', JSON.stringify(user.data));
			return user;
		} else {
			if(possible_errors.includes(response.status)) {
				const user = await response.json();
				return user;
			}
			return null;
		}

	} catch (error) {
		console.log('error register ', error);
		return null;
	}
}

export async function updateUser(userProfile: UserProfile): Promise<APIResponse> {
	try{
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(userProfile),
		};

		const response = await fetch(UpdateUserEndpoint, requestOptions);

		if (response.ok) {
			const updateUserResp = await response.json();
			console.log('updateUserResp', updateUserResp);
			return { ok: true, message: updateUserResp.message, status: 0 };
		} else {
			return { ok: false, message: 'No se pudo actualizar el usuario', status: -1 };
		}

	} catch (error) {
		console.log('error updateUser ', error);
		return { ok: false, message: 'No se pudo actualizar el usuario: ' + error, status: -1 };
	}
}

