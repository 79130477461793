import React, { useContext, useState, useEffect } from 'react';
import { Box, Divider, Text, FormErrorMessage, FormLabel, Input, FormControl, Link } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CartStepperComponent from '../CartStepperComponent';
import CartProductComponent from '../Product/CartProductComponent';
import AlertComponent from '../AlertComponent';

import { CartContext } from '../../contexts/CartContext';
import { useTranslation } from '../../contexts/TranslationContext';

import CartFooter from './CartFooter';

import { WANG_COMPANIES } from '../../variables/companyNamesById';
import { CartUserIds, InfoProvided } from '../../interfaces/interfaces';

import { getDataLocal } from '../../helpers/LocalStorage';
import tichMessageTypes from '../../variables/Tich/tichMessageTypes';
import { ruleValidationPasses } from '../../helpers/Tich/TichRulesHelper';
import { fireAlert } from '../../helpers/Tich/TichAlert';
import { sendOrder } from '../../helpers/OrderHelper';

interface Props {
	update: boolean,
	setUpdate: React.Dispatch<React.SetStateAction<boolean>>
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>
	setCreatedOrder: React.Dispatch<React.SetStateAction<object>>
}

export default function CartListMobile(props: Props) {
	// const navigate = useNavigate();
	const initialState: StepperState = {
		currentStep: 1,
		totalSteps: 3,
	};

	const navigate = useNavigate();
	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);
	const cartList = cartContext.cartList;

	const [state, setState] = React.useState(initialState);

	// const [ orderData, setOrderData ] = useState( {} )
	const [userInfo, setUserInfo] = useState(getDataLocal('user') && getDataLocal('user').isLogged ? getDataLocal('user') : null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { handleSubmit, register, setError, clearErrors, formState: { errors, isSubmitting } } = useForm();
	
	type StepperState = {
		currentStep: number;
		totalSteps: number;
	};
	

	const handleNextStep = async () => {

		if (state.currentStep === 3) {
			onSubmit();
		}


		if (state.currentStep === 2) {
			const successfullValidation = await cartContext.validateBusinessRules(true);
			if (!successfullValidation) {
				return;
			}
		}
		setState((prevState) => ({
			...prevState,
			currentStep: Math.min(prevState.currentStep + 1, prevState.totalSteps),
		}));
	};

	const handlePreviousStep = () => {
		setState((prevState) => ({
			...prevState,
			currentStep: Math.max(prevState.currentStep - 1, 1),
		}));
	};

	async function onSubmit() {
		const tempId = getDataLocal('tempId');
		const userIds: CartUserIds = {};
		
		if(userInfo && userInfo.isLogged) userIds.userId = userInfo.id;
		if(tempId) userIds.tempId = tempId;
		const orderCreated: any = await sendOrder(userInfo, userIds);
		console.log('orderCreated', orderCreated);
		if (orderCreated) {
			props.setCreatedOrder(orderCreated);
			cartContext.emptyCart();
			fireAlert(translations['The order has been successfully shipped']?.[language], 'success');
			props.setSuccess(true);
			// navigate('/');
		} else {
			fireAlert(translations['An error occurred while trying to send the order. Please try again']?.[language], 'error');
		}
	}

	useEffect(() => {
		// console.log('translations mobile', translations);
		cartContext.cartExtraInfo();
	}, []);

	return (
		<Box marginTop={'45%'} zIndex={0} w={'100%'}>
			<Box borderRadius='8px' mt='1' mb={'24'} mx='3' borderWidth='2px' borderColor={'#D7D7D7'} shadow={'md'}>
				<CartStepperComponent currentStep={state.currentStep} totalSteps={state.totalSteps} setState={setState} nextStep={handleNextStep} previousStep={handlePreviousStep} />

				{state.currentStep === 1 &&
					<Box backgroundColor={'white'} borderRadius='8px' flex={8} paddingTop='5%'>
						{
							cartContext.cartInfo.map((info: InfoProvided, pos: number) =>(
								!ruleValidationPasses(info.type, cartContext.currentCartRules) ?
									<Box key={pos} marginBottom={'5%'}>
										<AlertComponent
											message={info.message}
											type={tichMessageTypes[info.type as keyof typeof tichMessageTypes]}
											canOpen={!info.important}
											hasHTMLMessage={info.htmlFormat}
										/>
									</Box>
									: null
							))
						}
						<Box paddingX={'3%'} marginTop={'4%'} paddingBottom={'5%'}>
							{
								Object.keys(cartList).map((companyId: string, index) => {
									if(cartList[companyId].length === 0) return null;
									return (
										<Box key={companyId}>
											<Text fontFamily={'Work sans'} color='#5F5F5F' fontSize={'2vh'} fontWeight={'bold'}> {WANG_COMPANIES[companyId]} </Text>
											{
												cartList[companyId].map((product, index) => (
													<CartProductComponent key={product.product.id} item={product} index={index} setUpdate={props.setUpdate} update={props.update} />
												))
											}
											{
												index < Object.keys(cartList).length - 1 && <Divider my='5' borderWidth="1.5px" />
											}
										</Box>
									);
								})
							}
						</Box>
					</Box>
				}

				{state.currentStep === 2 &&

					<Box backgroundColor={'white'} flex={8} paddingTop='5%' borderRadius={'8'}>
						<Box paddingX={'3%'} marginTop={'4%'} paddingBottom={'5%'}>
							{
								cartContext.preOrderInfo.map((info: InfoProvided, pos: number) =>(
									<Box key={pos} marginBottom={'5%'}>
										<AlertComponent
											message={info.message}
											type={tichMessageTypes[info.type as keyof typeof tichMessageTypes]}
											canOpen={!info.important}
											hasHTMLMessage={info.htmlFormat}
										/>
									</Box>
								))
							}
							<Box height={0.5} borderWidth={1} mb={2}></Box>
							<Link color="#0993ff" onClick={() => navigate('/refund')} textDecor="underline" style={{ cursor: 'pointer' }}>
								{translations['Refund Policy']?.[language] || 'Política de reembolso'}
							</Link>
						</Box>
					</Box>
				}

				{state.currentStep === 3 &&
					<Box>
						<Box backgroundColor={'white'} flex={8} paddingY={'5%'} paddingX={'4%'} borderRadius={'8'}>
							<Text fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'2.5vh'}>{translations['Cart']?.[language] || 'Carrito'}</Text>

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.username)} isRequired>
									<FormLabel htmlFor="username" color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Name']?.[language] || 'Nombre'} ({translations['Required']?.[language] || 'Requerido'})</FormLabel>
									<Input required id="username" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.username}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })}
									/>
									{errors.username && (
										<FormErrorMessage>
											{translations['Please']?.[language] + ', ' + translations['Enter a valid name']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.taxId)} isRequired>
									<FormLabel htmlFor="taxId" color={'#5F5F5F'} fontFamily={'Work sans'}> CUIL/CUIT/DNI ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id="taxId" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.taxId}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, taxId: e.target.value })}
									/>
									{errors.taxId && (
										<FormErrorMessage>
											{translations['Please']?.[language] + ', ' + translations['Enter a valid CUIL/CUIT/DNI']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.address)} isRequired>
									<FormLabel htmlFor="address" color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Address']?.[language] || 'Dirección'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id='address' fontFamily={'Work Sans'} min={4} height={'5vh'} borderColor={'#8E8E8E'} borderWidth={'1.5px'} 
										defaultValue={userInfo?.address}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, address: e.target.value })}
									/>
									{errors.address && (
										<FormErrorMessage>
											{translations['Required field']?.[language] + translations['Please']?.[language] + ', ' + translations['Enter a valid address']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" mr='5px' marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.location)} isRequired>
									<FormLabel htmlFor="location" color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['City/Province']?.[language] || 'Localidad/Provincia'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id="location" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.location}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, location: e.target.value })}
									/>
									{errors.location && (
										<FormErrorMessage>
											{translations['Required field']?.[language] + translations['Please']?.[language] + ', ' + translations['Enter a valid city or province']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" mr='5px' marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.postalCode)} isRequired>
									<FormLabel htmlFor="postalCode" color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Postal code']?.[language] || 'Código postal'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id="postalCode" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.postalCode}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, postalCode: e.target.value })}
									/>
									{errors.postalCode && (
										<FormErrorMessage>
											{translations['Required field']?.[language] + translations['Please']?.[language] + ', ' + translations['Enter a valid postal code']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.phone)} isRequired>
									<FormLabel htmlFor="phone" color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Cellphone number']?.[language] || 'Número de celular'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id="phone" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.phone}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
									/>
									{errors.phone && (
										<FormErrorMessage>
											{translations['Required field']?.[language] + translations['Please']?.[language] + translations['Enter a valid cellphone number']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.email)} isRequired>
									<FormLabel htmlFor="email" color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Email']?.[language] || 'Correo electrónico'} ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id="email" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.email}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
									/>
									{errors.email && (
										<FormErrorMessage>
											{translations['Required field']?.[language] + translations['Please']?.[language] + translations['Enter a valid Email']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Divider marginTop={'5%'} borderColor={'#8E8E8E'} />
							{/* <Text fontFamily={'Work sans'} marginBottom={'3%'} marginTop={'5%'} fontSize={'2vh'}>WhatsApp 或 微信 (任选一个填写，外省客户只用WhatsApp 联系)</Text>
							<Box flex="1" mr='1vw' marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.wechat)}>
									<FormLabel htmlFor="wechat" fontFamily={'Work sans'} noOfLines={2}> 微信ID号 </FormLabel>
									<Input
										id="wechat"
										fontFamily={'Work sans'}
										height={'5vh'}
										borderColor={'#8E8E8E'}
										type='tel'
										borderWidth={'1.5px'}
										defaultValue={userInfo?.wechat}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, wechat: e.target.value })}
									/>
									{errors.wechat && (
										<FormErrorMessage>
										</FormErrorMessage>
									)}
								</FormControl>
							</Box> */}

							<Box flex="1" mt={'5%'} mb={'3%'}>
								<FormControl isInvalid={Boolean(errors.whatsapp)} isRequired>
									<FormLabel htmlFor="whatsapp" color={'#5F5F5F'} fontFamily={'Work sans'} noOfLines={2}> WhatsApp ({translations['Required']?.[language] || 'Requerido'}) </FormLabel>
									<Input required id="whatsapp" fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='number' borderWidth={'1.5px'}
										defaultValue={userInfo?.whatsapp}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, whatsapp: e.target.value })}
									/>
									{errors.whatsapp && (
										<FormErrorMessage>
											{translations['Please']?.[language] + ', ' + translations['Enter a valid WhatsApp number']?.[language]}
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>
							<Divider borderColor={'#8E8E8E'} marginBottom={'5%'} marginTop={'5%'} />

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.transport)}>
									<FormLabel htmlFor='transport' color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Transport company']?.[language] || 'Empresa de transporte'} </FormLabel>
									<Input id='transport' fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.transport}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, transport: e.target.value })}
									/>
									{errors.transport && (
										<FormErrorMessage>
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

							<Box flex="1" marginBottom={'3%'}>
								<FormControl isInvalid={Boolean(errors.description)}>
									<FormLabel htmlFor='description' color={'#5F5F5F'} fontFamily={'Work sans'}> {translations['Description']?.[language] || 'Descripción'} </FormLabel>
									<Input
										id='description' fontFamily={'Work sans'} height={'5vh'} borderColor={'#8E8E8E'} type='text' borderWidth={'1.5px'}
										defaultValue={userInfo?.description}
										onInput={() => clearErrors('createOrder')}
										onChange={(e) => setUserInfo({ ...userInfo, description: e.target.value })}
									/>
									{errors.description && (
										<FormErrorMessage>
										</FormErrorMessage>
									)}
								</FormControl>
							</Box>

						</Box>
					</Box>
				}
			</Box>
			<CartFooter handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} state={state.currentStep} />
		</Box>
	);
}
