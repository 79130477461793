import React, { createContext, useState, useContext } from 'react';

interface SearchContextType {
    searchText: string;
    setSearchText: (text: string) => void;
}

const SearchContext = createContext<SearchContextType>({ searchText: '', setSearchText: () => {return;} });

export default function SearchProvider({ children }: { children: React.ReactNode }) {

	const [searchText, setSearchText] = useState('');

	return (
		<SearchContext.Provider value={{ searchText, setSearchText }}>
			{children}
		</SearchContext.Provider>
	);
}

export const useSearchContext = () => useContext(SearchContext);