type ErrorMessages = {
	[key: string]: string;
  }

export const ERROR_MSG: ErrorMessages = {
	'1.1':'请检查输入的信息是否正确 (Cod. 1.1)',
	'1.2':'手机号码不正确',
	'1.3':'密码不正确',
	'2.1':'请检查输入的信息是否正确 (Cod. 2.1)',
	'2.2':'已经有人注册了这个手机号码',
	'2.3':'注册时出错。请稍后再试 (Cod. 2.3)',
};