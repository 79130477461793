/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Box, Flex, Text, VStack, Checkbox, Spinner, HStack, Wrap, WrapItem, Image } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';

import '@fontsource/work-sans';
import { ArrowDown, ArrowUp, XMarkIcon } from '../assets/icons/iconList';

import { getCategories, getProducts } from '../api/products';
import { Category, CategoryCheckBox, CompanyCheckBox, CurrentFilters, CurrentFiltersAux, Product, PromotionsCheckBox } from '../interfaces/interfaces';
import ListOfProducts from '../components/ListOfProducts';
import NavBar from '../components/NavBar';
import { getDataLocal, saveDataLocal } from '../helpers/LocalStorage';
import { WANG_COMPANIES } from '../variables/companyNamesById';
import prod_not_found_pic from '../assets/prod_not_found.png';
import { useTranslation } from '../contexts/TranslationContext';

export default function SearchProduct() {

	const PRODS_PER_PAGE = 60;

	const location = useLocation();
	const { translations, language } = useTranslation();

	const [originalProducts, setOriginalProducts] = React.useState<Product[]>([]);
	const [products, setProducts] = React.useState<Product[]>([]);
	
	const [expandCategories, setExpandCategories] = React.useState(true);

	/* const [expandPrice, setExpandPrice] = React.useState(true);
	const [minPrice, setMinPrice] = React.useState(-1);
	const [maxPrice, setMaxPrice] = React.useState(-1); */

	const [categoriesCheckBox, setCategoriesCheckBox] = React.useState<CategoryCheckBox[]>([]);
	const [companiesCheckBox, setCompaniesCheckBox] = React.useState<CompanyCheckBox[]>([]);
	const [promotionsCheckBox, setPromotionsCheckBox] = React.useState<PromotionsCheckBox[]>([
		{
			group: '1',
			name: '上架货',
			checked: location.state?.group === '1'
		},
		{
			group: '2',
			name: '特价活动',
			checked: location.state?.group === '2'
		},
		{
			group: '4',
			name: '即将上架',
			checked: location.state?.group === '4'
		}
	]);

	const [isLoading, setIsLoading] = React.useState(false);
	const [isLoadingNewPage, setIsLoadingNewPage] = React.useState(false);
	const [currentCompany, setCurrentCompany] = React.useState(-1);

	const [currentSearchFilters, setCurrentSearchFilters] = React.useState<CurrentFiltersAux>({
		companyId: -1,
		page: 1,
		productsQty: PRODS_PER_PAGE,
		categories: [],
		searchText: location.state?.searchTextParam.searchText,
		promotions: [location.state?.group]
	});


	useEffect(() => {
		setCompany();
		loadCheckboxCompanies();
	}, []);

	
	useEffect(() => {
		// console.log('Cambio el search');
		const companyId = currentSearchFilters.companyId === -1 ? 3 : currentSearchFilters.companyId;
		deleteCurrentProducts();
		setCurrentSearchFilters({...currentSearchFilters, companyId: companyId, searchText: location.state?.searchTextParam.searchText, page: 1});
	}, [location.state?.searchTextParam.searchText]);

	useEffect(() => {
		loadCategories();
		// loadGroups();
	}, [currentCompany]);


	useEffect(() => {
		loadProducts();
	}, [ currentSearchFilters ]);


	const setCompany = async () => {
		const business = await getDataLocal('business');
		setCurrentCompany(Number(business.companyID));
		setCurrentSearchFilters(prevCurrFilters => { return { ...prevCurrFilters, companyId: business.companyID }; });
	};

	const updateCurrentCompany = (newCompanyId: string) => {
		setCurrentCompany(Number(newCompanyId));
		// console.log('company es',newCompanyId);
		
		deleteCurrentProducts();
		setCurrentSearchFilters({ ...currentSearchFilters, companyId: Number(newCompanyId), page: 1, categories: []});
		saveDataLocal('business', JSON.stringify({ companyID: newCompanyId.toString() }));
	};

	function deleteCurrentProducts() {
		setProducts([]);
		setOriginalProducts([]);
	}

	//Infinite Scroll
	const observer = React.useRef<IntersectionObserver | null>(null);
	const lastProductElementRef = React.useCallback((node: any) => {

		if (isLoading) return;
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(entries => {
			if (entries[0].isIntersecting) {
				console.log('final!');
				
				setCurrentSearchFilters(prevCurrFilters => { return { ...prevCurrFilters, page: prevCurrFilters.page + 1 }; });
			}
		});

		if (node) observer.current.observe(node);

	}, [isLoading]);


	const loadProducts = async () => {
		
		if (isLoading || currentSearchFilters.companyId === -1) return;
		if (currentSearchFilters.page == 1) setIsLoading(true); else setIsLoadingNewPage(true);

		const business = await getDataLocal('business');
		const companyID = business.companyID;
		currentSearchFilters.companyId = companyID;
		
		console.log('searhccc',currentSearchFilters);
		
		const newProducts = await getProducts(currentSearchFilters);
		
		setProducts([...products, ...newProducts]);
		setOriginalProducts([...products, ...newProducts]);
		if (currentSearchFilters.page == 1) setIsLoading(false); else setIsLoadingNewPage(false);
	};

	const loadCheckboxCompanies = async () => {
		const currentCompanyId = (await getDataLocal('business')).companyID.toString();
		// console.log('currentCompanyId',currentCompanyId);
		const chboxCompanies = Object.keys(WANG_COMPANIES).map((companyid) => ({
			companyid,
			name: WANG_COMPANIES[companyid],
			checked: currentCompanyId === companyid
		}));
		setCompaniesCheckBox(chboxCompanies);
	};

	const loadCategories = async () => {
		const business = await getDataLocal('business');
		const categoriesAux: Category[] = await getCategories({ companyId: business.companyID.toString() });

		const auxCheckBox: CategoryCheckBox[] = [];
		let id = 0;

		categoriesAux.forEach((category: Category) => {
			auxCheckBox.push({ id: id++, label: category.category, isChecked: category.category === 'Todos' });
		});
		
		setCategoriesCheckBox(auxCheckBox);
	};
	const handleCompanyCheckboxChange = (companyid: string) => {
		const updatedCheckboxes = companiesCheckBox.map(checkbox => {
			if (checkbox.companyid === companyid) {
				return {
					...checkbox,
					checked: true,
				};
			}
			return {
				...checkbox,
				checked: false
			};
		});

		setCompaniesCheckBox(updatedCheckboxes);
		updateCurrentCompany(companyid);
	};

	const handlePromotionCheckboxChange = (group: string) => {
		const updatedCheckboxes = promotionsCheckBox.map(checkbox => {
			if (checkbox.group === group) {
				return {
					...checkbox,
					checked: !checkbox.checked,
				};
			}
			return {
				...checkbox,
				checked: false
			};
		});

		setPromotionsCheckBox(updatedCheckboxes);
		const promos: string[] = [];
		updatedCheckboxes.forEach((promo) => {
			if(promo.checked) promos.push(promo.group);
		});
		deleteCurrentProducts();
		setCurrentSearchFilters({ ...currentSearchFilters, promotions: promos,  page: 1 });
	};

	const checkAllAndUncheckRest = (checkbox: CategoryCheckBox, checkboxId: number) => {
		if (checkbox.id === 0) {
			return {
				...checkbox,
				isChecked: true,
			};
		}
		else {
			return {
				...checkbox,
				isChecked: false,
			};
		}
	};
	const checkCorrectCheckbox = (checkbox: CategoryCheckBox, checkboxId: number) => {
		if (checkbox.id === checkboxId) {
			return {
				...checkbox,
				isChecked: !checkbox.isChecked,
			};
		}
		if (checkbox.id === 0) {
			return {
				...checkbox,
				isChecked: false,
			};
		}
		return checkbox;
	};

	const handleCheckboxChange = (checkboxId: number) => {
		let updatedCheckboxes;
		if(checkboxId === 0) updatedCheckboxes = categoriesCheckBox.map((checkbox) => checkAllAndUncheckRest(checkbox, checkboxId));
		else updatedCheckboxes = categoriesCheckBox.map((checkbox) => checkCorrectCheckbox(checkbox, checkboxId));

		setCategoriesCheckBox(updatedCheckboxes);
	};

	const filterProductsByCategories = async () => { 
		if (isLoading) return;
		if (currentSearchFilters.page == 1) setIsLoading(true); else setIsLoadingNewPage(true);

		const checkedCategories : string[]= [];
		categoriesCheckBox.map(checkbox => {
			if (checkbox.isChecked && checkbox.id !== 0) {
				checkedCategories.push(checkbox.label);
			}
		});

		deleteCurrentProducts();
		setCurrentSearchFilters({ ...currentSearchFilters, categories: checkedCategories, page: 1 });

		if (currentSearchFilters.page == 1) setIsLoading(false); else setIsLoadingNewPage(false);
	};

	const unmarkTag = async (categoryToRemove: string) => {
		if (isLoading) return;
		if (currentSearchFilters.page == 1) setIsLoading(true); else setIsLoadingNewPage(true);
		
		const selectedCategories = currentSearchFilters.categories.filter((category) => category !== categoryToRemove);
		const categoryCheckboxToRemove = categoriesCheckBox.find((category) => category.label === categoryToRemove);
		if(!categoryCheckboxToRemove) return; //NO deberia suceder nunca
		handleCheckboxChange(categoryCheckboxToRemove.id);
		if(currentSearchFilters.categories.length === 1) handleCheckboxChange(0);

		deleteCurrentProducts();
		setCurrentSearchFilters({ ...currentSearchFilters, categories: selectedCategories, page: 1 });

		if (currentSearchFilters.page == 1) setIsLoading(false); else setIsLoadingNewPage(false);
	};

	const unmarkPromotionTag = async (promoToRemove: PromotionsCheckBox) => {
		handlePromotionCheckboxChange(promoToRemove.group);
	};


	return (
		<Box>
			<NavBar isHome={false} />
			<Flex backgroundColor={'#F5F3F3'} paddingTop={'15vh'} zIndex={0} justify={'center'} align={'center'} height={'max-content'}>

				<Flex width={'80%'} >
					<Flex width={'100%'} justify={'space-between'} align={'flex-start'}>
						<Box width={'25%'} height={'auto'}>
							<Box marginBottom={'5%'}>
								<Wrap spacing="20px">
									{
										promotionsCheckBox.map((promotion) => {
											if(!promotion.checked) return null;
											return (
												<WrapItem key={promotion.name}  mr='1.5'>
													<Flex cursor={'pointer'}  backgroundColor={'#E3A82A'}borderRadius={5} paddingX={'3'} paddingY={'1'} flexDirection={'row'} alignItems={'center'} onClick={() => unmarkPromotionTag(promotion)}>
														<Text fontFamily={'Work sans'} fontSize={'1.4vh'} fontWeight={'semibold'} mr='1.5' color={'white'}>{promotion.name}</Text>
														<XMarkIcon boxSize={'1.2vh'} color={'lightgray'} />
													</Flex>
												</WrapItem>
											);
										})
									}
									{
										currentSearchFilters.categories.map((category) => {
											return (
												<WrapItem key={category}  mr='1.5'>
													<Flex cursor={'pointer'}  backgroundColor={'#7A5CCE'}borderRadius={5} paddingX={'3'} paddingY={'1'} flexDirection={'row'} alignItems={'center'} onClick={() => unmarkTag(category)}>
														<Text fontFamily={'Work sans'} fontSize={'1.4vh'} fontWeight={'semibold'} mr='1.5' color={'white'}>{category}</Text>
														<XMarkIcon boxSize={'1.2vh'} color={'lightgray'} />
													</Flex>
												</WrapItem>
											);
										})
									}
								</Wrap>
							</Box>
							<Box backgroundColor={'white'} borderRadius={10} border={'1px'} borderColor={'#C8C7C7'} marginBottom={'5%'}>
								<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'} borderBottom={'1px'} borderColor={'#C8C7C7'}>
									<Flex justify={'flex-start'} paddingLeft={'9%'} paddingY={'7%'}>
										<VStack spacing={4}  align={'start'}>
											{companiesCheckBox.map((checkbox) => (
												<Checkbox
													key={checkbox.companyid}
													isChecked={checkbox.checked}
													onChange={() => handleCompanyCheckboxChange(checkbox.companyid)}
													colorScheme='purple'
													fontFamily={'Work sans'}
													textColor={checkbox.checked ? '#7A5CCE' : 'black'}
												>
													{checkbox.name}
												</Checkbox>
											))}
										</VStack>
									</Flex>
								</Flex>
							</Box>
							{/* {
								<Box backgroundColor={'white'} borderRadius={10} border={'1px'} borderColor={'#C8C7C7'} marginBottom={'5%'}>
									<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'} borderBottom={'1px'} borderColor={'#C8C7C7'}>
										<Flex justify={'flex-start'} paddingLeft={'9%'} paddingY={'7%'}>
											<VStack spacing={4}  align={'start'}>
												{promotionsCheckBox.map((checkbox) => (
													<Checkbox
														key={checkbox.group}
														isChecked={checkbox.checked}
														onChange={() => handlePromotionCheckboxChange(checkbox.group)}
														colorScheme='purple'
														fontFamily={'Work sans'}
														textColor={checkbox.checked ? '##7A5CCE' : 'black'}
													>
														{checkbox.name}
													</Checkbox>
												))}
											</VStack>
										</Flex>
									</Flex>
								</Box>

							} */}

							{expandCategories ?

								<Box backgroundColor={'white'} borderRadius={10} border={'1px'} borderColor={'#C8C7C7'} marginBottom={'5%'}>
									<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'} borderBottom={'1px'} borderColor={'#C8C7C7'}>
										<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>{translations['Categories']?.[language] || 'Cateorías'}</Text>
										<ArrowDown boxSize={'2vh'} onClick={() => setExpandCategories(!expandCategories)} cursor={'pointer'} />
									</Flex>
									<Flex backgroundColor={'#F5F3F3'} justify={'flex-start'} paddingLeft={'9%'} paddingY={'7%'}>
										<VStack spacing={4} width={'60%'} align={'start'}>
											{categoriesCheckBox.map((checkbox) => (
												<Checkbox
													key={checkbox.id}
													isChecked={checkbox.isChecked}
													onChange={() => handleCheckboxChange(checkbox.id)}
													colorScheme='purple'
													fontFamily={'Work sans'}
													textColor={checkbox.isChecked ? '#7A5CCE' : 'black'}
												>
													{checkbox.label}
												</Checkbox>
											))}
										</VStack>
									</Flex>
								</Box>

								:

								<Box backgroundColor={'white'} borderRadius={10} border={'1px'} borderColor={'#C8C7C7'} marginBottom={'5%'}>
									<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'}>
										<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>{translations['Categories']?.[language] || 'Cateorías'}</Text>
										<ArrowUp boxSize={'2vh'} onClick={() => setExpandCategories(!expandCategories)} cursor={'pointer'} />
									</Flex>
								</Box>
							}

							{/* {expandPrice ?

								<Box backgroundColor={'white'} borderRadius={10} border={'1px'} borderColor={'#C8C7C7'}>
									<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'} borderBottom={'1px'} borderColor={'#C8C7C7'}>
										<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>价钱</Text>
										<ArrowDown boxSize={'2vh'} onClick={() => setExpandPrice(!expandPrice)} cursor={'pointer'} />
									</Flex>

									<Flex backgroundColor={'#F5F3F3'} justify={'flex-start'} paddingY={'7%'} align={'center'} paddingX={'5%'}>

										<Input
											placeholder={'最低价'}
											fontFamily={'Work sans'}
											fontSize={'1.7vh'}
											width={'45%'}
											border={'1px'}
											borderColor={'#C0C0C0'}
											onChange={(e) => setMinPrice(parseInt(e.target.value))}
										/>

										<Box marginX={'5%'} border={'1px'} borderColor={'#C0C0C0'} height={0} width={'15%'} />

										<Input
											placeholder={'最高价'}
											fontFamily={'Work sans'}
											fontSize={'1.7vh'}
											width={'45%'}
											border={'1px'}
											borderColor={'#C0C0C0'}
											onChange={(e) => setMaxPrice(parseInt(e.target.value))}
										/>

									</Flex>
								</Box>

								:

								<Box backgroundColor={'white'} borderRadius={10} border={'1px'} borderColor={'#C8C7C7'}>
									<Flex justify={'space-between'} paddingX={'5%'} paddingY={'5%'} align={'center'}>
										<Text fontFamily={'Work sans'} fontSize={'1.7vh'} fontWeight={'bold'}>价钱</Text>
										<ArrowUp boxSize={'2vh'} onClick={() => setExpandPrice(!expandPrice)} cursor={'pointer'} />
									</Flex>
								</Box>
							} */}


							<Box
								onClick={() => filterProductsByCategories() }
								backgroundColor={'#4C7D2C'}
								marginTop={'8%'}
								textAlign={'center'}
								borderRadius={7}
								paddingY={'.5vh'}
								width={'100%'}
								cursor={'pointer'}
								marginBottom={'10%'}
							>
								<Text color={'white'} fontSize={'2vh'}>{translations['Filter']?.[language] || 'Filtrar'}</Text>
							</Box>

						</Box>

						<Box flex={1}>
							
							{  isLoading &&
								<Flex marginLeft={'2%'} justifyContent={'center'} py='5%' backgroundColor='white' borderBottomRadius={5}>
									<Spinner size='xl' />
								</Flex>

							}
							{
								!isLoading && products.length !== 0 &&
								<Box flex={1} marginLeft={'2%'} minHeight={'90vh'}>
									<ListOfProducts products={products} originalProducts={originalProducts} isHome={false} isSearch={true} lastProductElementRef={lastProductElementRef} />
								</Box>
							}
							{
								!isLoading && products.length === 0 &&
								<Box flex={1} marginLeft={'2%'} minHeight={'90vh'}>
									<Flex flexDirection={'column'} backgroundColor={'white'} width={'100%'} boxShadow={'md'} paddingX={'1%'} paddingY='25vh' paddingLeft={'3%'}  justify={'flex-start'} borderRadius={8} borderBottomRadius={'1vh'} alignItems={'center'} mt='2vh'>
										<Image src={prod_not_found_pic} alt='Products not found' boxSize={'20vh'} w={'25vh'}/>
										{/* <Text fontWeight={'bold'} fontFamily={'Work sans'} fontSize={20}>No se encontraron resultados</Text> */}
										<Text fontFamily={'Work sans'} color={'gray'} fontSize={17}>{translations['No related results found']?.[language] || 'No se encontraron resultados relacionados'}</Text>
									</Flex>
								</Box>
							}
							{
								isLoadingNewPage &&
								<Flex marginLeft={'2%'} justifyContent={'center'} mt='10%'>
									<Spinner size={'xl'} />
								</Flex>
							}
						</Box>

					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
}
