import React, { useState } from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';

import { useMediaQuery } from 'react-responsive';
import '@fontsource/work-sans';

import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';

import Register from './Register';
import Login from './Login';

import loginDesktopBG1 from '../assets/loginDesktopBG1.png';
import loginMobile1 from '../assets/loginMobile1.png';
import NavBar from '../components/NavBar';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../contexts/TranslationContext';	


const LoginRegister: React.FC = () => {

	const { translations, language } = useTranslation();

	const location = useLocation();
	const loginOrRegisterSelected: number = location.state?.loginOrRegister;


	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	const [selected, setSelected] = useState(loginOrRegisterSelected);  // 0 for login, 1 for register

	const mobileFront = () => {
		return (
			<Box display={'flex'} justifyContent={'center'} paddingBottom={'10%'}>
				<Image src={loginMobile1} position={'fixed'} width={'100%'} height={'100%'} flex={1} />
				<Box zIndex={2} backgroundColor={'white'} width={'90%'} marginTop={'30%'} borderRadius={'2vh'} paddingTop={'5%'} paddingBottom={'5%'} paddingLeft={'5%'} paddingRight={'5%'} >
					<Flex justifyContent={'space-evenly'}>
						<Box flex={'.5'} borderBottomWidth={'.5vh'} borderColor={selected === 0 ? '#0993ff' : 'grey'} textAlign={'center'} onClick={() => setSelected(0)} paddingBottom={'1vh'} fontWeight={selected === 0 ? 'extrabold' : 'normal'}>
							<Text fontFamily={'Work sans'}>{translations['Log in']?.[language] || 'Iniciar Sesión'}</Text>
						</Box>
						<Box flex={'.5'} borderBottomWidth={'.5vh'} borderColor={selected === 1 ? '#0993ff' : 'grey'} textAlign={'center'} onClick={() => setSelected(1)} paddingBottom={'1vh'} fontWeight={selected === 1 ? 'extrabold' : 'normal'}>
							<Text fontFamily={'Work sans'}>{translations['Sign up']?.[language] || 'Registrarme'}</Text>
						</Box>
					</Flex>

					{selected === 0 ? <Login setSelected={setSelected} /> : <Register setSelected={setSelected} isMobile={isMobile || isTablet || isSmallScreen} />}

				</Box>
			</Box>
		);
	};


	const desktopFront = () => {
		return (
			<Box display={'flex'} justifyContent={'center'} marginBottom={'10%'}>
				<Image src={loginDesktopBG1} position={'fixed'} width={'110%'} height={'100%'} flex={1} />
				<Box zIndex={2} backgroundColor={'white'} borderRadius={'2vh'} marginTop={'10%'} width={'29%'} paddingTop={'2%'} paddingLeft={'2%'} paddingRight={'2%'} paddingBottom={'2%'}>

					<Flex justifyContent={'space-evenly'} >
						<Box cursor={'pointer'} flex={'.5'} borderBottomWidth={'.5vh'} borderColor={selected === 0 ? '#0993ff' : 'grey'} textAlign={'center'} onClick={() => setSelected(0)} paddingBottom={'1vh'} fontWeight={selected === 0 ? 'extrabold' : 'normal'}>
							<Text fontFamily={'Work sans'}>{translations['Log in']?.[language] || 'Iniciar Sesión'}</Text>
						</Box>

						<Box cursor={'pointer'} flex={'.5'} borderBottomWidth={'.5vh'} borderColor={selected === 1 ? '#0993ff' : 'grey'} textAlign={'center'} onClick={() => setSelected(1)} paddingBottom={'1vh'} fontWeight={selected === 1 ? 'extrabold' : 'normal'}>
							<Text fontFamily={'Work sans'}>{translations['Sign up']?.[language] || 'Registrarme'}</Text>
						</Box>
					</Flex>

					{selected === 0 ? <Login setSelected={setSelected} /> : <Register setSelected={setSelected} isMobile={isMobile || isTablet || isSmallScreen} />}

				</Box>
			</Box>
		);
	};


	return (
		<Box as="main" backgroundColor={'white'} zIndex={5} >
			<NavBar isHome={false} />
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
};

export default LoginRegister;
