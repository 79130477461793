// import ToastNotificationMobile from '../components/ToastNotificationMobile';
import { fireAlert } from '../helpers/Tich/TichAlert';
import { Product, GetProductsProps, GetCategoriesProps, Category, Group, FilterProps, GetProductsPropsParsed } from '../interfaces/interfaces';
import { GetCategoriesEndpoint, GetGroupsEndpoint, ProductsEndpoint } from '../variables/Connections';
// import { useTranslation } from '../contexts/TranslationContext';

export async function getProducts(props: GetProductsProps): Promise<Product[]> {

	// const { translations, language } = useTranslation();

	// const lastPage = translations['Last page']?.[language] || 'Última página';

	const productsQueryParams: GetProductsPropsParsed = {
		companyId: props.companyId.toString(),
		productsQty: props.productsQty.toString(),
		page: props.page.toString(),
		promotions: props.promotions
	};
	
	if (props.categories) productsQueryParams.categories = props.categories;
	if (props.searchText) productsQueryParams.search = props.searchText;
	if (props.search) productsQueryParams.search = props.search;

	const queryParams = new URLSearchParams(productsQueryParams as any);

	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};

		console.log(`${ProductsEndpoint}?${queryParams.toString()}`);

		const response = await fetch(`${ProductsEndpoint}?${queryParams.toString()}`, requestOptions);
		
		if (response.status == 200) {
			const products = await response.json();
			return products.data;
		} else {
			if (response.status == 404) fireAlert('Última página' +'!', 'success');
			return [];
		}

	} catch (error) {
		console.log('error getProducts ', error);
		return [];
	}
}


export async function filterProducts(props: FilterProps): Promise<Product[]> {

	// const { translations, language } = useTranslation();

	// const lastPage = translations['Last page']?.[language] || 'Última página';

	const queryParams = new URLSearchParams({
		companyId: props.companyId.toString(),
		search: props.searchText,
		productsQty: props.productsQty.toString(),
		page: props.page.toString(),
		categories: (props.categories).toString()
	});

	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};

		// console.log(`${ProductsEndpoint}?${queryParams.toString()}`);
		
		const response = await fetch(`${ProductsEndpoint}?${queryParams.toString()}`, requestOptions);

		if (response.status == 200) {
			const products = await response.json();
			return products.data;
		} else {
			if (response.status == 404) fireAlert('Última página' + '!', 'success');
			return [];
		}

	} catch (error) {
		console.log('error filter products ', error);
		return [];
	}
}



export async function getCategories(props: GetCategoriesProps): Promise<Category[]> {

	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};

		const response = await fetch(`${GetCategoriesEndpoint}/${props.companyId.toString()}`, requestOptions);

		if (response.ok) {
			const categories = await response.json();			
			if (categories.data.length > 0) {
				categories.data.unshift({category: 'Todos'});
			}
			return categories.data;

		} else {
			throw new Error('Credenciales incorrectas');
		}

	} catch (error) {
		console.log('error getCategories ', error);
		throw new Error('No se pudo obtener las categorias');
	}
}

export async function getGroups(): Promise<Group[]> {

	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};

		const response = await fetch(`${GetGroupsEndpoint}/`, requestOptions);

		if (response.ok) {
			const groups = await response.json();
			return groups.data;

		} else {
			throw new Error('Credenciales incorrectas');
		}

	} catch (error) {
		console.log('error getGroups ', error);
		throw new Error('No se pudo obtener los groups');
	}
}
