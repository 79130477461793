import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';
import { termsAndConditions } from '../variables/Terms';
import { useTranslation } from '../contexts/TranslationContext';
import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router-dom';
import { GoBackArrow } from '../assets/icons/iconList';

type LanguageKeys = keyof typeof termsAndConditions;

interface TermsAndConditions {
    title: string;
    introduction: string;
    definitions: {
      title: string;
      product: string;
      price: string;
      order: string;
      customer: string;
    };
    purchaseConditions: {
      title: string;
      age: string;
      registration: string;
      accurateInfo: string;
      payment: string;
    };
    shippingPolicy: {
      title: string;
      shippingTime: string;
      shippingCost: string;
      tracking: string;
    };
    returnPolicy: {
      title: string;
      returnPeriod: string;
      returnConditions: string;
      refund: string;
    };
    privacyPolicy: {
      title: string;
      dataProtection: string;
      dataUsage: string;
    };
    liability: {
      title: string;
      damages: string;
      errors: string;
    };
    applicableLaw: {
      title: string;
      law: string;
    };
    changes: {
      title: string;
      modification: string;
    };
    acceptance: {
      title: string;
      acceptanceText: string;
    };
    contact: {
      title: string;
      contactText: string;
    };
    version: {
      title: string;
      versionText: string;
    };
    confirm: {
      text: string;
    };
  }      


const TermsAndConditions = () => {

	const navigate = useNavigate();
	const { language } = useTranslation();
	const terms: TermsAndConditions = termsAndConditions[language as LanguageKeys];

	// const terms: React.FC<TermsAndConditions> = termsAndConditions[language];

	return (
		<Container maxW="full" centerContent>
			<NavBar isHome={false} />
			<Box padding="8" paddingTop="20" maxW="800px" margin="0 auto" textAlign="left" bg="gray.50" borderRadius="md" boxShadow="sm">

				{/* Volver a la pantalla de registro */}
				<Box pb="1vh">
					<GoBackArrow boxSize={'2.5vh'} onClick={() => navigate('/signin', { state: { loginOrRegister: 1 } })}/>
				</Box>

				<Heading as="h1" size="xl" mb="4" textAlign="center">
					{terms.title}
				</Heading>
    
				<Text fontSize="md" mb="4">
					{terms.introduction}
				</Text>
    
				{/* Sección: Definiciones */}
				<Heading as="h2" size="lg" mb="2">
					{terms.definitions.title}
				</Heading>
				<Text mb="2">{terms.definitions.product}</Text>
				<Text mb="2">{terms.definitions.price}</Text>
				<Text mb="2">{terms.definitions.order}</Text>
				<Text mb="4">{terms.definitions.customer}</Text>
    
				{/* Sección: Condiciones de Compra */}
				<Heading as="h2" size="lg" mb="2">
					{terms.purchaseConditions.title}
				</Heading>
				<Text mb="2">{terms.purchaseConditions.age}</Text>
				<Text mb="2">{terms.purchaseConditions.registration}</Text>
				<Text mb="2">{terms.purchaseConditions.accurateInfo}</Text>
				<Text mb="4">{terms.purchaseConditions.payment}</Text>
    
				{/* Sección: Política de Envío */}
				<Heading as="h2" size="lg" mb="2">
					{terms.shippingPolicy.title}
				</Heading>
				<Text mb="2">{terms.shippingPolicy.shippingTime}</Text>
				<Text mb="2">{terms.shippingPolicy.shippingCost}</Text>
				<Text mb="4">{terms.shippingPolicy.tracking}</Text>
    
				{/* Sección: Política de Devoluciones y Reembolsos */}
				<Heading as="h2" size="lg" mb="2">
					{terms.returnPolicy.title}
				</Heading>
				<Text mb="2">{terms.returnPolicy.returnPeriod}</Text>
				<Text mb="2">{terms.returnPolicy.returnConditions}</Text>
				<Text mb="4">{terms.returnPolicy.refund}</Text>
    
				{/* Sección: Política de Privacidad */}
				<Heading as="h2" size="lg" mb="2">
					{terms.privacyPolicy.title}
				</Heading>
				<Text mb="2">{terms.privacyPolicy.dataProtection}</Text>
				<Text mb="4">{terms.privacyPolicy.dataUsage}</Text>
    
				{/* Sección: Limitación de Responsabilidad */}
				<Heading as="h2" size="lg" mb="2">
					{terms.liability.title}
				</Heading>
				<Text mb="2">{terms.liability.damages}</Text>
				<Text mb="4">{terms.liability.errors}</Text>
    
				{/* Sección: Leyes Aplicables */}
				<Heading as="h2" size="lg" mb="2">
					{terms.applicableLaw.title}
				</Heading>
				<Text mb="4">{terms.applicableLaw.law}</Text>
    
				{/* Sección: Cambios en los Términos y Condiciones */}
				<Heading as="h2" size="lg" mb="2">
					{terms.changes.title}
				</Heading>
				<Text mb="4">{terms.changes.modification}</Text>
    
				{/* Sección: Aceptación */}
				<Heading as="h2" size="lg" mb="2">
					{terms.acceptance.title}
				</Heading>
				<Text mb="4">{terms.acceptance.acceptanceText}</Text>
    
				{/* Sección: Contacto */}
				<Heading as="h2" size="lg" mb="2">
					{terms.contact.title}
				</Heading>
				<Text mb="4">{terms.contact.contactText}</Text>
    
				{/* Sección: Versión */}
				<Heading as="h2" size="lg" mb="2">
					{terms.version.title}
				</Heading>
				<Text mb="4">{terms.version.versionText}</Text>

				<Text mb="4">{terms.confirm.text}</Text>
			</Box>
		</Container>
	);
};

export default TermsAndConditions;
