import React from 'react';
import { Box, Heading, Text, Stack, Icon, Button, Flex, useBreakpointValue, SimpleGrid, Image } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from '../contexts/TranslationContext';
import { MercadoPagoIcon } from '../assets/icons/iconList';
import paywayIcon from '../assets/icons/paywayIcon.jpeg';

interface Props {
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>
	createdOrder: { [key: string]: any }
}

const PostOrderScreen = (props: Props) => {
	// Para manejar el diseño responsive
	const isMobile = useBreakpointValue({ base: true, md: false });
	const { translations, language } = useTranslation();

	const HandleOrderId = () => {
		const orderId = Object.keys(props.createdOrder).map((key) => 
			key.toString().padStart(4, '0') + '-' + (props.createdOrder[key] as string).toString().padStart(6, '0')
		);
		return orderId;
	};

	return (
            
		<Box minHeight={'100vh'} maxH="100%" p={5} paddingTop="20" maxW={isMobile ? '100%' : '600px'} mx="auto">
			<Stack spacing={6}>
				<Heading as="h2" size="xl" textAlign="center">
                    ¡{translations['Thank you for your purchase']?.[language] || 'Gracias por tu compra'}!
				</Heading>

				{/* // Contactese con nosotros para arreglar forma de pago y envio */}
				<Text fontSize="2xl" textAlign="center" fontWeight={'bold'}>
					{translations['Contact us to arrange the payment and shipping method.']?.[language] || 'Contáctese con nosotros para arreglar forma de pago y envío.'}
				</Text>

				{/* // Numeros de pedido */}

				<Box borderWidth="1px" backgroundColor={'white'} borderRadius="lg" p={4}>
					<Heading as="h3" size="md" mb={2}>
						{translations['Order information']?.[language] || 'Información del pedido'}
					</Heading>
					<Stack spacing={3}>
						<Flex>
							<Text fontSize="lg" mr="1vh">{translations['Order number']?.[language] || 'Número de pedido'}: </Text>
							<Stack>
								{
									HandleOrderId().map((orderId, index) => (
										<Text key={index} fontSize="lg" fontWeight="bold">{orderId}</Text>
									))
								}
							</Stack>
						</Flex>
					</Stack>
				</Box>

				<Box borderWidth="1px" backgroundColor={'white'} borderRadius="lg" p={4}>
					<Heading as="h3" size="md" mb={2}>
						{translations['Contact Information']?.[language] || 'Información de Contacto'}
					</Heading>
					<Stack spacing={3}>               
						<Flex>           
							<Text fontSize="lg" mr="1vh">
								{translations['Contact us via Whatsapp']?.[language] || 'Escribinos por Whatsapp'}: 
							</Text>
							<Icon boxSize={6} alignSelf="center" mr="1vh" as={FaWhatsapp} color="green.500" /> 
							<Text fontSize="lg" fontWeight="bold">+54 9 1165361357</Text>
						</Flex>
						<Flex>
							<Text fontSize="lg" mr="1vh">{translations['Business Hours']?.[language] || 'Horario de atención'}: </Text>
							<Text fontSize="lg" fontWeight="bold">9:30hs - 18:00hs</Text>
						</Flex>
						<Flex>
							<Text fontSize="lg" mr="1vh">{translations['Email']?.[language] || 'Correo electrónico'}: </Text>
							<Text fontSize="lg" alignSelf="center" fontWeight="bold">wang.ar710@gmail.com</Text>
						</Flex>
					</Stack>
				</Box>

				<Box borderWidth="1px" backgroundColor={'white'} borderRadius="lg" p={4}>
					<Heading as="h3" size="md" mb={2}>
						{translations['Payment methods']?.[language] || 'Formas de pago'}
					</Heading>
					<SimpleGrid columns={2} spacing={3} mt="2vh">
						<Flex>
							<Text fontFamily={'Work sans'}>{translations['Cash']?.[language] || 'Efectivo'}</Text>
						</Flex>
						<Flex alignContent="center">
							<MercadoPagoIcon boxSize={6} mr={'1vh'}/>
							<Text fontFamily={'Work sans'}>Mercado Pago</Text>
						</Flex>
						<Flex>
							<Text fontFamily={'Work sans'}>{translations['Deposit']?.[language] || 'Depósito'}</Text>
						</Flex>
						<Flex>
							<Image boxSize={6} mr={'0.5vh'} src={paywayIcon} alt="Payway Icon" />
							<Text fontFamily={'Work sans'}>Payway</Text>
						</Flex>
						<Flex alignContent="center">
							<Text fontFamily={'Work sans'}>{translations['Bank transfer']?.[language] || 'Transferencia bancaria'}</Text>
						</Flex>
						
					</SimpleGrid>
				</Box>

				{/* <Box borderWidth="1px" borderRadius="lg" p={4}>
					<Heading as="h3" size="md" mb={2}>
						{translations['Shipping information']?.[language] || 'Información de envío'}
					</Heading>
					<Stack>
						<Text fontSize="lg">
                            El envío es gratis dentro de un radio de 60 km.
						</Text>
						<Text fontSize="lg">Tiempo estimado de entrega: 2 a 7 días hábiles.</Text>
						<Text fontSize="lg">Trabajamos con:</Text>
						<SimpleGrid columns={2} spacing={3} mt="2vh">
							<Flex>
								<Text fontSize="md" fontFamily={'Work sans'}>OCA</Text>
							</Flex>
							<Flex>
								<Text fontSize="md" fontFamily={'Work sans'}>Andreani</Text>
							</Flex>
							<Flex>
								<Text fontSize="md" fontFamily={'Work sans'}>Correo Argentino</Text>
							</Flex>
							<Flex>
								<Text fontSize="md" fontFamily={'Work sans'}>Via Cargo</Text>
							</Flex>
						</SimpleGrid>
					</Stack>
				</Box> */}

				{/* <Box borderWidth="1px" borderRadius="lg" p={4}>
					<Heading as="h3" size="md" mb={2}>
                        Información adicional
					</Heading>
					<Text fontSize="lg">
                        El envío es gratis dentro de un radio de 60 km.
					</Text>
					<Text fontSize="lg">Tiempo estimado de entrega: 2 a 7 días hábiles.</Text>
				</Box> */}

				<Button
					as="a"
					href="/"
					backgroundColor="#0993ff"
					colorScheme="steal"
					onChange={() => props.setSuccess(false)}
					size="lg"
					width="full"
					mt={5}
					textAlign="center"
				>
					{translations['Back to Home']?.[language] || 'Volver al Inicio'}
				</Button>
			</Stack>
		</Box>
	);
};

export default PostOrderScreen;
