import React, { useEffect, useState } from 'react';
import { Box, Container, Flex, Text, Image, VStack, HStack, Button, Center, SimpleGrid, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronRightArrow } from '../assets/icons/iconList';
import NavBar from '../components/NavBar';
import OrderStatus from '../components/Orders/OrderStatus';
import { useMediaQuery } from 'react-responsive';
import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';
import { getOrderDetails, updateOrderStatus } from '../api/orders';
import { getOrderDate } from '../helpers/TimeParser';
import { fireAlert } from '../helpers/Tich/TichAlert';
import { useTranslation } from '../contexts/TranslationContext';
import { MercadoPagoIcon } from '../assets/icons/iconList';


export default function OrderDetails() {

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = React.useState(false);
	const [orderIsCanceled, setOrderIsCanceled] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const order = location.state;

	// const [orderDetails, setOrderDetails] = useState({})
	const [orderProductList, setOrderProductList] = useState([]);
	const { translations, language } = useTranslation();

	useEffect(() => {
		setIsLoading(true);
		setOrderIsCanceled(false);
		getDetails();
	}, []);

	const getDetails = async () => {
		const orderDetails = await getOrderDetails(order.id);
		// setOrderDetails(orderDetails.orderInfo)
		setOrderProductList(orderDetails.orderDetails);
		setIsLoading(false);
	};

	const updateOrderState = async (newStatus: number) => {
		const updatedOrder = await updateOrderStatus(order.id, newStatus);
		if (updatedOrder) {
			fireAlert(translations['The order has been cancelled']?.[language] || 'El pedido ha sido cancelado', 'success');
			setOrderIsCanceled(true);
		}
		onClose();
	};

	const OrderUpdateVerifyModal = () => {
		return(
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent w={'xs'} mt={'10%'}>
					<ModalHeader fontSize={22}>{translations['Cancel order']?.[language] || 'Cancelar pedido'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontSize={19} textAlign={'center'}>{translations['Are you sure you want to cancel the order?']?.[language] || '¿Esta seguro que quiere cancelar el pedido?'}</Text>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={() => updateOrderState(6)}>
							{translations['Confirm']?.[language] || 'Confirmar'}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};

	const prepareImageURL = (partialRoute: string) => {
		// const partialRouteModified = partialRoute.replace('../', '');
		return `https://haomaistorage.blob.core.windows.net/${partialRoute}`;
	};

	const desktopFront = () => {
		return (
			<Box zIndex={0} minHeight={'100vh'} bg={'#F5F5F5'} paddingBottom={'5%'}>
				<NavBar isHome={false} />
				<Container pt={'15vh'} maxW={'155vh'}>
					<Flex pb={'12'} flexDirection={'row'} alignContent={'center'}>
						<Text fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'} onClick={() => navigate(-1)} color='#5F5F5F'>{translations['My orders']?.[language] || 'Mis pedidos'} </Text> {/* Mis pedidos */}
						<Box mt={1.5} px={5}>
							<ChevronRightArrow boxSize={'1.5vh'} />
						</Box>
						<Text fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'} color='#1E1E1E'>{translations['Order']?.[language] || 'Pedido'} #{((order.companyId).toString()).padStart(4, '0') + '-' + ((order.requestId).toString()).padStart(6, '0')}</Text>
					</Flex>

					<Box width={'100%'} padding={'2%'} borderRadius={5} bg={'#FFFFFF'}>

						<Flex justify={'space-between'} border={'1px'} padding={'2%'} borderRadius={5} borderColor={'#5F5F5F'}>
							<Box width={'60%'}>

								<Text fontWeight={'bold'} marginBottom={'3%'} fontSize={'2.5vh'} fontFamily={'Work sans'}>{translations['Order information']?.[language] || 'Información del pedido'}</Text>

								<Flex justify={'space-between'} marginBottom={'3%'} align={'center'}>
									<Text fontSize={'2vh'} width={'40%'} fontFamily={'Work sans'}>{translations['Date']?.[language] || 'Fecha'}: {getOrderDate(order.date)} </Text>
									<Flex width={'50%'} align={'center'}>
										<Text fontSize={'2vh'} width={'100%'} fontFamily={'Work sans'} marginRight={'2%'}>{translations['Status']?.[language] || 'Estado'}: </Text>
										<OrderStatus orderStatus={orderIsCanceled ? 6 : order.status} />
									</Flex>
								</Flex>

								<Flex justify={'space-between'} align={'center'}>
									<Text fontSize={'2vh'} width={'40%'} fontFamily={'Work sans'}>{translations['Total products']?.[language] || 'Total de productos'}: {order.totalProducts}</Text>
									{/* <Flex width={'50%'} align={'center'}>
										<Text fontSize={'2vh'} width={'100%'} fontFamily={'Work sans'} marginRight={'2%'}>付款状态: </Text>
										<OrderStatus orderStatus={1} />
									</Flex> */}
								</Flex>

							</Box>

							<Box height={'22vh'} w={0} border={'1px'} marginX={'4%'} borderColor={'grey'} />

							<Box width={'40%'} >
								<Text fontWeight={'bold'} marginBottom={'1vh'} fontFamily={'Work sans'} fontSize={'2.5vh'}>{translations['Payment methods']?.[language] || 'Formas de pago'} : </Text>
								<SimpleGrid columns={3} spacing={3}>
									<Flex>
										<Text fontFamily={'Work sans'}>{translations['Cash']?.[language] || 'Efectivo'}</Text>
									</Flex>
									<Flex>
										<Text fontFamily={'Work sans'}>{translations['Deposit']?.[language] || 'Depósito'}</Text>
									</Flex>
									<Flex>
										<Text fontFamily={'Work sans'}>Payway</Text>
									</Flex>
									<Flex alignContent={'center'}>
										<Text fontFamily={'Work sans'}>{translations['Bank transfer']?.[language] || 'Transferencia bancaria'}</Text>
									</Flex>
									<Flex alignContent={'center'}>
										<MercadoPagoIcon boxSize={6} mr={'1vh'}/>
										<Text fontFamily={'Work sans'}>Mercado Pago</Text>
									</Flex>
								</SimpleGrid>
								<Text fontSize={'2vh'} mt={'1.5vh'} mb={'1vh'} fontWeight={'bold'} fontFamily={'Work sans'}>{translations['Contact']?.[language] || 'Contactese con'} : </Text>
								<SimpleGrid columns={2} spacing={2}>
									<Text fontFamily={'Work sans'}>wang.ar710@gmail.com</Text>
									<Flex>
										<Text fontFamily={'Work sans'} mr={'2vh'}>1165361357</Text>
										<Text fontFamily={'Work sans'}>9:30hs - 18hs</Text>
									</Flex>
								</SimpleGrid>
							</Box>
						</Flex>

						<Box marginTop={'2%'} width={'100%'} >
							<Flex justify={'space-between'} px={10}>
								<Text fontFamily={'Work sans'} width={'25%'} fontWeight={'bold'} textAlign={'start'}>{translations['Product']?.[language] || 'Producto'}</Text>
								<Text fontFamily={'Work sans'} width={'25%'} fontWeight={'bold'} textAlign={'center'}>{translations['Quantity']?.[language] || 'Cantidad'}</Text>
								<Text fontFamily={'Work sans'} width={'25%'} fontWeight={'bold'} textAlign={'center'}>{translations['Unit price']?.[language] || 'Precio Unitario'}</Text>
								<Text fontFamily={'Work sans'} width={'25%'} fontWeight={'bold'} textAlign={'end'}>{translations['Total']?.[language] || 'Total'}</Text>
							</Flex>
						</Box>

						{

							isLoading ?
								<Flex justifyContent={'center'} mt='10%'>
									<Spinner size={'xl'} />
								</Flex>
								:
								orderProductList.length > 0 && orderProductList.map((product: any, index: number) => {

									return (
										<Flex key={index} backgroundColor={'#F5F5F5'} marginBottom={'1%'} align={'center'} justify={'space-between'} paddingX={'1%'} paddingY={'1%'} marginTop={'2%'}>

											<Flex width={'25%'}>
												<Center w={'sm'} h={'40'}>
													<Image src={prepareImageURL(product.image)} w={'auto'} alignSelf={'center'} h={'100%'} objectFit={'scale-down'} borderRadius={5} />
												</Center>

												<Box marginLeft={'2%'}>
													<Text fontFamily={'Work sans'} fontWeight={'bold'}>{product.productName}</Text>
													{/* <Text fontFamily={'Work sans'}>统一编码: {product.id}</Text> */}
												</Box>
											</Flex>

											<Text fontFamily={'Work sans'} fontWeight={'bold'} textAlign={'center'} width={'25%'}>{product.quantity}</Text>

											<Flex width={'25%'} justify={'center'}>
												<Text fontWeight={'bold'} fontFamily={'Work sans'} marginRight={'2%'}>{product.currency}</Text>
												<Text fontWeight={'bold'} fontFamily={'Work sans'}>{product.price}</Text>
											</Flex>

											<Flex width={'25%'} justify={'flex-end'}>
												<Text fontWeight={'bold'} fontFamily={'Work sans'} marginRight={'2%'}>{product.currency}</Text>
												<Text fontWeight={'bold'} fontFamily={'Work sans'}>{product.price * product.quantity}</Text>
											</Flex>

										</Flex>
									);
								})
						}


						<Flex justify={'space-between'} marginTop={'3%'}>
							{
								(order.status != 6 && !orderIsCanceled) &&
								<Button backgroundColor={'#FFF0EB'} color={'#FD5756'} fontFamily={'Work sans'} onClick={onOpen}>{translations['Cancel order']?.[language] || 'Cancelar pedido'}</Button>
							}
							<Box>
								<Text fontFamily={'Work sans'} fontSize={'20'}> {translations['total']?.[language] || 'total'}</Text>
								<Text fontWeight={'bold'} fontSize={'28'} fontFamily={'Work sans'}>ARS $ {order.totalPrice}</Text>
							</Box>

						</Flex>

					</Box>
				</Container>

			</Box>
		);
	};

	const mobileFront = () => {
		return (
			<Box zIndex={0} minHeight={'100vh'} bg={'#F5F5F5'} paddingBottom={'5%'}>
				<NavBar isHome={false} />
				<Container pt={'10vh'} maxW={'155vh'}>
					<Flex pb={'5'} flexDirection={'row'} alignContent={'center'}>
						<Text fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'} onClick={() => navigate(-1)} color='#5F5F5F'>{translations['My orders']?.[language] || 'Mis pedidos'} </Text> {/* Mis pedidos */}
						<Box mt={1.5} px={5}>
							<ChevronRightArrow boxSize={'1.5vh'} />
						</Box>
						<Text fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'} color='#1E1E1E'>{translations['Order']?.[language] || 'Pedido'} #{((order.companyId).toString()).padStart(4, '0') + '-' + ((order.requestId).toString()).padStart(6, '0')}</Text>
					</Flex>
					<Box width={'100%'} p={'4%'} borderRadius={5} bg={'#FFFFFF'}>
						<Box border={'2px'} p={'2%'} mb={'10'} borderRadius={5} borderColor={'#D7D7D7'}>
							<Text fontWeight={'bold'} marginBottom={'3%'} fontSize={'2.5vh'} fontFamily={'Work sans'}>{translations['Order information']?.[language] || 'Información del pedido'}</Text>
							<VStack spacing={3} align={'flex-start'}>
								<Flex align={'center'} w={'100%'}>
									<Text fontSize={'2vh'} mr={'2%'} fontFamily={'Work sans'}>{translations['Date']?.[language] || 'Fecha'}: </Text>
									<Text fontSize={'2vh'} fontWeight={'bold'} fontFamily={'Work sans'}>{getOrderDate(order.date)}</Text>
								</Flex>
								<Flex align={'center'} w={'100%'}>
									<Text fontSize={'2vh'} mr={'2%'} fontFamily={'Work sans'}>{translations['Total products']?.[language] || 'Total de productos'}:</Text>
									<Text fontSize={'2vh'} fontWeight={'bold'} fontFamily={'Work sans'}>{order.totalProducts}</Text>
								</Flex>
								<Flex align={'center'} w={'100%'}>
									<Text fontSize={'2vh'} fontFamily={'Work sans'} marginRight={'2%'}>{translations['Status']?.[language] || 'Estado'}: </Text>
									<Box w={'35%'}>
										<OrderStatus orderStatus={orderIsCanceled ? 6 : order.status} />
									</Box>
								</Flex>
								{/* <Flex align={'center'} w={'100%'}>
									<Text fontSize={'2vh'} fontFamily={'Work sans'} marginRight={'2%'}>付款状态: </Text>
									<Box w={'35%'}>
										<OrderStatus orderStatus={1} />
									</Box>
								</Flex> */}
							</VStack>
							<Box height={0} w={'100%'} border={'1px'} borderWidth={'thin'} marginY={'4%'} borderColor={'#D7D7D7'} />
							<Box>
								<Text fontWeight={'bold'} marginBottom={'3%'} fontFamily={'Work sans'} fontSize={'2.5vh'}>{translations['Payment method']?.[language] || 'Forma de pago'}</Text>
								{/* <Text fontWeight={'bold'} marginBottom={'3%'} fontFamily={'Work sans'} fontSize={'2.5vh'}>付款</Text> */}
								<Text fontSize={'2vh'} fontFamily={'Work sans'} color='gray'>{translations['Contact']?.[language] || 'Contactese con'} </Text>
							</Box>
						</Box>
						<Text>
							<Text color={'#5F5F5F'} my={'3%'} fontFamily={'Work sans'} fontSize={'2.2vh'}>{translations['Product']?.[language] || 'Producto'}</Text>
						</Text>
						{

							isLoading ?
								<Flex justifyContent={'center'} mt='10%'>
									<Spinner size={'xl'} />
								</Flex>
								:
								orderProductList.length > 0 && orderProductList.map((product: any, index: number) => {

									return (
										<VStack key={index} backgroundColor={'#F5F5F5'} spacing={2} borderRadius={5} align={'center'} justify={'space-between'} p={'3%'} marginTop={'2%'}>
											<HStack w={'100%'}>
												<Image boxSize={'16'} src={prepareImageURL(product.image)} borderRadius={5} />
												<VStack w={'100%'} spacing={4} align={'flex-start'}>
													<Text fontFamily={'Work sans'} fontWeight={'bold'}>{product.productName}</Text>
													<HStack w={'100%'} justifyContent={'space-between'}>
														<Text fontFamily={'Work sans'} fontWeight={'bold'} textAlign={'center'}>{translations['Quantity']?.[language] || 'Cantidad'}: {product.quantity}</Text>
														<Flex>
															<Text fontWeight={'bold'} fontFamily={'Work sans'} marginRight={'2%'}>{product.currency}</Text>
															<Text fontWeight={'bold'} fontFamily={'Work sans'}>{product.price * product.quantity}</Text>
														</Flex>
													</HStack>
												</VStack>
											</HStack>
										</VStack>
									);
								})
						}

						<HStack justify={'space-between'} mt={'10%'}>
							{
								order.status != 6 &&
								<Button backgroundColor={'#FFF0EB'} color={'#FD5756'} fontFamily={'Work sans'} onClick={onOpen}>{translations['Cancel order']?.[language] || 'Cancelar pedido'}</Button>
							}
							<Box>
								<Text fontFamily={'Work sans'}> {translations['total']?.[language] || 'total'}</Text>
								<Text fontWeight={'bold'} fontSize={'2vh'} fontFamily={'Work sans'}>ARS $ {order.totalPrice}</Text>
							</Box>
						</HStack>
					</Box>
				</Container>
			</Box>
		);
	};

	return (
		<Box w="100%" backgroundColor="white" borderBottomWidth={'2px'}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
			<OrderUpdateVerifyModal/>
		</Box>
	);
}
