import React, { useEffect } from 'react';
import { Box, Image } from '@chakra-ui/react';
// import { TfiArrowCircleLeft, TfiArrowCircleRight } from 'react-icons/tfi';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import carousel1_es from '../assets/banners/BIENVENIDA_ES.png';
import carousel1_cn from '../assets/banners/BIENVENIDA_CN.png';
import carousel2_es from '../assets/banners/5_OFF_ES.png';
import carousel2_cn from '../assets/banners/5_OFF_CN.png';
import carousel3_es from '../assets/banners/RETIRO_GRATIS_ES.png';
import carousel3_cn from '../assets/banners/RETIRO_GRATIS_CN.png';
import carousel4_es from '../assets/banners/ENVIOS_ES.png';
import carousel4_cn from '../assets/banners/ENVIOS_CN.png';
import carousel5_es from '../assets/banners/MEDIO_DE_PAGO_ES.png';
import carousel5_cn from '../assets/banners/MEDIO_DE_PAGO_CN.png';

import { useTranslation } from '../contexts/TranslationContext';

const settings = {
	dots: true,
	arrows: false,
	infinite: true,
	autoplay: false,
	speed: 500,
	autoplaySpeed: 5000,
	slidesToShow: 1,
	slidesToScroll: 1
};

interface CarouselProps {
	flex: number;
}

export default function Carousel(props: CarouselProps) {

	const [slider, setSlider] = React.useState<Slider | null>(null);
	const { language } = useTranslation();
	const mobileCarouselImagesES = [carousel1_es, carousel2_es, carousel3_es, carousel4_es, carousel5_es];
	const mobileCarouselImagesCN = [carousel1_cn, carousel2_cn, carousel3_cn, carousel4_cn, carousel5_cn];
	
	const mobileCarouselImages = language === 'spanish' ? mobileCarouselImagesES : mobileCarouselImagesCN;

	useEffect(() => {
		const intervalo = setInterval(() => {
			slider?.slickNext();
		}, 8000);
		
		return () => clearInterval(intervalo);
	}, [slider]);
	

	return (
		<Box flex={props.flex} position={'relative'} overflow={'visible'} zIndex={0} width={'0%'}>

			{/* <IconButton boxShadow={'md'} aria-label="left-arrow" variant="ghost" position="absolute" left={'-2%'} top={'50%'} transform={'translate(0%, -50%)'} zIndex={2} onClick={() => slider?.slickPrev()} backgroundColor={'white'} borderRadius={'5vh'} size={'xxs'}>
				<TfiArrowCircleLeft size="3.0vh" color='black'/>
			</IconButton>

			<IconButton boxShadow={'md'} aria-label="right-arrow" variant="ghost" position="absolute" right={'-2%'} top={'50%'} transform={'translate(0%, -50%)'} zIndex={2} onClick={() => slider?.slickNext()} backgroundColor={'white'} borderRadius={'5vh'} size={'xxs'} >
				<TfiArrowCircleRight size="3.0vh" color='black'/>
			</IconButton> */}

			<Slider  {...settings} ref={(slider) => setSlider(slider)} >

				{mobileCarouselImages.map((image, index) => (
					<Box key={index} boxShadow={'md'}>
						<Image src={image} width={'100%'} height={'auto'} />
					</Box>
				))}

			</Slider>

		</Box>
	);
}














