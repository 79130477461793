import { Box, Text, Heading, VStack, useColorMode, Container } from '@chakra-ui/react';
import { refundPolicy as refundPolicyData } from '../variables/refundPolicy';
import { useTranslation } from '../contexts/TranslationContext';
import { GoBackArrow } from '../assets/icons/iconList';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';

type RefundPolicy = {
    title: string;
    introduction: string;
    refund_conditions: {
        title: string;
        points: string[];
    };
    refund_process: {
        title: string;
        points: string[];
    };
    refund_times: {
        title: string;
        points: string[];
    };
    refundable_amounts: {
        title: string;
        points: string[];
    };
    exceptions: {
        title: string;
        points: string[];
    };
    contact: {
        title: string;
        content: string;
    };
    version: {
        title: string;
        content: string;
    };
    accept: string;
};



const RefundPolicy = () => {

	const navigate = useNavigate();
	const { language } = useTranslation();
	const refundPolicy = refundPolicyData[language as keyof typeof refundPolicyData];
	const { title, introduction, refund_conditions, refund_process, refund_times, refundable_amounts, exceptions, contact, version, accept } = refundPolicy;
  
	const { colorMode } = useColorMode();
  
	return (
		<Container maxW="full" centerContent>
			<NavBar isHome={false} />
			<Box padding="8" paddingTop="20" maxW="800px" margin="0 auto" textAlign="left" bg="gray.50" borderRadius="md" boxShadow="sm">

				<Box pb="1vh">
					<GoBackArrow boxSize={'2.5vh'} onClick={() => navigate('/cart')}/>
				</Box>

				<Heading as="h1" mb={4} fontSize="2xl" color={colorMode === 'light' ? 'black' : 'white'}>
					{title}
				</Heading>
        
				<Text mb={4} fontSize="lg">{introduction}</Text>
        
				<VStack align="start" spacing={6}>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{refund_conditions.title}
						</Heading>
						<VStack align="start" spacing={2}>
							{refund_conditions.points.map((point: string, index: number) => (
								<Text key={index}>• {point}</Text>
							))}
						</VStack>
					</Box>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{refund_process.title}
						</Heading>
						<VStack align="start" spacing={2}>
							{refund_process.points.map((point: string, index: number) => (
								<Text key={index}>• {point}</Text>
							))}
						</VStack>
					</Box>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{refund_times.title}
						</Heading>
						<VStack align="start" spacing={2}>
							{refund_times.points.map((point: string, index: number) => (
								<Text key={index}>• {point}</Text>
							))}
						</VStack>
					</Box>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{refundable_amounts.title}
						</Heading>
						<VStack align="start" spacing={2}>
							{refundable_amounts.points.map((point: string, index: number) => (
								<Text key={index}>• {point}</Text>
							))}
						</VStack>
					</Box>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{exceptions.title}
						</Heading>
						<VStack align="start" spacing={2}>
							{exceptions.points.map((point: string, index: number) => (
								<Text key={index}>• {point}</Text>
							))}
						</VStack>
					</Box>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{contact.title}
						</Heading>
						<Text>{contact.content}</Text>
					</Box>
            
					<Box>
						<Heading as="h2" size="md" mb={2}>
							{version.title}
						</Heading>
						<Text>{version.content}</Text>
					</Box>
            
					<Box>
						<Text fontSize="lg" fontWeight="bold">
							{accept}
						</Text>
					</Box>
				</VStack>
			</Box>
		</Container>
	);
};

export default RefundPolicy;
