import React from 'react';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView } from '../analytics/google';

export default function GoogleAnalytics() {
	const location = useLocation();
	const measurementId = 'G-47NKVC8MFB';
  
	React.useEffect(() => {
		initGA(measurementId);
	}, [measurementId]);
  
	React.useEffect(() => {
		logPageView(location.pathname + location.search);
	}, [location]);
	return (
		<></>
	);
}
