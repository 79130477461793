const tichMessageTypes = {
	minPurchase:0,
	minQtyMsg: 0,
	noRegisterMsg: 2,
	logisticMsg: 2,
	provinceMsg: 2,
	promotion: 1,
	distanceMsg: 1,
	CABAMsg: 1,
	orderCheckMsg: 1,
	default: 1
};

export default tichMessageTypes;