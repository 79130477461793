import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import statusMapper from '../../variables/orderStatusMapper';



interface OrderStatusProps {
	orderStatus: number
}

export default function OrderStatus(props: OrderStatusProps) {
	const [orderInfo, setOrderInfo] = React.useState({
		backgroundColor: '',
		status: 'Pendiente'
	});

	React.useEffect(() => {
		const orderInfo = statusMapper(props.orderStatus);
		setOrderInfo(orderInfo);
	}, [props.orderStatus]);

	return (
		<Flex paddingY={'3%'} width={'100%'} height={'30'} justify={'center'} align={'center'} backgroundColor={orderInfo.backgroundColor} borderRadius={4}>
			<Text fontSize={'1.8vh'} fontWeight={'semibold'} fontFamily={'Work sans'} color='#F5FAF2' >{orderInfo.status}</Text>
		</Flex>
	);
}
