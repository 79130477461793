import React, { SetStateAction } from 'react';
import { Box, Text, HStack, Flex } from '@chakra-ui/react';

import '@fontsource/work-sans';

import { CartStepperActual, CartStepperCompleted, CartStepperIncomplete } from '../assets/icons/iconList';
import { CartContext } from '../contexts/CartContext';
import { useTranslation } from '../contexts/TranslationContext';

type StepperState = {
	currentStep: number;
	totalSteps: number;
};

interface Props {

	setState: React.Dispatch<SetStateAction<StepperState>>,
	currentStep: number,
	totalSteps: number,
	nextStep: () => void,
	previousStep: () => void,

}

export default function CartStepperComponent(props: Props) {

	const { translations, language } = useTranslation();
	const cartContext = React.useContext(CartContext);
	// const cartList = cartContext.cartList;

	type StepperState = {
		currentStep: number;
		totalSteps: number;
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const initialState: StepperState = {
		currentStep: 1,
		totalSteps: 3,
	};


	const secondStep = () => {
		props.setState((prevState) => ({
			...prevState,
			currentStep: 2,
		}));
	};

	const fristStep = () => {
		props.setState((prevState) => ({
			...prevState,
			currentStep: 1,
		}));
	};

	const thirdStep = async () => {
		const successfullValidation = await cartContext.validateBusinessRules(true);
		if (!successfullValidation) return;
		props.setState((prevState) => ({
			...prevState,
			currentStep: 3,
		}));
	};

	return (
		<HStack borderTopRadius='8px' justify={'center'} align={'center'} bgColor={'#F5F3F3'} py={'1.5vh'}>
			<Box w={'100%'}>
				<Flex justify={'space-evenly'} cursor={'pointer'} px={'1%'}>
					<Flex direction={'column'} align={'center'} justify={'start'}>
						{props.currentStep === 1 ? <CartStepperActual w={7} h={7} onClick={() => fristStep()} /> : props.currentStep === 2 || props.currentStep === 3 ? <CartStepperCompleted w={7} h={7} onClick={() => fristStep()} /> : <CartStepperIncomplete w={7} h={7} onClick={() => fristStep()} />}
						<Text fontSize={'2vh'} fontWeight='600' fontFamily={'Work sans'} color={props.currentStep === 1 ? '#8150E2' : 'blackAlpha.600'} textAlign={'left'}
							onClick={() => fristStep()}
						>
							{translations['Cart'][language]}
						</Text>
					</Flex>
					<Box border={'1px'} alignSelf={'center'} mb={10} w={'18%'} h={0.1} borderColor={'#D7D7D7'} />
					<Flex direction={'column'} align={'center'} justify={'start'}>
						{props.currentStep === 2 ? <CartStepperActual w={7} h={7} onClick={() => secondStep()} /> : props.currentStep === 3 ? <CartStepperCompleted w={7} h={7} onClick={() => secondStep()} /> : <CartStepperIncomplete w={7} h={7} onClick={() => secondStep()} />}
						<Text fontSize={'2vh'} fontWeight='600' fontFamily={'Work sans'} color={props.currentStep === 2 ? '#8150E2' : 'blackAlpha.600'} textAlign={'center'}
							onClick={() => secondStep()}
						>
							{translations['Notification'][language]}
						</Text>
					</Flex>
					<Box border={'1px'} alignSelf={'center'} mb={10} w={'18%'} h={0.1} borderColor={'#D7D7D7'} />
					<Flex direction={'column'} align={'center'} justify={'start'}>
						{props.currentStep === 3 ? <CartStepperActual w={7} h={7} onClick={() => thirdStep()} /> : <CartStepperIncomplete w={7} h={7} onClick={() => thirdStep()} />}
						<Text fontSize={language == 'spanish'? '1.7vh' : '2vh'} fontWeight='600' fontFamily={'Work sans'} color={props.currentStep === 3 ? '#8150E2' : 'blackAlpha.600'} textAlign={'center'}
							onClick={() =>  thirdStep()}
						>
							{translations['Order information'][language]}
						</Text>
					</Flex>
				</Flex>
			</Box>
		</HStack>
	);
}
