import React, { useContext, useEffect, useState } from 'react';
import { Box, Image, Flex, Text, Input, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import { useMediaQuery } from 'react-responsive';
import '@fontsource/work-sans';
import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../../variables/responsiveDimensions';

import { CartGreenMinus, CartGreenPlus, CartPlusIcon, TrashCan } from '../../assets/icons/iconList';

import { Product } from '../../interfaces/interfaces';
import { useTranslation } from '../../contexts/TranslationContext';
import { CartContext } from '../../contexts/CartContext';
// import ToastNotificationMobile from '../ToastNotificationMobile';
import { validateMaxQty } from '../../helpers/Tich/TichRulesHelper';

interface Props {
	item: Product
}

export default function ProductComponent(props: Props) {

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);

	const [quantity, setQuantity] = useState<any>('');
	const [added, setAdded] = useState<boolean>(false);
	const [openDetails, setOpenDetails] = useState<boolean>(false);

	// const [showNotification, setShowNotification] = useState<boolean>(false);

	const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

	const localCart = cartContext.localCart;

	useEffect(() => {
		if (localCart[props.item?.companyId] && localCart[props.item?.companyId][props.item?.id]) {
			setAdded(true);
			setQuantity(localCart[props.item?.companyId][props.item?.id]);
		}
	}, [localCart]);


	const handleQuantityInput = async (qty: string | number ) => {
		
		if (qty === localCart[props.item?.companyId][props.item?.id]) return;
		setBtnDisabled(true);
		
		if (qty === '' || qty === '0') setQuantity('');
		//Validacion regla tich
		qty = validateMaxQty(Number(qty),props.item?.maxWholesale);

		if (Number(qty) > 0) {
			const quantityUpdate = await cartContext.updateItemQuantity(props.item, Number(qty),true);
			if (quantityUpdate) setQuantity(Number(qty));

		}
		setTimeout(() => setBtnDisabled(false), 3000);
	};

	const handleAddToCart = async () => {
		setBtnDisabled(true);
		const prodAdded: boolean = await cartContext.addItem(props.item, 1);
		if(!prodAdded) return;
		setAdded(true);
		setQuantity(1);
		setBtnDisabled(false);
	};

	const handleDeleteFromCart = async () => {
		cartContext.removeItem(props.item);
		setAdded(!added);
		setQuantity(0);
	};

	const prepareImageURL = (partialRoute: string) => {
		if (!partialRoute) return undefined;
		// WANG o TICH  2.0:
		// Tengo que hacer la negrada de extirpar un pedazo del string porque no coincide con el que está en
		// const partialRouteModified = partialRoute.replace('../', '').substring(22);
		// console.log('parttialRoute',partialRouteModified);
		// console.log('link total: https://haomaistorage.blob.core.windows.net/wang/images/',partialRouteModified);
		
		return `https://haomaistorage.blob.core.windows.net/${partialRoute}`;
	};

	const mobileFront = () => {
		return (
			<Box>
				<Modal isOpen={openDetails} onClose={() => setOpenDetails(!openDetails)} size={'xs'}>
					<ModalOverlay />
					<ModalContent>
						<ModalCloseButton />
						<ModalBody my={'15%'}>
							<Flex justify={'center'}>
								<Image src={prepareImageURL(props.item.image)} width={'auto'} maxHeight={'350px'} />
							</Flex>
							<Text fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'2vh'} marginTop={'10%'} >{props.item.name2}</Text>
							<Text fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'1.6vh'} marginTop={'5%'} >{props.item.subtitle}</Text>
							<Text fontFamily={'Work sans'} fontSize={'1.6vh'} marginTop={'5%'} >{props.item.description}</Text>
						</ModalBody>
					</ModalContent>
				</Modal>

				<Box borderWidth={'0px'}>
					{/* {props.item.flagGroup2 === true &&
						<Box backgroundColor={'#FD5756'} width={'12vh'} textAlign={'center'} borderRadius={'4px'} position={'absolute'}>
							<Text fontFamily={'Work Sans'} fontWeight={'bold'} color={'white'} fontSize={'12pt'} padding='1'>特价活动</Text>
						</Box>
					} */}

					<Flex justify={'center'} maxHeight={'150px'} minHeight={'150px'} onClick={() => setOpenDetails(!openDetails)}>
						<Image src={prepareImageURL(props.item.image)} width={'auto'} objectFit={'contain'} maxHeight={'150px'} />
					</Flex>
					<Flex borderWidth={'0px'} fontSize={'2.4vh'} fontWeight={'bold'} marginBottom={'2%'} marginTop={'5%'} onClick={() => setOpenDetails(!openDetails)}>
						<Text fontFamily={'Work Sans'}>{props.item.currency}</Text>
						<Text fontFamily={'Work Sans'} marginLeft={'3%'}>{props.item.priceWholesale},00</Text>
					</Flex>

					{added ?
						<Flex backgroundColor={'#F5F5F5'} my={'3'} border={'1px'} borderColor={'#D7D7D7'} borderRadius={'.8vh'} >
							<Flex paddingX={'5%'} justify={'space-around'} align={'center'}>
								{Number(quantity) <= 1 ?
									<TrashCan boxSize={'2.5vh'} cursor={'pointer'} onClick={() => handleDeleteFromCart()} />
									:
									<CartGreenMinus opacity={btnDisabled ? 0.5 : 1} boxSize={'2.5vh'} cursor={'pointer'} onClick={() => { if (btnDisabled) return; handleQuantityInput((Number(quantity) - 1).toString()); }} />
								}
								<Input
									type='number'
									min={1}
									value={quantity}
									variant={'unstyled'}
									textAlign={'center'}
									fontSize={'2vh'}
									fontFamily={'Work Sans'}
									height={'3.4vh'}
									focusBorderColor={'#F5F5F5'}
									textColor={'#4C7D2C'}
									color={'#4C7D2C'}
									onChange={(event) => setQuantity(parseInt(event.target.value))}
									onBlur={(event) => handleQuantityInput(parseInt(event.target.value))}
								/>
								<CartGreenPlus opacity={btnDisabled ? 0.5 : 1} boxSize={'2.5vh'} cursor={'pointer'} onClick={() => { if (btnDisabled) return; handleQuantityInput((Number(quantity) + 1).toString()); }} />
							</Flex>
						</Flex>

						:

						props.item.flagMarket === true && props.item.marketLimit > 0 ?
							<Flex opacity={btnDisabled ? 0.5 : 1} onClick={() => !btnDisabled && handleAddToCart()} py={'2%'} my={'3'} cursor={'pointer'} border={'1px'} justifyContent={'center'} alignItems={'end'} w={'100%'} borderRadius={'.8vh'} boxShadow="md" _active={{ boxShadow: 'sm', transform: 'translateY(1px)' }} >
								<CartPlusIcon boxSize={'2.5vh'} marginRight={'5%'} />
								<Text fontFamily={'Work Sans'} fontSize={'1.5vh'} mb={'1%'}>{translations['Add to cart']?.[language] || 'Agregar al carrito'}</Text>
							</Flex>
							:
							<Flex cursor={'default'} bgColor={'#F5F5F5'} py={'2%'} my={'3'} border={'1px'} borderColor={'#D7D7D7'} justifyContent={'center'} alignItems={'end'} w={'100%'} borderRadius={'.8vh'}>
								<Text fontFamily={'Work Sans'} fontSize={'1.5vh'} color={'#747474'} fontWeight={'bold'}>{translations['Out of stock']?.[language] || 'Agotado'}</Text>
							</Flex>
					}
					{
						props.item.flagGroup3 === true &&
							<Flex cursor={'default'} bgColor={'#F5F5F5'} py={'2%'} my={'3'} border={'1px'} borderColor={'#D7D7D7'} justifyContent={'center'} alignItems={'end'} w={'100%'} borderRadius={'.8vh'}>
								<Text fontFamily={'Work Sans'} fontSize={'1.5vh'} color={'#747474'} fontWeight={'bold'}>{translations['Coming soon']?.[language] || 'Próximamente'}</Text>
							</Flex>
					}

					<Text onClick={() => setOpenDetails(!openDetails)} width={'100%'} noOfLines={3} py={'1'} fontSize={'1.5vh'} fontFamily={'Work Sans'} color={'#5F5F5F'} fontWeight={'bold'} marginBottom={'0%'}>{props.item.name2}</Text>
					{/* <Text onClick={() => setOpenDetails(!openDetails)} width={'100%'} noOfLines={2} py={'1'} fontSize={'1.2vh'} fontFamily={'Work Sans'} color={'#5F5F5F'} marginBottom={'0%'}>{props.item.subtitle}</Text> */}
					{
						props.item.maxWholesale ?	
							<Text fontFamily={'Work sans'} fontSize={'14'} fontWeight={'bold'}>*{translations['Limit']?.[language] || 'Limite'} {props.item.maxWholesale} </Text> :
							null
					}
					{/* <Text fontFamily={'Work sans'} fontSize={'14'} color={'gray'}> ({translations['Unit per box']?.[language] || 'Unidad por caja'} {props.item.packWholesale}) </Text> */}
				</Box>
				{/* <ToastNotificationMobile showNotification={showNotification} /> */}
			</Box>
		);
	};

	const desktopFront = () => {
		return (
			<Box>
				{/* {props.item.flagGroup2 === true &&
					<Box backgroundColor={'#FD5756'} width={'12vh'} textAlign={'center'} borderRadius={'.5vh'} position={'absolute'} paddingX={'1%'}>
						<Text fontFamily={'Work Sans'} color={'white'} fontSize={'2vh'}>特价活动</Text>
					</Box>
				} */}
				<Modal isOpen={openDetails} onClose={() => setOpenDetails(!openDetails)} size={'xs'}>
					<ModalOverlay />
					<ModalContent>
						<ModalCloseButton />
						<ModalBody my={'15%'}>
							<Flex justify={'center'}>
								<Image src={prepareImageURL(props.item.image)} width={'auto'} maxHeight={'auto'} />
							</Flex>
							<Text fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'2.5vh'} marginTop={'10%'} >{props.item.name2}</Text>
							<Text fontFamily={'Work sans'} fontWeight={'bold'} fontSize={'1.6vh'} marginTop={'5%'} >{props.item.subtitle}</Text>
							{/* <Text fontFamily={'Work sans'} fontSize={'1.6vh'} marginTop={'5%'} >{props.item.description}</Text> */}
						</ModalBody>
					</ModalContent>
				</Modal>

				<Flex justify={'center'} onClick={() => setOpenDetails(!openDetails)}>
					<Image src={prepareImageURL(props.item.image) /* topProduct1 */} width={'auto'} height={'16vh'} objectFit={'contain'} />
				</Flex>

				<Flex fontSize={'1.8vh'} fontWeight={'bold'} marginBottom={'2%'} marginTop={'5%'}>
					<Text fontFamily={'Work Sans'} >{props.item.currency}</Text>
					<Text fontFamily={'Work Sans'} marginLeft={'3%'}>{props.item.priceWholesale},00</Text>
				</Flex>
				{added ?
					<Flex backgroundColor={'#F5F5F5'} my={'4%'} border={'1px'} borderColor={'#D7D7D7'} borderRadius={'.5vh'} >
						<Flex paddingX={'5%'} justify={'space-around'} align={'center'}>
							{Number(quantity) <= 1 ?
								<TrashCan boxSize={'2.5vh'} cursor={'pointer'} onClick={() => handleDeleteFromCart()} />
								:
								<CartGreenMinus opacity={btnDisabled ? 0.5 : 1} boxSize={'2.5vh'} cursor={'pointer'} onClick={() => { if (btnDisabled) return; handleQuantityInput((Number(quantity) - 1).toString()); }} />
							}
							<Input
								type='number'
								min={1}
								value={quantity}
								variant={'unstyled'}
								textAlign={'center'}
								fontSize={'2vh'}
								fontFamily={'Work Sans'}
								height={'3.5vh'}
								focusBorderColor={'#F5F5F5'}
								textColor={'##4C7D2C'}
								color={'##4C7D2C'}
								onChange={(event) => setQuantity(parseInt(event.target.value))}
								onBlur={(event) => handleQuantityInput(parseInt(event.target.value))}
							/>
							<CartGreenPlus opacity={btnDisabled ? 0.5 : 1} boxSize={'2.5vh'} cursor={'pointer'} onClick={() => { if (btnDisabled || (props.item.maxWholesale && Number(quantity) >= props.item.maxWholesale)) return; handleQuantityInput((Number(quantity) + 1).toString()); }} />
						</Flex>
					</Flex>

					:

					props.item.flagMarket === true && props.item.marketLimit > 0 ?
						<Flex opacity={btnDisabled ? 0.5 : 1} onClick={() => !btnDisabled && handleAddToCart()} my={'4%'} cursor={'pointer'} border={'1px'} justifyContent={'space-around'} alignItems={'center'} borderRadius={'.5vh'} p={'2%'} boxShadow="md" _active={{ boxShadow: 'sm', transform: 'translateY(1px)' }} >
							<CartPlusIcon boxSize={'2.5vh'} ml={2}/>
							<Text fontFamily={'Work Sans'} textAlign={'center'} fontSize={'1.5vh'}>{translations['Add to cart']?.[language] || 'Agregar al carrito'}</Text>
						</Flex>
						:
						<Flex cursor={'default'} backgroundColor={'#F5F5F5'} my={'4%'} border={'1px'} borderColor={'#D7D7D7'} justifyContent={'center'} marginTop={'5%'} alignItems={'end'} width={'100%'} borderRadius={'.5vh'} paddingY={'2%'}>
							<Text fontFamily={'Work Sans'} fontSize={'1.5vh'} color={'#747474'} fontWeight={'bold'}>{translations['Out of stock']?.[language] || 'Agotado'}</Text>
						</Flex>
				}

				{/* {
					props.item.flagGroup3 === true &&
							<Flex cursor={'default'} bgColor={'#F5F5F5'} py={'2%'} my={'3'} border={'1px'} borderColor={'#D7D7D7'} justifyContent={'center'} alignItems={'end'} w={'100%'} borderRadius={'.8vh'}>
								<Text fontFamily={'Work Sans'} fontSize={'1.5vh'} color={'#747474'} fontWeight={'bold'}>{translations['Comming soon'][language]}</Text>
							</Flex>
				} */}

				<Text onClick={() => setOpenDetails(!openDetails)} fontSize={'1.4vh'} color={'#5F5F5F'} py={'1'} fontWeight={'bold'} noOfLines={3}>{props.item.name2}</Text>
				{/* <Text onClick={() => setOpenDetails(!openDetails)} fontSize={'1.2vh'} color={'#5F5F5F'} py={'1'} noOfLines={2}>{props.item.subtitle}</Text> */}
				{
					props.item.maxWholesale ?	
						<Text fontFamily={'Work sans'} fontSize={'14'} fontWeight={'bold'}>*{translations['Limit']?.[language] || 'Limite'} {props.item.maxWholesale} </Text> :
						null
				}
				{/* <Text fontFamily={'Work sans'} fontSize={'1.3vh'} color={'gray'}> ({translations['Unit per box']?.[language] || 'Unidad por caja'} {props.item.packWholesale}) </Text> */}
			</Box>
		);
	};

	// if (loading) return <Box>Cargando...</Box>;


	return (
		<Box border={'3px'} borderColor={'black'}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
}
