import React from 'react';
import { Alert, Center, Flex, Text } from '@chakra-ui/react';

import { ArrowDown, ArrowUp, ExclamationYellow, Information, RedFlagWarning } from '../assets/icons/iconList';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from '../contexts/TranslationContext';
import { largeScreenMinDimensions } from '../variables/responsiveDimensions';


interface Props {
  message: string,
  type: number, //type: warning = 0 | information = 1 | atention = 2
  canOpen: boolean,
  hasHTMLMessage: boolean
}

export default function AlertComponent(props: Props) {
    
	const { translations, language } = useTranslation();
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	const [ open, setOpen ] = React.useState(false);

	// Hacer una función que se encarga de reemplazar las keys de traducción por su valor correspondiente
	// en el idioma seleccionado
	// Ejemplo: "-key-" -> "valor"
	// Si no encuentra la key, la deja como está
	// y que tambien se encargue de reemplazar los saltos de línea por <br> para que se muestren correctamente en el alert
	
	// const handleNewLines = (message: string) => {
	// 	const regex = /\\n/g;
	// 	const matches = message.match(regex);
	// 	if (matches) {
	// 		matches.forEach((match) => {
	// 			message = message.replace(match, '<br>');
	// 		});
	// 	}
	// 	return message;
	// };



	// const handleMessage = (message: string) => {
	// 	const regex = /-"(.*?)"-/g;
	// 	const matches = message.match(regex);
	// 	if (matches) {
	// 		matches.forEach((match) => {
	// 			const key = match.replace(/-|"|"/g, '');
	// 			const value = translations[key]?.[language];
	// 			console.log(key);
	// 			message = message.replace(match, value || key);
	// 		});
	// 	}
	// 	return message;
	// };

	const handleTranslationAndNewLines = (message: string) => {
		// Reemplazar las keys de traducción por su valor correspondiente
		const regexKey = /-"(.*?)"-/g;
		const matchesKey = message.match(regexKey);
		
		if (matchesKey) {
			matchesKey.forEach((match) => {
				const key = match.replace(/-|"|"/g, '');
				const value = translations[key]?.[language];
				message = message.replace(match, value || key);
			});
		}
	
		// Reemplazar los saltos de línea por <br>
		const regexNewLines = /\\n/g;
		message = message.replace(regexNewLines, '<br/>');
		console.log(message);
	
		return message;
	};
    
	return (
		<Center width={'100%'}>
			<Alert width={ isLargeScreen ? '100%' : '95%'} bgColor={ (props.type === 0 || props.type === 2) ? '#FFF0EB' : '#EBF5FF'} borderColor='#EBCDAF' borderWidth='1px' borderRadius='4px'>
				{
					props.canOpen ? 

						open ?
							<Flex minW={'100%'} justifyContent={'space-between'}>
								<Flex>
									{ props.type === 0 && <RedFlagWarning height={6} width={6} marginRight={'2vh'}/> }
									{ props.type === 1 && <Information height={6} width={6} marginRight={'2vh'}/> }
									{ props.type === 2 && <ExclamationYellow height={6} width={6} marginRight={'2vh'}/> }
									
									{ props.hasHTMLMessage ?
										<Text minW={isLargeScreen ? '100%' : '0%'} dangerouslySetInnerHTML={{ __html: handleTranslationAndNewLines(props.message) }}/>
										:
										<Text>{handleTranslationAndNewLines(props.message)}</Text>
									}
								</Flex>
								<ArrowUp onClick={() => setOpen(!open)} height={5} width={5} marginLeft={'1%'}/>
							</Flex>
							:
							<Flex minW={'100%'} justifyContent={'space-between'}>
								<Flex>
									{ props.type === 0 && <RedFlagWarning height={6} width={6} marginRight={'2vh'}/> }
									{ props.type === 1 && <Information height={6} width={6} marginRight={'2vh'}/> }
									{ props.type === 2 && <ExclamationYellow height={6} width={6} marginRight={'2vh'}/> }

									{ props.hasHTMLMessage ?
										<Text minW={isLargeScreen ? '100%' : '0%'} noOfLines={1} dangerouslySetInnerHTML={{ __html: handleTranslationAndNewLines(props.message) }}/>
										:
										<Text noOfLines={1}>{handleTranslationAndNewLines(props.message)}</Text>
									}
								</Flex>
								{
									props.message.length > 40 &&
									<ArrowDown onClick={() => setOpen(!open)} height={5} width={5} marginLeft={'1%'} alignSelf={'flex-end'}/>
								}
							</Flex>
        
						:
						<Flex>
							{ props.type === 0 && <RedFlagWarning height={6} width={6} marginRight={'2vh'}/> }
							{ props.type === 1 && <Information height={6} width={6} marginRight={'2vh'}/> }
							{ props.type === 2 && <ExclamationYellow height={6} width={6} marginRight={'2vh'}/> }
                            
							{ props.hasHTMLMessage ?
								<Text dangerouslySetInnerHTML={{ __html: handleTranslationAndNewLines(props.message) }}/>
								:
								<Text>{handleTranslationAndNewLines(props.message)}</Text>
							}
						</Flex>
				}
			</Alert>
		</Center>

	);
}
