export const refundPolicy = {
	'spanish': {
		'title': 'POLÍTICA DE REEMBOLSO',
		'introduction': 'En WANG.AR, nos comprometemos a ofrecer productos de alta calidad y servicio excelente. Sin embargo, entendemos que a veces puede ser necesario devolver o reembolsar un producto. A continuación, se detallan nuestras políticas de reembolso:',
		'refund_conditions': {
			'title': 'Condiciones de Reembolso',
			'points': [
				'Los productos deben estar en su condición original y con etiquetas intactas.',
				'Los productos deben ser devueltos dentro de los 7 días después de la recepción.',
				'Los productos deben ser devueltos con todos los accesorios.',
				'Los productos deben tener la dirección exacta.',
				'El envío del producto está a cargo del cliente.',
				'En caso de ser comestibles, no hay reembolso a no ser que sea por fecha de vencimiento expirada.'
			]
		},
		'refund_process': {
			'title': 'Proceso de Reembolso',
			'points': [
				'Para iniciar el proceso de reembolso, por favor contacte con nuestro equipo de atención al cliente a través de wangelectronica@gmail.com (ASUNTO: DEVOLUCIÓN Y REEMBOLSO).',
				'Proporcione el número de pedido y la razón para la devolución, además debe adjuntar fotos del mismo.',
				'Se le proporcionará una etiqueta de devolución para imprimir y adjuntar al paquete.',
				'Envíe el paquete de devolución a la dirección indicada.',
				'Una vez recibido y procesado el paquete, se le reembolsará el monto correspondiente.'
			]
		},
		'refund_times': {
			'title': 'Plazos de Reembolso',
			'points': [
				'Los reembolsos se procesan dentro de los 5-7 días hábiles después de recibir el paquete de devolución.',
				'Los reembolsos se realizan a través del mismo método de pago utilizado para la compra.'
			]
		},
		'refundable_amounts': {
			'title': 'Montos Reembolsables',
			'points': [
				'Se reembolsa sólo el precio del producto, menos cualquier cargo de envío.',
				'No se reembolsan los cargos de envío para devoluciones.'
			]
		},
		'exceptions': {
			'title': 'Excepciones',
			'points': [
				'Los productos dañados o defectuosos pueden ser reembolsados o reemplazados sin cargo adicional.',
				'Los productos que no se ajusten a la descripción pueden ser reembolsados o reemplazados sin cargo adicional siempre y cuando presenten fotos del mismo.'
			]
		},
		'contact': {
			'title': 'Contacto',
			'content': 'Para cualquier consulta o reclamo relacionado con nuestra política de reembolso, por favor contacte con nosotros a través de Wang.ar710@gmail.com o Teléfono 1165361357.'
		},
		'version': {
			'title': 'Versión',
			'content': 'Versión 1.0, 01/10/2024'
		},
		'accept': 'Al realizar una compra en WANG.AR, usted acepta y se compromete a cumplir con nuestra política de reembolso.'
	},
	'chinese': {
		'title': '退款政策',
		'introduction': '在WANG.AR，我们致力于提供高质量的产品和优质的服务。然而，我们理解有时可能需要退货或退款。以下是我们的退款政策：',
		'refund_conditions': {
			'title': '退款条件',
			'points': [
				'产品必须保持原始状态，并附有完整的标签。',
				'产品必须在收到后的7天内退回。',
				'产品必须与所有附件一同退回。',
				'产品必须标明准确的地址。',
				'退货的运费由客户承担。',
				'如果是食品类产品，除非因过期，否则不提供退款。'
			]
		},
		'refund_process': {
			'title': '退款流程',
			'points': [
				'若要启动退款流程，请通过wangelectronica@gmail.com联系我们的客户服务团队（主题：退货和退款）。',
				'提供订单号和退货原因，并附上产品照片。',
				'您将收到一张退货标签，请打印并贴在包裹上。',
				'将退货包裹寄送至指定地址。',
				'一旦收到并处理了包裹，您将获得相应的退款金额。'
			]
		},
		'refund_times': {
			'title': '退款时间',
			'points': [
				'退款将在收到退货包裹后的5-7个工作日内处理。',
				'退款将通过与购买时相同的支付方式进行。'
			]
		},
		'refundable_amounts': {
			'title': '可退款金额',
			'points': [
				'仅退还产品的价格，任何运费不予退还。',
				'退货的运费不予退还。'
			]
		},
		'exceptions': {
			'title': '例外情况',
			'points': [
				'受损或有缺陷的产品可以无额外费用退款或更换。',
				'不符合描述的产品可以在提供相关照片的情况下无额外费用退款或更换。'
			]
		},
		'contact': {
			'title': '联系方式',
			'content': '如果您对我们的退款政策有任何疑问或投诉，请通过以下方式联系我们：Wang.ar710@gmail.com或电话：1165361357。'
		},
		'version': {
			'title': '版本',
			'content': '版本 1.0，2024年10月1日'
		},
		'accept': '在WANG.AR购买产品即表示您接受并同意遵守我们的退款政策。'
	}
};
   