import React, { Box, Flex, Wrap, WrapItem } from '@chakra-ui/react';

import { useMediaQuery } from 'react-responsive';
import '@fontsource/work-sans';
import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';

import { Product } from '../interfaces/interfaces';
import ProductComponent from './Product/ProductComponent';

interface Props {
	products: Product[]
	originalProducts: Product[] | null
	isHome: boolean,
	width?: string,
	isSearch?: boolean,
	lastProductElementRef: (node: any) => void
}

export default function ListOfProducts(props: Props) {

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });
	
	function getProductItem(product: Product, index: number) {
		if (props.products.length === index + 1) {
			return (
				<WrapItem width={'14%'} key={index} >
					<Flex direction={'column'} justify={'space-between'} height={'100%'} width={'100%'}>
						<ProductComponent item={product} />
					</Flex>
				</WrapItem>
			);
		} else {
			return (
				<WrapItem ref={props.lastProductElementRef} width={'14%'} key={index} >
					<Flex direction={'column'} justify={'space-between'} height={'100%'} width={'100%'}>
						<ProductComponent item={product} />
					</Flex>
				</WrapItem>
			);
		}
	}

	function getMobileProductItem(product: Product, index: number) {
		if (props.products.length === index + 1) {
			return (
				<WrapItem ref={props.lastProductElementRef} width={'40%'} key={index} paddingTop={'4%'} >
					<Flex direction={'column'} justify={'space-between'} height={'100%'} width={'100%'}>
						<ProductComponent item={product} />
					</Flex>
				</WrapItem>
			);
		} else {
			return (
				<WrapItem width={'40%'} key={index} paddingTop={'4%'} >
					<Flex direction={'column'} justify={'space-between'} height={'100%'} width={'100%'}>
						<ProductComponent item={product} />
					</Flex>
				</WrapItem>
			);
		}
	}

	const mobileFront = () => {
		return (
			<Wrap backgroundColor={'white'} paddingLeft={'4%'} paddingY={'2%'} justify={'left'} spacingX={'8%'} spacingY={'5%'} marginTop={'2%'} borderRadius={props.isHome ? 0 : 15} height={ props.products.length === 0 ? '300px' : 'auto' } paddingBottom={'5%'}>
				{props.products.map((product, index) => (
					getMobileProductItem(product, index)
				))}
			</Wrap>
		);
	};
	const desktopFront = () => {
		return (
			<Box display={'flex'} justifyContent={'center'} height={ props.products.length === 0 ? '500px' : 'auto' } paddingBottom={'5%'}>

				{props.isSearch === true ?
					<Wrap backgroundColor={'white'} width={'85%'} boxShadow={'md'} paddingX={'1%'} paddingLeft={'3%'} paddingY={'2%'} justify={'flex-start'} spacingX={'2%'} spacingY={'3%'} borderRadius={8} borderBottomRadius={'1vh'}>
						{props.products.map((product, index) => (
							getProductItem(product, index)
						))}
					</Wrap>
					:
					<Wrap backgroundColor={'white'} width={'85%'} boxShadow={'md'} paddingX={'1%'} paddingLeft={'3%'} paddingY={'2%'} justify={'flex-start'} spacingX={'2%'} spacingY={'3%'} borderBottomRadius={'1vh'}>
						{props.products.map((product, index) => (
							getProductItem(product, index)
						))}
					</Wrap>
				}
			</Box>
		);
	};

	return (
		<Box width={props.width} border={'3px'} borderColor={'black'}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
}
