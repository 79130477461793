import { createOrder } from '../api/orders';
import { CartUserIds, SwrNewOrderInfo } from '../interfaces/interfaces';
import { getUserOSInfo } from './UserOSInfo';

export async function  sendOrder(userInfo: any, userIds: CartUserIds) {
	const userOSInfo = getUserOSInfo();
	// const tempId = await getDataLocal('tempId');

	const newOrderInfo: SwrNewOrderInfo = {
		phone: userInfo.phone,
		address: userInfo.address,
		location: userInfo.location,
		userOS: userOSInfo,
		businessName: userInfo.username,
		whatsapp: userInfo.whatsapp === '' ? null : userInfo.whatsapp,
		wechat: userInfo.wechat === '' ? null : userInfo.wechat,
		transport: userInfo.transport,
		description: userInfo.description,
		taxId: userInfo.taxId,
		email: userInfo.email,
		postalCode: userInfo.postalCode
	};
	
	if(userIds.tempId) newOrderInfo.tempId = userIds.tempId;
	if(userIds.userId) newOrderInfo.userId = userIds.userId;
	const orderCreated: any = await createOrder(newOrderInfo);
	return orderCreated;
}