import React, { useState } from 'react';
import { Box, Flex, Input, InputGroup, InputRightAddon, Text, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import '@fontsource/work-sans';

// import { PasswordVisibleIcon } from '../assets/icons/iconList';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { login } from '../api/auth';
import { fireAlert } from '../helpers/Tich/TichAlert';
import { getDataLocal } from '../helpers/LocalStorage';
import { updateTempIdToUserId } from '../api/cart';
import { CartContext } from '../contexts/CartContext';
import { useTranslation } from '../contexts/TranslationContext';
import { ERROR_MSG } from '../helpers/ErrorHandler';

type Props = {
	setSelected: React.Dispatch<React.SetStateAction<number>>
}

export default function Login({ setSelected }: Props) {
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [loginData, setLoginData] = useState({ phone: '', password: '' });
	const { translations, language } = useTranslation();
	const cartContext = React.useContext(CartContext);

	async function checkLoginData() {

		if (loginData.phone === '' || loginData.password === '') {
			fireAlert(translations['Please']?.[language] + translations['Enter a valid phone number and password']?.[language], 'error'); // Please fill in the correct phone number and password
			return;
		} else {
			const result = await login(loginData.phone, loginData.password);
			if (!result) {
				fireAlert(translations['Please']?.[language] + translations['Verify that the entered phone number and password are valid']?.[language], 'error'); // Please confirm that the phone number and password you entered are correct
				return;
			}
			if(result.error){
				fireAlert(ERROR_MSG[result.error.code], 'error');
				return;
			}

			fireAlert(translations['Successful login']?.[language] || 'Inicio de sesión exitoso', 'success'); // Login successful
	
			const tempId = await getDataLocal('tempId');
			const userDataLocal = await getDataLocal('user');
			if(!userDataLocal) {
				fireAlert(translations['Error retrieving information']?.[language] + translations['Please']?.[language] + translations['Try again later']?.[language], 'error');  // Error getting information, please try again later
				return;
			}
			if (tempId && userDataLocal) {
				const cartUpdated = await updateTempIdToUserId({ tempId: tempId, userId: userDataLocal.id });
				if (!cartUpdated) fireAlert(translations['Error retrieving information']?.[language] + translations['Please']?.[language] + translations['Try again later']?.[language], 'error');  // Error getting information, please try again later
			}
			//Para que al volver a home cargue bien lo que tengo
			await cartContext.getUserCart();
			navigate('/');
		}
	}

	return (
		<Box backgroundColor={'white'} marginTop={'4vh'}>
			<Box backgroundColor={'white'} marginTop={'4vh'}>
				<Text fontFamily={'Work sans'}>{translations['Cellphone number']?.[language] || 'Número de celular'}</Text>
				<Input
					type='tel'
					fontFamily={'Work Sans'}
					borderColor={'#A7A6A6'}
					height={'4vh'}
					onChange={(e) => setLoginData({ ...loginData, phone: e.target.value })}
				/>
			</Box>

			<Box backgroundColor={'white'} marginTop={'4vh'}>
				<Text fontFamily={'Work sans'}>{translations['Password']?.[language] || 'Contraseña'}</Text>
				<InputGroup>
					<Input
						type={showPassword ? 'text' : 'password'}
						fontFamily={'Work Sans'}
						height={'4vh'}
						fontSize={'2.3vh'}
						borderColor={'#A7A6A6'}
						onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
					/>
					<InputRightAddon
						backgroundColor={'white'}
						borderLeftWidth={'0px'}
						height={'4vh'}
						borderColor={'#A7A6A6'}
					>
						<Icon as={showPassword ? ViewIcon : ViewOffIcon} px={1} boxSize={'3.5vh'} onClick={() => setShowPassword(!showPassword)} />
						{/* <PasswordVisibleIcon boxSize={'3.5vh'} marginTop={'1vh'} cursor={'pointer'} onClick={() => setShowPassword(!showPassword)} /> */}
					</InputRightAddon>
				</InputGroup>
			</Box>

			<Flex marginTop={'3vh'}>
				<Text fontSize={'2vh'} mr={1}>{translations['Don\'t have an account?']?.[language] || '¿No tenes una cuenta?'} </Text>
				<Text cursor={'pointer'} fontSize={'2vh'} color={'#0993ff'} onClick={() => setSelected(1)}> {translations['Create account']?.[language] || 'Regístrate'}</Text>
			</Flex>

			<Box
				onClick={() => checkLoginData()}
				backgroundColor={'#0993ff'}
				textAlign={'center'}
				marginLeft={'4vh'}
				marginRight={'4vh'}
				marginTop={'5vh'}
				borderRadius={'1vh'}
				paddingY={'.5vh'}
				boxShadow="md"
				_active={{ boxShadow: 'sm', transform: 'translateY(1px)' }}
				cursor={'pointer'}
			>
				<Text color={'white'} fontSize={'2.5vh'}>{translations['Log in']?.[language] || 'Iniciar Sesión'}</Text>
			</Box>
		</Box>
	);
}
