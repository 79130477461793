export const saveDataLocal = (key: string, value: string) => {
	try {
		localStorage.setItem(key, value);
	} catch (e) {
		// manejar el error
	}
};

export const getDataLocal = (key: string) => {
	try {
		const value = localStorage.getItem(key);

		if (value !== null) {
			return JSON.parse(value);
		} else {
			// el valor no existe
			return null;
		}
	} catch (e) {
		// manejar el error
		console.log('err obtener data local', e);

		return null;
	}
};

export const removeDataLocal = (key: string) => {
	try {
		localStorage.removeItem(key);
	} catch (e) {
		// manejar el error
	}
};

export const clearAllDataLocal = () => {
	try {
		localStorage.clear();
	} catch (e) {
		// manejar el error
	}
};
