import React, { useContext, useState } from 'react';
import { Box, Image, Flex, Text, Input, HStack } from '@chakra-ui/react';

import { useMediaQuery } from 'react-responsive';
import '@fontsource/work-sans';
import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../../variables/responsiveDimensions';

import { CartGreenPlus, CartGreenMinus } from '../../assets/icons/iconList';

import { CartProductDB } from '../../interfaces/interfaces';
import { useTranslation } from '../../contexts/TranslationContext';
import { CartContext } from '../../contexts/CartContext';
import { validateMaxQty } from '../../helpers/Tich/TichRulesHelper';

interface Props {
	item: CartProductDB,
	index: number,
	update: boolean,
	setUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CartProductComponent(props: Props) {

	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);
	const localCart = cartContext.localCart;

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });
	const [quantity, setQuantity] = useState<any>(localCart[props.item?.product.companyId][props.item?.product.id]);
	const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

	const handleQuantityInput = async (qty: string | number) => {
		// console.log('recibo ',qty,' y el que esta guardado es', localCart[props.item?.product.companyId][props.item?.product.id]);
		
		if (Number(qty) === localCart[props.item?.product.companyId][props.item?.product.id]) return;
		setBtnDisabled(true);
		
		if (qty === '' || qty === '0') setQuantity('');
		//Validacion regla tich
		qty = validateMaxQty(Number(qty),props.item?.product.maxWholesale);

		if (Number(qty) > 0) {
			const quantityUpdate = await cartContext.updateItemQuantity(props.item.product, Number(qty),false);			
			if (quantityUpdate) setQuantity(Number(qty));
		}
		setTimeout(() => setBtnDisabled(false), 3000);
	};

	const prepareImageURL = (partialRoute: string) => {
		// const partialRouteModified = partialRoute.replace('../', '');
		return `https://haomaistorage.blob.core.windows.net/${partialRoute}`;
	};

	const handlePlusButtonPressed = () =>  { 
		if (btnDisabled || (props.item.product.maxWholesale && quantity >= props.item.product.maxWholesale)) return; 
		handleQuantityInput((quantity + 1).toString()); 
	};

	const handleMinusButtonPressed = () =>  { 
		if (btnDisabled || quantity === 1) return; 
		handleQuantityInput((quantity - 1).toString());
	};

	const mobileFront = () => {
		return (
			<Box key={props.item.product.id}>
				<Box marginTop={'2%'}>
					<Flex align={'center'} bgColor={'#F5F5F5'} borderRadius={'8px'} px='3' py='2'>
						<Image src={prepareImageURL(props.item.product.image)} w={'23%'} h={'auto'} mr={'3%'} />
						<Box w='80%'>
							<Text fontFamily={'Work sans'} fontSize={'12'} w={'100%'} noOfLines={2} h={'10'} fontWeight={'bold'} color='#5F5F5F'> {props.item.product.name2} </Text>
							<Text fontFamily={'Work sans'} fontWeight={'bold'}> {props.item.product.currency} {(isNaN(quantity)  ? 0 : quantity) * props.item.product.priceWholesale},00</Text>
							<Text fontFamily={'Work sans'} fontSize={'16'}>{props.item.product.currency} {props.item.product.priceWholesale},00 </Text>
							{
								props.item.product.maxWholesale ?	
									<Text fontFamily={'Work sans'} fontSize={'14'} fontWeight={'bold'}>*{translations['Limit']?.[language] || 'Limite'} {props.item.product.maxWholesale} </Text> :
									null
							}
							<Text fontFamily={'Work sans'} fontSize={'14'} color={'gray'}> ({translations['Unit per box']?.[language] || 'Unidad por caja'} {props.item.product.packWholesale}) </Text>
							<Flex justifyContent={'space-between'} align={'center'} mt={'2%'} pr={'2'}>
								<Text
									color='#3086EB'
									cursor='pointer'
									fontSize={'1.5vh'}
									fontWeight={'bold'}
									onClick={() => { cartContext.removeItem(props.item.product); }}
								>
									{translations['Remove product'][language]}
								</Text>

								<Flex align={'center'} justify={'center'} width={'45%'}>
									<CartGreenMinus
										onClick={handleMinusButtonPressed}
										boxSize={'2.0vh'}
										cursor={'pointer'}
										opacity={btnDisabled ? 0.5 : 1}
									/>
									<Input
										type='number'
										value={quantity}
										variant={'unstyled'}
										textAlign={'center'}
										fontSize={'1.8vh'}
										fontFamily={'Work Sans'}
										h={'3.5vh'}
										focusBorderColor={'#F5F5F5'}
										textColor={'#4C7D2C'}
										onChange={(event) => setQuantity(parseInt(event.target.value))}
										onBlur={(event) => handleQuantityInput(parseInt(event.target.value))}
									/>
									<CartGreenPlus opacity={btnDisabled ? 0.5 : 1} boxSize={'2.0vh'} cursor={'pointer'} onClick={handlePlusButtonPressed} />
								</Flex>
							</Flex>
						</Box>
					</Flex>
					{/* <Box width={'100%'} h={'1px'} backgroundColor={'#DDDDDD'} marginTop={'3%'} /> */}
				</Box>
			</Box>
		);
	};

	const desktopFront = () => {
		return (
			<Box bgColor={'#F5F5F5'} py={'1.5%'} px={'2%'} paddingRight={'4%'} mt='1.5%' borderRadius={10}>
				<Box >

					<Flex align={'center'}>
						<Flex align={'center'} w={'55%'} mr={'5%'}>
							<Image src={prepareImageURL(props.item.product.image)} w={'15%'} h={'auto'} mr={'3%'} borderRadius={10} />
							<Box w={'80%'}>
								<Text minH={'10'} noOfLines={[1, 2]} fontSize={'14'} fontFamily={'Work sans'} fontWeight={'bold'}> {props.item.product.name2} </Text>
								{
									props.item.product.maxWholesale ?	
										<Text fontFamily={'Work sans'} fontSize={'14'} fontWeight={'bold'}>*{translations['Limit']?.[language] || 'Limite'} {props.item.product.maxWholesale} </Text> :
										null
								}
								<Text fontFamily={'Work sans'} fontSize={'14'} color={'gray'}> ({translations['Unit per box']?.[language] || 'Unidad por caja'} {props.item.product.packWholesale}) </Text>
								<Text
									color='#3086EB'
									cursor='pointer'
									fontSize={'1.5vh'}
									fontWeight={'bold'}
									onClick={() => { cartContext.removeItem(props.item.product); }}
								>
									{translations['Remove product'][language]}
								</Text>
							</Box>
						</Flex>

						<Flex align={'center'} justify={'space-between'} w={'50%'}>
							<HStack w={'50%'} spacing={2} px={'5%'} justify={'space-around'} align={'center'}>
								<CartGreenMinus
									onClick={handleMinusButtonPressed}
									cursor={'pointer'}
									opacity={btnDisabled ? 0.5 : 1}
								/>
								<Input
									type='number'
									value={quantity}
									variant={'unstyled'}
									textAlign={'center'}
									fontSize={'2vh'}
									fontFamily={'Work Sans'}
									h={'3.5vh'}
									focusBorderColor={'#F5F5F5'}
									textColor={'#4C7D2C'}
									color={'#4C7D2C'}
									onChange={(event) => setQuantity(parseInt(event.target.value))}
									onBlur={(event) => handleQuantityInput(parseInt(event.target.value))}
								/>
								<CartGreenPlus
									boxSize={'2vh'}
									cursor={'pointer'}
									opacity={btnDisabled ? 0.5 : 1}
									onClick={handlePlusButtonPressed}
								/>
							</HStack>

							<Flex flexDirection={'column'} align={'end'}>
								<Flex>
									{/* <Text mr={'10%'} fontFamily={'Work sans'} fontWeight={'bold'}></Text> */}
									<Text fontFamily={'Work sans'} fontWeight={'bold'}> {props.item.product.currency} {props.item.product.priceWholesale * (isNaN(quantity)  ? 0 : quantity)},00</Text>
								</Flex>
								{/* <Flex>
									<Text align={'end'} fontFamily={'Work sans'} fontSize={13}> Precio mayorista {props.item.product.currency} {props.item.product.priceWholesale},00</Text>
								</Flex> */}
							</Flex>
						</Flex>
					</Flex>
				</Box>
			</Box>
		);
	};


	return (
		<Box border={'3px'} borderColor={'black'}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
}
