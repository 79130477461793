import React from 'react';
import { ChakraProvider/* , extendTheme */ } from '@chakra-ui/react';
import '@fontsource/work-sans';

import './App.css';
import MainRoutes from './routes/MainRoutes';
import 'react-toastify/dist/ReactToastify.css';


/* const theme = extendTheme({
	fonts: {
		heading: `Work Sans`,
		body: `Work Sans`
	},
  }) */


function App() {
	return (
		<ChakraProvider /* theme={theme} */>
			<MainRoutes />
		</ChakraProvider>
	);
}

export default App;
