import { SwrNewOrderInfo } from '../interfaces/interfaces';
import { CreateOrderEndpoint, GetOrdersEndpoint, UpdateOrdersEndpoint } from '../variables/Connections';

export async function createOrder(newOrder: SwrNewOrderInfo): Promise<boolean> {
	try {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(newOrder),
		};

		const response = await fetch(CreateOrderEndpoint, requestOptions);

		if (response.ok) {
			const newOrderResp = await response.json();
			// console.log('newOrderResp', newOrderResp);
			if (newOrderResp.ok) {
				return newOrderResp.data;
			}
			return false;
		} else {
			return false;
		}

	} catch (error) {
		console.log('error create order ', error);
		return false;
	}
}

export async function getOrdersByUser(userId: number): Promise<any> {
	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const urlGet = `?userId=${userId}`;

		const response = await fetch(`${GetOrdersEndpoint}${urlGet}`, requestOptions);
		// console.log('response', response);
		if (response.ok) {
			const ordersResp = await response.json();
			// console.log('ordersResp', ordersResp.status);
			if (ordersResp.ok) {
				return ordersResp.data;
			}
			// throw new Error(ordersResp.message);

			return null;
		} else {
			if (response.status === 404) {
				return [];
			}
			return null;
		}

	} catch (error) {
		console.log('error get orders ', error);
		return false;
	}
}

export async function getOrderDetails(orderID: number): Promise<any> {
	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};
		const urlGet = `/${orderID.toString()}`;
		// console.log('url get order details', `${GetOrdersEndpoint}${urlGet}`);

		const response = await fetch(`${GetOrdersEndpoint}${urlGet}`, requestOptions);

		if (response.ok) {

			const ordersResp = await response.json();
			if (ordersResp.ok) {
				return ordersResp.data;
			}
			// throw new Error(ordersResp.message);
			return null;

		} else {
			return null;
		}

	} catch (error) {
		console.log('error get order details ', error);
		return false;
	}
}

export async function updateOrderStatus(orderID: number, newStatus: number):Promise<any> {
	try {
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ orderId: orderID, status: newStatus }),
		};

		const response = await fetch(`${UpdateOrdersEndpoint}`, requestOptions);

		if (response.ok) {

			const ordersResp = await response.json();
			if (ordersResp.ok) {
				return ordersResp.ok;
			}
			// throw new Error(ordersResp.message);
			return null;

		} else {
			return null;
		}

	} catch (error) {
		console.log('error update order status ', error);
		return false;
	}
}
