import { removeDataLocal } from '../helpers/LocalStorage';
import { APIResponse, AddProductToCartProps, CartAPIResponse, CartAPIValidationResponse, CartProductDelete, CartProductUpdate, CartUserIds, ExtraInfoResponse } from '../interfaces/interfaces';
import { AddToCartEndpoint, DeleteAllCartProductsEndpoint, DeleteProductCartEndpoint, GetCartEndpoint, GetCartExtraInfoEndpoint, UpdateProductCartEndpoint, UpdateProductCartTempIdToUserId, ValidateCartEndpoint } from '../variables/Connections';

export async function getCart(userIds: CartUserIds): Promise<CartAPIResponse> {
	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};
		const urlGet = userIds.userId ? `?userId=${userIds.userId}` : `?tempId=${userIds.tempId}`;
		// console.log('url get cart', `${GetCartEndpoint}${urlGet}`);

		const response = await fetch(`${GetCartEndpoint}${urlGet}`, requestOptions);

		if (response.ok) {
			const getCartResp = await response.json();
			// console.log('getCartResp', getCartResp);
			return getCartResp;
		}
		if (response.status == 404) return { ok: true, message: 'Carrito vacio', status: 0, data: {} };

		return { ok: false, message: 'No se pudo obtener el carrito', status: -1, data: {} };

	} catch (error) {
		console.log('error getCart ', error);
		return { ok: false, message: 'No se pudo obtener el carrito', status: -1, data: {} };
	}
}

export async function addToCart(newProd: AddProductToCartProps): Promise<APIResponse> {
	try {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(newProd),
		};

		const response = await fetch(AddToCartEndpoint, requestOptions);
		const addCartResp = await response.json();
		console.log('addCartResp', addCartResp);
		return addCartResp;

		// if (response.ok) {


		// } else {
		// 	console.log('err response add cart no OK', response);

		// 	return { ok: false, message: 'No se pudo agregar al carrito', status: -1 };
		// }

	} catch (error) {
		console.log('error add cart ', error);
		return { ok: false, message: 'No se pudo agregar al carrito', status: -1 };
	}
}

export async function deleteProductFromCart(prod: CartProductDelete): Promise<CartAPIResponse> {
	try {
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(prod),
		};

		const response = await fetch(DeleteProductCartEndpoint, requestOptions);

		if (response.ok) {

			const deleteProdResp = await response.json();
			console.log('deleteProdResp', deleteProdResp);

			if (deleteProdResp.ok) {
				return { ok: true, message: 'Producto borrado', status: 0 };
			}

			return { ok: false, message: 'No se pudo borrar el producto del carrito', status: -1 };

		} else {
			console.log('err response add cart no OK', response);

			return { ok: false, message: 'No se pudo borrar el producto del carrito', status: -2 };
		}

	} catch (error) {
		console.log('error delete prod cart ', error);
		return { ok: false, message: 'No se pudo borrar el producto del carrito', status: -3 };
	}
}

export async function updateProductFromCart(prod: CartProductUpdate): Promise<CartAPIResponse> {
	try {
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(prod),
		};

		const response = await fetch(UpdateProductCartEndpoint, requestOptions);

		if (response.ok) {
			const updateCartResp = await response.json();
			// console.log('updateCartResp', updateCartResp);
			if (updateCartResp.ok) {
				return { ok: true, message: updateCartResp.message, status: 0 };
			}
			return { ok: false, message: updateCartResp.message || 'Error al actualizar la cantidad del producto', status: -1 };
		} else {
			return { ok: false, message: 'Error al actualizar la cantidad del producto', status: -1 };
		}

	} catch (error) {
		console.log('error Update cart ', error);
		return { ok: false, message: 'Error al actualizar la cantidad del producto', status: -1 };
	}
}

export async function validateCartRules(userIds: CartUserIds): Promise<CartAPIValidationResponse> {
	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};
		const urlGet = userIds.userId ? `?userId=${userIds.userId}` : `?tempId=${userIds.tempId}`;
		// console.log('ValidateCartEndpoint cart', `${ValidateCartEndpoint}${urlGet}`);

		const response = await fetch(`${ValidateCartEndpoint}${urlGet}`, requestOptions);
		const rulesValidated = await response.json();
		return rulesValidated;

	} catch (error) {
		console.log('error Validation ', error);
		// throw new Error("No se pudo agregar al carrito");
		return {
			ok: false,
			status: 1, //hardcodeado dsp ver cual es el adecuado
		};
	}
}

export async function removeAllCartProducts(userIds: CartUserIds): Promise<boolean> {
	try {
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(userIds)
		};

		const response = await fetch(`${DeleteAllCartProductsEndpoint}`, requestOptions);

		if (response.ok) {
			const cartRemoved = await response.json();
			// console.log('cartRemoved', cartRemoved);
			if (cartRemoved.ok) {
				return true;
			}
			return false;
		} else {
			return false;
		}

	} catch (error) {
		console.log('error Validation ', error);
		return false;
	}
}

export async function updateTempIdToUserId(ids: CartUserIds): Promise<boolean> {
	try {
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(ids)
		};
		console.log('ids', ids);
		const response = await fetch(`${UpdateProductCartTempIdToUserId}`, requestOptions);

		if (response.ok) {
			const cartUpdated = await response.json();
			console.log('cartUpdated',cartUpdated);
			
			if (cartUpdated.ok) {
				removeDataLocal('tempId');
				return true;
			}
			return false;
		} else {
			// Caso 404: Usuario temp tiene carrito con cosas y cuando se loguea su carrito de user tambien tiene productos.
			//			 Se borra el carrito temp en ese caso por eso se remueve tempId
			// Caso 400: Usuario temp tiene carrito vacio, no hay carrito que migrar por eso se remueve tempId
			if(response.status === 400 || response.status === 404){
				removeDataLocal('tempId');
				return true;
			}
			return false;
		}

	} catch (error) {
		console.log('error Validation ', error);
		return false;
	}
}

export async function getCartExtraInfo(): Promise<ExtraInfoResponse> {
	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};
		const response = await fetch(`${GetCartExtraInfoEndpoint}`, requestOptions);
		return await response.json();
	} catch(err) {
		console.log('error get cart info ', err);
		return { ok: false, message: 'Error al obtener informacion extra del carrito', status: -1 };
	}
}