import React, { useEffect } from 'react';
import { Box, Image, Flex, Text, Input, InputGroup, InputLeftAddon, Menu, MenuButton, MenuList, MenuItem, Select } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import '@fontsource/work-sans';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';
import logo from '../assets/logoWang_pink.png';
// import oldLogo from '../assets/logo.png';
// import mpIcon from '../assets/icons/mpIcon.png';
// import wappIcon from '../assets/icons/wappIcon.png';
import { SearchIcon, ProfileIcon, CartIcon, XMarkIcon, WhiteCartIcon } from '../assets/icons/iconList';
import { getDataLocal } from '../helpers/LocalStorage';
import { SearchParams } from '../interfaces/interfaces';
import { removeDataLocal } from '../helpers/LocalStorage';
import { useTranslation } from '../contexts/TranslationContext';
import { CartContext } from '../contexts/CartContext';
// import CustomTooltip from '../components/CustomTooltip';
import { useSearchContext } from '../contexts/SearchContext';
// import { Link } from 'react-router-dom';
// import { PhoneIcon } from '@chakra-ui/icons';
import { validateCartPreview } from '../helpers/CartHelper';

interface Props {
	filterByTextSearch?: (search: string) => void,
	isHome: boolean
}

export default function NavBar(props: Props) {

	const navigate = useNavigate();
	const { translations, language, setLanguage } = useTranslation();
	const cartContext = useContext(CartContext);

	const { searchText, setSearchText } = useSearchContext();
	const pathLocation = useLocation();

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	// const [searchText, setSearchText] = React.useState<string>('');
	const [isLogged, setIsLogged] = React.useState<boolean>(false);
	const [isSearching, setIsSearching] = React.useState<boolean>(false);

	useEffect(() => {
		userIsLogged();
	}, [isLogged]);


	useEffect(() => {
		// console.log('holis 1',pathLocation);
		// if (Object.keys(translations).length === 0) {
		// 	const translation = getDataLocal('translations');
		// 	if (translation) setTranslations(translation);
		// }
		
		if (pathLocation.pathname === '/') setSearchText('');
		else setSearchText(searchText);

	},[ pathLocation ]);


	const userIsLogged = async () => {
		const user = await getDataLocal('user');
		if (user?.isLogged) setIsLogged(user.isLogged);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const searchTextParam: SearchParams = { searchText: searchText };
		if (event.key === 'Enter') {
			
			if (isLargeScreen) navigate('/search', { state: { searchTextParam } });
			else {
				console.log(searchTextParam.searchText);
				if (props.filterByTextSearch) props.filterByTextSearch(searchTextParam.searchText);
				// navigate('/', {state: { isFiltering: true, textSearch: searchTextParam.searchText } });
			}
		}
	};

	const handleSearchButton = () => {
		handleKeyDown({ key: 'Enter', currentTarget: { value: searchText } } as React.KeyboardEvent<HTMLInputElement>);
	};

	const handleLogout = () => {
		removeDataLocal('user');
		setIsLogged(false);
		cartContext.deleteLocalCartInfo();
		navigate('/');
		location.reload();
	};

	const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setLanguage(e.target.value);
	};

	const mobileFront = () => {
		return (
			<>
				{/* <Flex backgroundColor={'#CC5826'} paddingBlock={'0.5vh'} alignItems={'center'} justifyContent={'center'}>
					<Text color={'white'}  fontSize={'12pt'} fontFamily={'Work Sans'} mr='2'>我们依然保留旧版</Text>
					<Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} as={Link} to="http://www.tich.ar" >
						<Text color={'white'}  fontSize={'14pt'} fontWeight={'semibold'} fontFamily={'Work Sans'} textDecor={'underline'} mr='2'>天创</Text>
						<Image src={oldLogo} borderRadius={20} alt='Tich old Logo' boxSize={'3vh'}  backgroundColor={'white'}/>
					</Flex>
				</Flex> */}
				{
					isSearching ?

						<Flex direction={'column'} width={'100%'} paddingBottom={'2%'}>
							
							<Flex alignItems={'center'} justify={'space-between'} width={'100%'}>
								<Flex alignItems='center' flex={'.5'} onClick={() => navigate('/')}>
									<Image src={logo} alt='Wang Logo' boxSize='6vh' p={'0.5vh'} rounded={'full'} marginRight={'1vh'} marginLeft={'2vh'} />
									{/* <Text fontWeight={'900'} fontSize={'3vh'} fontFamily={'Work Sans'}> WANG </Text> */}
								</Flex>
							
								<Flex flex={'.5'} alignItems={'center'} justifyContent={'flex-end'} marginRight={'3vh'}>
									{
										(props.isHome && !isSearching) &&
										<Box marginRight={'3vh'} cursor={'pointer'} onClick={() => setIsSearching(!isSearching)}>
											<SearchIcon boxSize={'4vh'} />
										</Box>
									}
									<Box marginBottom={'0.6vh'} onClick={() => isLogged ? navigate('/profile') : navigate('/signin')}  >
										<ProfileIcon boxSize={'3.5vh'} />
									</Box>
								</Flex>
							</Flex>

							<Flex marginTop={'3%'} alignItems={'center'} justify={'space-between'} px={4}>
								<Box cursor={'pointer'} onClick={() => setIsSearching(!isSearching)} mb={1}>
									<XMarkIcon boxSize={'2vh'} />
								</Box>
								<Input
									type='search'
									placeholder='Nombre de producto, categoría, etc...'
									fontSize={'1.7vh'}
									fontFamily={'Work Sans'}
									fontWeight={'700'}
									borderRadius={20}
									focusBorderColor={'lightgray'}
									borderColor={'lightgray'}
									color={'black'}
									h={'3.5vh'}
									size={'lg'}
									width={'85%'}
									value={searchText}
									defaultValue={searchText}
									onChange={(event) => setSearchText(event.target.value)}
									onKeyDown={handleKeyDown}
								/>
								<Box onClick={()=> handleSearchButton()}>
									<SearchIcon borderWidth={'0px'} pt={1} boxSize={'3.5vh'} />
								</Box>
							</Flex>
						</Flex>

						:
						<Flex alignItems={'center'} position={'sticky'} top={24} justify={'space-between'} width={'100%'}>
							
							<Flex alignItems='center' flex={'.4'} onClick={() => navigate('/')}>
								<Image src={logo} alt='Wang Logo' boxSize='6vh' p={'0.5vh'} rounded={'full'} marginRight={'1vh'} marginLeft={'2vh'} />
							</Flex>

							<Flex flex={.5} alignSelf={'center'} justifyContent={'center'} ml={'5'}>
								<Select value={language} onChange={handleLanguageChange} size={'sm'} alignSelf={'center'} borderRadius={'3vh'}>
									<option value="spanish">Español</option>
									<option value="chinese">简体中文</option>
								</Select>
							</Flex>

							<Flex flex={'.5'} border={'0px'} alignItems={'center'} justifyContent={'flex-end'} marginRight={'3vh'}>
								{
									props.isHome &&
									<Box marginRight={'2vh'} onClick={() => setIsSearching(!isSearching)}>
										<SearchIcon boxSize={'4vh'} />
									</Box>
								}
								<Box >
									{/* <ProfileIcon boxSize={'3.5vh'} onClick={() => isLogged ? navigate('/profile') : navigate('/signin')} /> */}

									<Menu isLazy>
										<MenuButton>
											<Flex alignItems={'center'} >
												<ProfileIcon boxSize={'3.5vh'} />
												{/* <Text marginLeft={'1vh'} fontSize={'2vh'} fontWeight={'semibold'} fontFamily={'Work Sans'}>{isLogged ? '会员' : '会员登入'}</Text> */}
											</Flex>
										</MenuButton>
										{
											isLogged ?
												<MenuList minW={'24'}>
													<MenuItem onClick={() => navigate('/profile')}>{translations['My information']?.[language] || 'Mis Datos'}</MenuItem>
													<MenuItem onClick={() => navigate('/orders')}>{translations['My orders']?.[language] || 'Mis Pedidos'}</MenuItem>
													<MenuItem onClick={() => navigate('/faqs')}>{translations['Frequently Asked Questions']?.[language] || 'Preguntas Frecuentes'}</MenuItem>
													<MenuItem onClick={() => handleLogout()}>{translations['Log out']?.[language] || 'Cerrar Sesión'}</MenuItem>
												</MenuList> :
												<MenuList minW={'24'}>
													<MenuItem onClick={() => navigate('/signin', { state: { loginOrRegister: 0 } })}>{translations['Log in']?.[language] || 'Iniciar Sesión'}</MenuItem>
													<MenuItem onClick={() => navigate('/signin', { state: { loginOrRegister: 1 } })}>{translations['Sign up']?.[language] || 'Registrarme'}</MenuItem>
												</MenuList>
										}
									</Menu>
								</Box>
							</Flex>
						</Flex>
				}
			</>
		);
	};

	const desktopFront = () => {
		return (
			<Flex flexDirection={'column'}>
				{/* <Flex backgroundColor={'#CC5826'} paddingBlock={'0.5vh'} alignItems={'center'} justifyContent={'center'}>
					<Text color={'white'}  fontSize={'14pt'} fontFamily={'Work Sans'} mr='2'>我们依然保留旧版</Text>
					<Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} as={Link} to="http://www.tich.ar" >
						<Text color={'white'}  fontSize={'16pt'} fontWeight={'semibold'} fontFamily={'Work Sans'} textDecor={'underline'} mr='2'>天创</Text>
						<Image src={oldLogo} borderRadius={20} alt='Tich old Logo' boxSize={'4vh'}  backgroundColor={'white'}/>
					</Flex>
				</Flex> */}
				<Flex paddingY={'.3vh'}>
					<Flex flex={'.15'} alignSelf={'center'} justifyContent={'center'} alignItems='center' onClick={() => navigate('/')} cursor={'pointer'}>
						<Image src={logo} alt='Wang Logo' boxSize={'4.5vh'} rounded={'none'} marginRight={'1vh'} />
						{/* <Text fontWeight={'extrabold'} fontSize={'27'} fontFamily={'Work Sans'}> WANG </Text> */}
					</Flex>
					{/* <Flex  mt={'1.5'} height={'50%'} boxShadow={'lg'} borderRadius={10} as={Link} to="http://www.tich.ar" flex={'.2'} marginLeft={'1vh'} marginRight={'5vh'} alignItems='center' justifyContent={'space-evenly'} backgroundColor={'white'} onClick={() => navigate('/')} cursor={'pointer'}>
						<Text fontWeight={'extrabold'} fontSize={'20'} fontFamily={'Work Sans'}> 回到旧 </Text>
						<Image src={oldLogo} alt='Tich old Logo' boxSize={'8vh'}  backgroundColor={'white'}/>
					</Flex> */}

					<Flex width={'30%'} alignItems='center' flex={'.6'} marginLeft={'0vh'} marginRight={'0vh'}>
						<InputGroup>
							<InputLeftAddon
								backgroundColor={'white'}
								border={'1px'}
								borderColor={'#8E8E8E'}
								borderLeftRadius={'3vh'}
								height={'4vh'}
								width={'5%'}
								justifyContent={'center'}
								paddingLeft={'3vh'}
								borderRightWidth={'0px'}
								paddingY={'2vh'}
							>
								<SearchIcon boxSize={'3.5vh'} marginTop={'.5vh'} />
							</InputLeftAddon>
							<Input
								type='search'
								placeholder={ translations['Search']?.[language]+'...' || 'Buscar...'}
								fontSize={'1.7vh'}
								fontFamily={'Work Sans'}
								fontWeight={'700'}
								borderRightRadius={'3vh'}
								borderColor={'#8E8E8E'}
								_hover={{ borderColor: '#8E8E8E' }}
								_focusVisible={{ borderColor: '#8E8E8E' }}
								height={'4vh'}
								borderLeftWidth={'0px'}
								paddingY={'2vh'}
								defaultValue={searchText}
								value={searchText}

								onChange={(event) => setSearchText(event.target.value)}
								onKeyDown={handleKeyDown}

							/>
						</InputGroup>

					</Flex>
					<Flex flex={.1} alignSelf={'center'} justifyContent={'center'} ml={'5'}>
						<Select value={language} onChange={handleLanguageChange} size={'sm'} alignSelf={'center'} borderRadius={'3vh'}>
							<option value="spanish">Español</option>
							<option value="chinese">简体中文</option>
						</Select>
					</Flex>

					{/* <Flex 
						flex={'.5'}
						flexDirection={'column'}
						justify={'center'}
						padding='2'
						borderRadius={10}
						marginRight={'2vh'}
						backgroundColor={'white'}
					>
						<Flex flexDirection={'row'} alignItems={'center'}>
							<PhoneIcon mx='1'/>
							<Text fontWeight={'bold'} fontSize={14}>4587-2557 / 11-2717-1142</Text>
						</Flex>
						<Flex flexDirection={'row'} alignItems={'center'}>
							<Image src={wappIcon} w={'2.5vh'} mr={'0'}/>
							<Text fontWeight={'bold'} fontSize={14}>+54 911 2717 1142</Text>
						</Flex>
						<Flex flexDirection={'row'} alignItems={'center'}>
							<Image src={mpIcon} w={'2.2vh'} h={'1.8vh'} mr={'1'}/>
							<Text fontWeight={'bold'} fontSize={13} mr='2'>Mercadopago电子邮箱:</Text>
							<Text fontSize={13}>wgygw1@gmail.com</Text>
						</Flex>
					</Flex> */}

					<Flex justifyContent={'center'} alignSelf={'center'} flex={'.25'}>
						<Menu isLazy>
							<MenuButton>
								<Flex  alignItems={'center'} onClick={() => isLogged ? navigate('/profile') : navigate('/signin')} cursor={'pointer'}>
									<ProfileIcon boxSize={'2.5vh'} />
									<Text marginLeft={'1vh'} fontSize={'2vh'} fontWeight={'semibold'} fontFamily={'Work Sans'}>{isLogged ? translations['Customer']?.[language] || 'Cliente' : translations['Log in']?.[language] || 'Ingresar'}</Text>
								</Flex>
							</MenuButton>
							{
								isLogged ?
									<MenuList minWidth={'24'} ml={'-3'}>
										<MenuItem onClick={() => navigate('/profile')}>{translations['My information']?.[language] || 'Mis Datos'}</MenuItem>
										<MenuItem onClick={() => navigate('/orders')}>{translations['My orders']?.[language] || 'Mis Pedidos'}</MenuItem>
										<MenuItem onClick={() => navigate('/faqs')}>{translations['Frequently Asked Questions']?.[language] || 'Preguntas Frecuentes'}</MenuItem>
										<MenuItem onClick={() => handleLogout()}>{translations['Log out']?.[language] || 'Cerrar Sesión'}</MenuItem>
									</MenuList> :
									<MenuList minWidth={'24'} ml={'3'} textAlign={'center'}>
										<MenuItem onClick={() => navigate('/signin', { state: { loginOrRegister: 0 } })}>{translations['Log in']?.[language] || 'Iniciar Sesión'}</MenuItem>
										<MenuItem onClick={() => navigate('/signin', { state: { loginOrRegister: 1 } })}>{translations['Sign up']?.[language] || 'Registrarme'}</MenuItem>
									</MenuList>
							}
						</Menu>
						{
							!validateCartPreview(pathLocation.pathname) &&
							<>
								<Flex
									flexDirection={'column'}
									backgroundColor={'white'}
									boxShadow={'lg'} 
									position={'fixed'} 
									height={'5vh'} // {50000 - cartContext.totalPrice > 0 ? '10vh' : '5vh'}
									width={'17vh'} 
									borderRadius={'15'} 
									bottom={'4vh'} 
									right={'2vh'} 
									justify={'center'} 
									align={'center'} 
									zIndex={3}
								>
									{/* {
										50000 - cartContext.totalPrice > 0 ?
											<Box>
												<Flex flex={1} flexDirection={'column'} alignItems={'center'} paddingTop={'3'}>
													<Text fontFamily={'Work sans'} color={'#5F5F5F'} fontWeight={'bold'} textAlign={'center'} fontSize={15}>{translations['Minimum purchase']?.[language] || 'Mínimo de compra'}</Text>
													<Text fontFamily={'Work sans'} color={'#FD5756'} fontWeight={'bold'} fontSize={15}>$50000</Text>
												</Flex>
											</Box>
											: null
									} */}
									<Flex onClick={() => navigate('/cart')} flex={1} backgroundColor={'#5F993A'} width={'100%'} paddingInline={'4'} borderBottomRadius={'15'} 
										borderTopRadius={'15'}  // {50000 - cartContext.totalPrice > 0 ? '15' : '0'}
										flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
										<WhiteCartIcon boxSize={'2vh'} />
										<Text fontFamily={'Work sans'} color={'white'} fontWeight={'bold'}> ${cartContext.totalPrice}</Text>
									</Flex>
								</Flex>
							</>
						}
						<Flex marginLeft={'6vh'} alignItems={'center'} marginRight={'0vh'} onClick={() => navigate('/cart')} cursor={'pointer'}>
							<CartIcon boxSize={'2.5vh'} color='black' />
							<Text marginLeft={'1vh'} fontSize={'2vh'} fontWeight={'semibold'} fontFamily={'Work Sans'}>{translations['Cart']?.[language] || 'Carrito'}</Text>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		);
	};



	return (
		<Box as="header" position="fixed" w="100%" zIndex={10} backgroundColor="white" borderBottomWidth={'2px'} boxShadow={''}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
}
