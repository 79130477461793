import React, { createContext, useEffect, useState } from 'react';
import { getTranslations } from '../api/translation';
import { saveDataLocal, getDataLocal } from '../helpers/LocalStorage';

interface TranslationContextType {
    translations: { [key: string]: { [key: string]: string } };
    setTranslations: (translations: { [key: string]: { [key: string]: string } }) => void;
	language: string;
	setLanguage: (language: string) => void;
}

const defaultValues: TranslationContextType = {
	translations: {},
	setTranslations: () => { return; },
	language: 'spanish',
	setLanguage: () => { return; }
};

const TranslationContext = createContext<TranslationContextType>(defaultValues);

export default function TranslationProvider({ children }: { children: React.ReactNode }) {

	const [translations, setTranslations] = useState<any>(defaultValues.translations);
	const [language, setLanguage] = useState<string>(defaultValues.language);
	// const [loading, setLoading] = useState<boolean>(defaultValues.loading);

	useEffect(() => {
		const storedTranslations = getDataLocal('translations');
		if (storedTranslations && storedTranslations.length > 0) {
			setTranslations(JSON.parse(storedTranslations));
			// setLoading(false);
		} else {
			fetchTranslations();
		}
	}, [language]);

	const fetchTranslations = async () => {
		try {
			const translations = await getTranslations();
			saveDataLocal('translations', JSON.stringify(translations));
			setTranslations(translations);
			// setLoading(false);
		} catch (error) {
			console.error('Error fetching translations', error);
			// setLoading(false);
		}
	};

	return (
		<TranslationContext.Provider value={{ translations, setTranslations, language, setLanguage }}>
			{children}
		</TranslationContext.Provider>
	);
}
export const useTranslation = () => {
	return React.useContext(TranslationContext);
};
