import { GetAllCompaniesEndpoint } from '../variables/Connections';

export async function getCompanies(): Promise<any> {
	try{
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		};
		const response = await fetch(GetAllCompaniesEndpoint, requestOptions);
		if (response.ok) {
			const companies = await response.json();
			return companies.data;
		} else {
			return [];
		}
	} catch(error) {
		console.log('error getCompanies ', error);
		return [];
	}
}       