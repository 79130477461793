import React, { useEffect, useContext } from 'react';
import { Box, Image, Flex, Text, Spinner, VStack } from '@chakra-ui/react';

import '@fontsource/work-sans';

import cartImage from '../../assets/cartImage.png';
// import desktopCartVerticalBanner from '../../assets/desktopCartVerticalBanner.png';
// import desktopCartComingSoon from '../../assets/desktopCartComingSoon.png';
// import desktopCartPromotions from '../../assets/desktopCartPromotions.png';
import CartStepper from './CartStepper';
// import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { useTranslation } from '../../contexts/TranslationContext';

interface Props {
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>
	setCreatedOrder: React.Dispatch<React.SetStateAction<object>>
}

export default function CartDesktop(props: Props) {

	// const [originalProducts, setOriginalProducts] = React.useState<Product[]>([])
	// const [products, setProducts] = React.useState<Product[]>([])

	const [update, setUpdate] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	// const navigate = useNavigate();
	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);
	const cartList = cartContext.cartList;

	useEffect(() => {
		loadCart();
	}, []);

	async function loadCart() {
		setLoading(true);
		await cartContext.getUserCart();
		setLoading(false);
	}

	const hasProducts = () => {
		return (
			<Box paddingBottom={'8%'}>
				<Flex justify={'center'} paddingTop={'8%'} >
					<VStack mr={'1%'} spacing={'2%'}>
						{/* <Image src={desktopCartVerticalBanner} h={'40.5vh'} onClick={() => navigate('/search', {state: {group: '1', searchTextParam: {searchText:''}}})}/>
						<Image src={desktopCartVerticalBanner} h={'40.5vh'} onClick={() => navigate('/search', {state: {group: '1', searchTextParam: {searchText:''}}})}/> */}
						{/* <Image src={desktopCartComingSoon} w={'44vh'} onClick={() => navigate('/search', {state: {group: '4', searchTextParam: {searchText:''}}})}/>
						<Image src={desktopCartPromotions} w={'44vh'} onClick={() => navigate('/search', {state: {group: '2', searchTextParam: {searchText:''}}})}/> */}
					</VStack>

					{/* <Box marginRight={'.5%'} width={'13%'} maxWidth={'13%'}>

						<Box backgroundColor={'#FFFFFF'} borderRadius={'.7vh'} shadow={'md'} marginBottom={'5%'} paddingY={'10%'} justifyContent={'center'} height={'8rem'}>
							<Flex alignItems={'center'} justify={'center'}>
								<Box textAlign={'center'}>
									<Text color={'#5075A2'} fontWeight={'bold'} fontSize={'1.9vh'}>生活家電生</Text>
									<Text fontSize={'1.4vh'}>生活家電生</Text>
								</Box>
								<Image src={suitcase} width={'40%'} />
							</Flex>
						</Box>

						<Box backgroundColor={'#85694D'} borderRadius={'.7vh'} shadow={'md'} marginBottom={'5%'} display={'flex'} paddingY={'10%'} height={'18rem'} >
							<Box textAlign={'center'}>
								<Text fontWeight={'bold'} color={'white'} fontSize={'1.9vh'}>生活家電生</Text>
								<Text color={'white'} fontSize={'1.4vh'} marginBottom={'2vh'}>生活家電生</Text>
								<Box display={'flex'} justifyContent="center">
									<Image src={suitcase} width={'80%'} />
								</Box>
							</Box>
						</Box>

						<Box backgroundColor={'#FFFFFF'} borderRadius={'.7vh'} shadow={'md'} paddingY={'10%'} height={'8rem'}>
							<Flex alignItems={'center'} justify={'center'}>
								<Box textAlign={'center'}>
									<Text color={'#5075A2'} fontWeight={'bold'} fontSize={'1.9vh'}>生活家電生</Text>
									<Text fontSize={'1.4vh'}>生活家電生</Text>
								</Box>
								<Image src={suitcase} width={'40%'} />
							</Flex>
						</Box>

					</Box>

					<Box marginRight={'1%'} width={'10%'} maxWidth={'10%'}>
						<Box backgroundColor={'#CADBE5'} textAlign={'center'} borderRadius={'.7vh'} shadow={'md'} marginBottom={'5%'} paddingY={'14%'} height={'17.4rem'}>
							<Text color={'#5075A2'} fontWeight={'bold'}>生活家電生</Text>
							<Text marginBottom={'2vh'}>生活家電生</Text>
							<Image src={suitcase} width={'90%'} marginBottom={'2vh'} />
						</Box>
						<Box backgroundColor={'#E8A765'} textAlign={'center'} borderRadius={'.7vh'} shadow={'md'} paddingY={'14%'} height={'17.4rem'}>
							<Text color={'#6F4011'} fontWeight={'bold'}>生活家電生</Text>
							<Text color={'white'} marginBottom={'2vh'}>生活家電生</Text>
							<Image src={suitcase} width={'90%'} marginBottom={'2vh'} />
						</Box>
					</Box> */}

					<Box width={'50%'} boxShadow={'md'} borderRadius={4}>
						<CartStepper setSuccess={props.setSuccess} setCreatedOrder={props.setCreatedOrder} setUpdate={setUpdate} update={update} />
					</Box>

				</Flex>
			</Box>
		);
	};


	const noProducts = () => {
		return (
			<Box paddingBottom={'8vh'} paddingTop={'15%'}>
				<Flex justify={'center'} >
					<Image src={cartImage} alt="cartImage" />
				</Flex>
				<Box textAlign={'center'} marginTop={'2%'}>
					{/* <Text fontFamily={'Work sans'} fontSize={'3vh'} fontWeight={'bold'}> ¿Tu carrito está vacío? </Text>
					<Text fontFamily={'Work sans'} fontSize={'2vh'}>¡Eso no está bien! ¡Añade productos ya!</Text> */}
					<Text fontFamily={'Work sans'} fontSize={'3vh'} fontWeight={'bold'}>{translations['Your cart is empty? That is not good! Add products now!']?.[language] || '¿Tu carrito está vacío? ¡Eso no está bien! ¡Añade productos ya!'}</Text>
				</Box>

				{/* <Flex justify={'center'} marginTop={'3%'} >
                    <Image src={cartTitleImageDesktop} alt="cartTitleImageDesktop" width={'80%'}/>
                </Flex> */}

				{/* <Flex marginTop={'2%'} justify={'center'}>
                    <ListOfProducts width='100%' products={products} originalProducts={originalProducts} isHome={false} isSearch={false} />
                </Flex> */}

				{/* PROMOCIONES */}
				{/* <Center mt={'5%'}>
					<HStack mr={'1%'} spacing={'2%'}>
						<Image src={desktopCartVerticalBanner} h={'35vh'} />
						<VStack spacing={'2%'}>
							<Image src={desktopCartComingSoon} w={'44vh'}/>
							<Image src={desktopCartPromotions} w={'44vh'}/>
						</VStack>
					</HStack>
				</Center> */}

				{/* <Box display={'flex'} justifyContent={'center'} marginTop={'3%'}>
					<Flex width={'71%'} justifyContent={'space-between'}>
						<Box backgroundColor={'#CADBE5'} textAlign={'center'} padding={'2%'} borderRadius={'2%'} paddingY={'5%'} boxShadow={'md'} width={'20%'} height={'auto'}>
							<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2.5vh'}>生活家電生</Text>
							<Text fontFamily={'Work Sans'} fontSize={'2vh'}>生活家電生</Text>
							<Box marginTop={'10%'} width={'100%'}>
								<Image src={suitcase} width={'100%'} height={'auto'} />
							</Box>
						</Box>
						<Box width={'30%'} marginLeft={'1%'}>
							<Flex backgroundColor={'white'} height={'48%'} borderRadius={'2%'} boxShadow={'md'}>
								<Box width={'70%'} textAlign={'center'} paddingTop={'20%'}>
									<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2vh'}>生活家電生</Text>
									<Text fontFamily={'Work Sans'} fontSize={'1.6vh'}>生活家電生</Text>
								</Box>
								<Box marginTop={'5%'} width={'100%'}>
									<Image src={suitcase} width={'70%'} height={'auto'} />
								</Box>
							</Flex>
							<Flex backgroundColor={'white'} height={'48%'} marginTop={'5%'} borderRadius={'2%'} boxShadow={'md'}>
								<Box width={'70%'} textAlign={'center'} paddingTop={'20%'}>
									<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2vh'}>生活家電生</Text>
									<Text fontFamily={'Work Sans'} fontSize={'1.6vh'}>生活家電生</Text>
								</Box>
								<Box marginTop={'5%'} width={'100%'}>
									<Image src={suitcase} width={'70%'} height={'auto'} />
								</Box>
							</Flex>
						</Box>
						<Box width={'30%'} marginInline={'1%'}>
							<Flex backgroundColor={'white'} height={'48%'} borderRadius={'2%'} boxShadow={'md'}>
								<Box width={'70%'} textAlign={'center'} paddingTop={'20%'}>
									<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2vh'}>生活家電生</Text>
									<Text fontFamily={'Work Sans'} fontSize={'1.6vh'}>生活家電生</Text>
								</Box>
								<Box marginTop={'5%'} width={'100%'}>
									<Image src={suitcase} width={'70%'} height={'auto'} />
								</Box>
							</Flex>
							<Flex backgroundColor={'white'} height={'48%'} marginTop={'5%'} borderRadius={'2%'} boxShadow={'md'}>
								<Box width={'70%'} textAlign={'center'} paddingTop={'20%'}>
									<Text fontFamily={'Work Sans'} color={'#5075A2'} fontWeight={'bold'} fontSize={'2vh'}>生活家電生</Text>
									<Text fontFamily={'Work Sans'} fontSize={'1.6vh'}>生活家電生</Text>
								</Box>
								<Box marginTop={'5%'} width={'100%'}>
									<Image src={suitcase} width={'70%'} height={'auto'} />
								</Box>
							</Flex>
						</Box>
						<Box backgroundColor={'#E8A765'} textAlign={'center'} padding={'2%'} borderRadius={'2%'} paddingY={'5%'} boxShadow={'md'} width={'20%'} height={'auto'}>
							<Text fontFamily={'Work Sans'} color={'#6F4011'} fontWeight={'bold'} fontSize={'2.5vh'}>生活家電生</Text>
							<Text fontFamily={'Work Sans'} fontSize={'2vh'}>生活家電生</Text>
							<Box marginTop={'10%'} width={'100%'}>
								<Image src={suitcase} width={'100%'} height={'auto'} />
							</Box>
						</Box>
					</Flex>
				</Box> */}
			</Box>
		);
	};


	return (
		<Box backgroundColor={'#F5F3F3'} minHeight={'100vh'} maxH="100%">
			{
				loading ?

					<Flex justifyContent={'center'} paddingTop={'10%'}>
						<Spinner size={'xl'} />
					</Flex>

					:

					<Box>
						{Object.keys(cartList).length === 0 ? noProducts() : hasProducts()}
					</Box>
			}
		</Box>
	);
}
