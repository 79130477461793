
// 0: Requested #45aaf2
// 1: Reviewing #c8a2c8
// 2: Updated #ffc107
// 3: Confirmed #45aaf2
// 4: Preparing #e06709
// 5: Completed #5eba00
// 6: Cancelled #868e96
// 7: Rejected #6c757d

export default function statusMapper(orderStatus: number) {
	if (orderStatus === 0) return {
		backgroundColor: '#45AAF2',
		status: 'Pendiente'
	};
	if (orderStatus === 1) return {
		backgroundColor: '#C8A2C8',
		status: 'Revisando'
	};
	if (orderStatus === 2) return {
		backgroundColor: '#FFC107',
		status: 'Actualizado'
	};
	if (orderStatus === 3) return {
		backgroundColor: '#45AAF',
		status: 'Confirmado'
	};
	if (orderStatus === 4) return {
		backgroundColor: '#E06709',
		status: 'Preparando'
	};
	if (orderStatus === 5) return {
		backgroundColor: '#5EBA00',
		status: 'Completado'
	};
	if (orderStatus === 6) return {
		backgroundColor: '#868E96',
		status: 'Cancelado'
	};
	if (orderStatus === 7) return {
		backgroundColor: '#6C757D',
		status: 'Rechazado'
	};
	return {
		backgroundColor: '',
		status: 'Pendiente'
	};
}
