import React, { useEffect, useContext } from 'react';
import { Box, Image, Flex, Text, Spinner } from '@chakra-ui/react';

import '@fontsource/work-sans';

import cartImage from '../../assets/cartImage.png';
import cartTitleImageMobile from '../../assets/cartTitleImageMobile.png';

import { ScrollToTop } from '../../assets/icons/iconList';
import { CartContext } from '../../contexts/CartContext';
import { useTranslation } from '../../contexts/TranslationContext';
import MobileHeader from '../MobileHeader';
import CartStepperMobile from './CartStepperMobile';

interface Props {
	setSuccess: React.Dispatch<React.SetStateAction<boolean>>
	setCreatedOrder: React.Dispatch<React.SetStateAction<object>>
}

export default function CartMobile(props: Props) {

	const { translations, language } = useTranslation();
	const cartContext = useContext(CartContext);
	const cartList = cartContext.cartList;

	const [update, setUpdate] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const handleScrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};


	useEffect(() => {
		loadCart();
	}, []);

	async function loadCart() {
		setIsLoading(true);
		await cartContext.getUserCart();
		setIsLoading(false);
	}

	return (
		<Box backgroundColor={'#F5F3F3'} overflowY="scroll" borderWidth={'1px'}>
			<MobileHeader buttonName={translations['Clear cart']?.[language] || 'Limpiar carrito'} buttonColor='#8150E2' buttonAction={() => cartContext.emptyCart()} title={translations['Cart']?.[language] || 'Carrito'} goBackTo='/' screenID={0} />
			{
				!isLoading && Object.keys(cartList).length !== 0 ?
					<Box>
						<CartStepperMobile setSuccess={props.setSuccess} setCreatedOrder={props.setCreatedOrder} setUpdate={setUpdate} update={update} />
						{/* <CartFooter /> */}
					</Box>

					:

					isLoading ?
						<Flex justifyContent={'center'} height={'100vh'} mt='100%'>
							<Spinner size={'xl'} />
						</Flex>

						:
						<Box marginTop={'45%'} height={'100vh'}>
							<Box position={'fixed'} right={'1vh'} bottom={'1vh'} zIndex={1} onClick={handleScrollToTop} >
								<ScrollToTop boxSize={'5vh'} />
							</Box>

							<Flex justify={'center'} marginTop={'10%'} >
								<Image src={cartImage} alt="cartImage" />
							</Flex>
							<Box textAlign={'center'} marginTop={'2%'}>
								{/* <Text fontFamily={'Work sans'} fontSize={'3vh'} fontWeight={'bold'}> ¿Tu carrito está vacío? </Text>
								<Text fontFamily={'Work sans'} fontSize={'2vh'}> ¡Eso no está bien! ¡Añade productos ya!</Text> */}
								<Text fontFamily={'Work sans'} fontSize={'3vh'} fontWeight={'bold'}>{translations['Your cart is empty? That is not good! Add products now!']?.[language] || ''}</Text>
							</Box>

							<Flex justify={'center'} marginTop={'20%'} >
								<Image src={cartTitleImageMobile} alt="cartTitleImageMobile" width={'70%'} />
							</Flex>

							{/* <ListOfProducts products={props.products} originalProducts={null} isHome={false} /> */}
						</Box>
			}
		</Box >
	);
}
