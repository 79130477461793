import { Box, Button, Container, Input, SimpleGrid, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { mobileMinDimensions, mobileMaxDimensions, tabletMinDimensions, tabletMaxDimensions, smallScreenMinDimensions, smallScreenMaxDimensions, largeScreenMinDimensions } from '../variables/responsiveDimensions';
import NavBar from '../components/NavBar';
import { getDataLocal, saveDataLocal } from '../helpers/LocalStorage';
import { useTranslation } from '../contexts/TranslationContext';
import { updateUser } from '../api/auth';
import { fireAlert } from '../helpers/Tich/TichAlert';
export default function Profile() {

	const isMobile = useMediaQuery({ minWidth: mobileMinDimensions, maxWidth: mobileMaxDimensions });
	const isTablet = useMediaQuery({ minWidth: tabletMinDimensions, maxWidth: tabletMaxDimensions });
	const isSmallScreen = useMediaQuery({ minWidth: smallScreenMinDimensions, maxWidth: smallScreenMaxDimensions });
	const isLargeScreen = useMediaQuery({ minWidth: largeScreenMinDimensions });

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [userInfo, setUserInfo] = useState(getDataLocal('user').isLogged ? getDataLocal('user') : null);
	const [updateInfo, setUpdateInfo] = useState({actualPhone:userInfo?.phone});
	const { translations, language } = useTranslation();

	const updateProfile = async () => {
		if (Object.keys(updateInfo).length === 1) {
			console.log('No hay nada que actualizar');
			return;
		}
		const response = await updateUser(updateInfo);
		console.log(response);
		if(!response.ok){
			fireAlert(translations['Something went wrong, the profile can\'t be updated']?.[language] || 'Algo salio mal, no se pude actualizar el perfil', 'error');
			return;
			// console.log('Algo salio mal, no se pude actualizar el perfil');
		}

		// Borro el actualPhone para que no se actualice en el local storage
		delete updateInfo.actualPhone;
		// Actualizo el local storage para no tener que hacer un fetch de nuevo
		const auxUserInfo = {...userInfo, ...updateInfo};
		saveDataLocal('user', JSON.stringify(auxUserInfo));
		setUserInfo(getDataLocal('user'));
		setUpdateInfo({actualPhone:userInfo?.phone});
		fireAlert(translations['Information updated']?.[language] || 'Información actualizada', 'success', true);
	};

	const handleInputChange = (key: any, value: any, actualValue: any) => {
		console.log(key, value);
		if (value !== actualValue && actualValue !== undefined) {
			setUpdateInfo(updateInfo => ({
				...updateInfo,
				[key]: value
			}));
		}
	};

	const desktopFront = () => {
		return (
			<Box zIndex={0} minHeight={'100vh'} bg={'#F5F5F5'}>
				<NavBar isHome={false} />
				<Container pt={'20vh'} maxW={'155vh'}>
					<Text pb={'8'} fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'}>{translations['Account information']?.[language] || 'Información de cuenta'}</Text>
					<Box w={'100%'} p={5} borderRadius={5} bg={'#FFFFFF'}>
						<SimpleGrid columns={2} spacing={8}>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Name']?.[language] || 'Nombre'}</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('username', e.target.value, userInfo?.username)}
									defaultValue={userInfo?.username}
								/>
							</Box>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>CUIL/CUIT/DNI:</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('taxId', e.target.value, userInfo?.taxId)}
									defaultValue={userInfo?.taxId}
								/>
							</Box>

						</SimpleGrid>
						<Box my={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Address']?.[language] || 'Dirección'}:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('address', e.target.value, userInfo?.address)}
								defaultValue={userInfo?.address}
							/>
						</Box>
						<SimpleGrid columns={2} spacing={8}>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['City/Province']?.[language] || 'Localidad/Provincia'}:</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('location', e.target.value, userInfo?.location)}
									defaultValue={userInfo?.location}
								/>
							</Box>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Postal code']?.[language] || 'Código postal'}:</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('postalCode', e.target.value, userInfo?.postalCode)}
									defaultValue={userInfo?.postalCode}
								/>
							</Box>
						</SimpleGrid>

						

						<SimpleGrid my={'2vh'} columns={2} spacing={8}>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Cellphone number']?.[language] || 'Número de celular'}:</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('phone', e.target.value, userInfo?.phone)}
									defaultValue={userInfo?.phone}
								/>
							</Box>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>WhatsApp:</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('whatsapp', e.target.value, userInfo?.whatsapp)}
									defaultValue={userInfo?.whatsapp}
								/>
							</Box>
							<Box>
								<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Email']?.[language] || 'Correo electrónico'}:</Text>
								<Input
									fontFamily={'Work sans'}
									borderColor={'#A7A6A6'}
									type='text'
									onBlur={(e: any) => handleInputChange('email', e.target.value, userInfo?.email)}
									defaultValue={userInfo?.email}
								/>
							</Box>
						</SimpleGrid>
						<Box mt={'3vh'} display='flex' alignItems={'center'} justifyContent={'flex-end'}>
							<Button
								w={'14%'}
								fontWeight={'normal'}
								fontFamily={'Work sans'}
								colorScheme='#4C7D2C'
								onClick={() => updateProfile()}
								bg={'#4C7D2C'}>{translations['Update']?.[language] || 'Actualizar'}</Button>
						</Box>
					</Box>
				</Container>
			</Box>
		);
	};

	const mobileFront = () => {
		return (
			<Box zIndex={0} minHeight={'100vh'} bg={'#F5F5F5'}>
				<NavBar isHome={false} />
				<Container pt={'10vh'} maxW={'155vh'}>
					<Text pb={'5'} fontSize={'22'} fontWeight={'bold'} fontFamily={'Work sans'}>{translations['Account information']?.[language] || 'Información de cuenta'}</Text>
					<Box w={'100%'} p={5} borderRadius={5} bg={'#FFFFFF'}>
						<Box backgroundColor={'white'} >
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Name']?.[language] || 'Nombre'}</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('username', e.target.value, userInfo?.username)}
								defaultValue={userInfo?.username}
							/>
						</Box>
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>CUIL/CUIT/DNI:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('taxId', e.target.value, userInfo?.taxId)}
								defaultValue={userInfo?.taxId}
							/>
						</Box>
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Address']?.[language] || 'Dirección'}: ({translations['Required'][language]|| 'Requerido'})</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('address', e.target.value, userInfo?.address)}
								defaultValue={userInfo?.address}
							/>
						</Box>
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['City/Province']?.[language] || 'Localidad/Provincia'}:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('location', e.target.value, userInfo?.location)}
								defaultValue={userInfo?.location}
							/>
						</Box>
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Postal code']?.[language] || 'Código postal'}:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('postalCode', e.target.value, userInfo?.postalCode)}
								defaultValue={userInfo?.postalCode}
							/>
						</Box>
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Cellphone number']?.[language] || 'Número de celular'}: ({translations['Required']?.[language]|| 'Requerido'})</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('phone', e.target.value, userInfo?.phone)}
								defaultValue={userInfo?.phone}
							/>
						</Box>
						{/* <Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>微信ID号:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('wechat', e.target.value, userInfo?.wechat)}
								defaultValue={userInfo?.wechat}
							/>
						</Box> */}
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>WhatsApp:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('whatsapp', e.target.value, userInfo?.whatsapp)}
								defaultValue={userInfo?.whatsapp}
							/>
						</Box>
						<Box backgroundColor={'white'} marginTop={'2vh'}>
							<Text mb={'1vh'} fontFamily={'Work sans'}>{translations['Email']?.[language] || 'Correo electrónico'}:</Text>
							<Input
								fontFamily={'Work sans'}
								borderColor={'#A7A6A6'}
								type='text'
								onBlur={(e: any) => handleInputChange('email', e.target.value, userInfo?.email)}
								defaultValue={userInfo?.email}
							/>
						</Box>
						<Box mt={'5vh'} display='flex' alignItems={'center'} justifyContent={'center'}>
							<Button
								w={'100%'}
								fontWeight={'normal'}
								fontFamily={'Work sans'}
								colorScheme='#4C7D2C'
								onClick={() => updateProfile()}
								bg={'#4C7D2C'}>{translations['Update']?.[language] || 'Actualizar'}</Button>
						</Box>
					</Box>
				</Container>
			</Box>
		);
	};

	return (
		<Box as="header" w="100%" zIndex={0} backgroundColor="white" borderBottomWidth={'2px'} boxShadow={''}>
			{(isMobile || isTablet || isSmallScreen) && mobileFront()}
			{(isLargeScreen) && desktopFront()}
		</Box>
	);
}
