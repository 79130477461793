
const baseUrl = process.env.REACT_APP_API_URL;


// LOGIN && REGISTER && USER

export const LoginEndpoint = baseUrl + '/api/user/login';
export const RegisterEndpoint = baseUrl + '/api/user/';
export const UpdateUserEndpoint = baseUrl + '/api/user/update/';

// COMPANY

export const GetAllCompaniesEndpoint = baseUrl + '/api/company/all'; // GET

// PRODUCTS

export const ProductsEndpoint = baseUrl + '/api/product'; 

// GET CATEGORIES && GROUPS

export const GetCategoriesEndpoint = baseUrl + '/api/product/category';
export const GetGroupsEndpoint = baseUrl + '/api/product/group';

// CART

export const GetCartEndpoint = baseUrl + '/api/cart/'; // GET
export const AddToCartEndpoint = baseUrl + '/api/cart/'; // POST
export const DeleteProductCartEndpoint = baseUrl + '/api/cart/'; // DELETE
export const UpdateProductCartEndpoint = baseUrl + '/api/cart/'; // PUT
export const ValidateCartEndpoint = baseUrl + '/api/cart/validate'; // GET
export const DeleteAllCartProductsEndpoint = baseUrl + '/api/cart/all'; // DELETE
export const UpdateProductCartTempIdToUserId = baseUrl + '/api/cart/temp'; // PUT
export const GetCartExtraInfoEndpoint = baseUrl + '/api/cart/info'; // PUT

// ORDER

export const CreateOrderEndpoint = baseUrl + '/api/swrOrder/'; // POST
export const GetOrdersEndpoint = baseUrl + '/api/swrOrder'; // GET
export const UpdateOrdersEndpoint = baseUrl + '/api/swrOrder/'; // PUT


// TRANSLATIONS

export const GetTranslationsEndpoint = baseUrl + '/api/translation'; // GET