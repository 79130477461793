import React from 'react';
import { Box, Collapse, Button, Text, Stack, Heading, useDisclosure, VStack, Container } from '@chakra-ui/react';
import NavBar from '../components/NavBar';
import { faqs } from '../variables/FAQs';
import { useTranslation } from '../contexts/TranslationContext';


interface FAQItemProps {
	question: {
		english: string;
		spanish: string;
		portuguese: string;
		chinese: string;
		japanese: string;
		korean: string;
	};
	answer: {
		english: string;
		spanish: string;
		portuguese: string;
		chinese: string;
		japanese: string;
		korean: string;
	};
	answer2: {
		english: string;
		spanish: string;
		portuguese: string;
		chinese: string;
		japanese: string;
		korean: string;
	};
	answer3: {
		english: string;
		spanish: string;
		portuguese: string;
		chinese: string;
		japanese: string;
		korean: string;
	};
}

export default function FAQs() {

	type Language = 'english' | 'spanish' | 'portuguese' | 'chinese' | 'japanese' | 'korean';
	const { translations, language } = useTranslation();


	const FAQItem: React.FC<FAQItemProps> = ({ question, answer, answer2, answer3 }) => {
		const { isOpen, onToggle } = useDisclosure();
		
		return (
			<Box
				borderWidth={1}
				borderRadius="lg"
				overflow="hidden"
				shadow="md"
				bg="white"
				_hover={{ shadow: 'lg' }}
				width="100%"
			>
				<Button
					onClick={onToggle}
					width="100%"
					height={{ base: 'auto', md: 'auto' }}
					textAlign="left"
					fontWeight="bold"
					colorScheme="gray"
					variant="ghost"
					borderRadius={0}
					px={4}
					py={3}
					whiteSpace="normal" // Permitir saltos de línea en texto largo
					overflow="hidden"
					fontSize={{ base: 'md', md: 'lg' }} // Tamaño de fuente más pequeño en mobile
					color={'Black'}
				>
					{question[language as Language]}
				</Button>
				<Collapse in={isOpen}>
					<Stack spacing={3} mt={2} p={4} bg="gray.50">
						<Text fontSize={{ base: 'md', md: 'lg' }}>{answer[language as Language]}</Text>
						<Text fontSize={{ base: 'md', md: 'lg' }}>{answer2[language as Language]}</Text>
						<Text fontSize={{ base: 'md', md: 'lg' }}>{answer3[language as Language]}</Text>
					</Stack>
				</Collapse>
			</Box>
		);
	};

	return (
		<Container maxW="full" centerContent>
			<NavBar isHome={false} />
			<VStack spacing={6} pt={20} align="stretch" width="100%">
				<Heading as="h2" size="lg" textAlign="center" fontSize={{ base: 'xl', md: '4xl' }} mb={4}>
					{translations['Frequently Asked Questions']?.[language] || 'Preguntas Frecuentes'}
				</Heading>
				<Stack spacing={3} width="100%">
					{faqs.map((faq, index) => (
						<FAQItem key={index} question={faq.question} answer={faq.answer} answer2={faq.answer2} answer3={faq.answer3} />
					))}
				</Stack>
			</VStack>
		</Container>
	);
}
