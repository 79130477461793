// src/analytics.ts
import ReactGA from 'react-ga4';

/**
 * Inicializa Google Analytics con el Measurement ID.
 * @param measurementId - El Measurement ID de Google Analytics.
 */
export const initGA = (measurementId: string): void => {
	ReactGA.initialize(measurementId);
};

/**
 * Registra una vista de página en Google Analytics.
 * @param pagePath - La ruta de la página que se está viendo.
 */
export const logPageView = (pagePath: string): void => {
	ReactGA.send({ hitType: 'pageview', page: pagePath });
};
