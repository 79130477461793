import React from 'react';
import { Box, Flex, Text} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import '@fontsource/work-sans';
import { GoBackArrow, Home } from '../assets/icons/iconList';


interface Props {
  title: string
  goBackTo: string
  screenID: number  // 0: Cart, 1: Datos de contacto
  buttonName: string | undefined
  buttonColor: string | undefined
  buttonAction: () => void | undefined
}

export default function MobileHeader ( props: Props ) {

	const navigate = useNavigate();
	// let cartContext = useContext(CartContext)

	return (

		<Box backgroundColor={'white'} zIndex={1} position={'fixed'} width={'100%'} boxShadow={'md'} paddingTop={'15%'}>
			<Box py={'3%'} px={'3%'} alignItems={'center'}>
                
				<Flex justify={'space-between'} width={'100%'} align={'center'}>
                    
					<Flex width={'15%'} justify={'space-evenly'} align={'center'} >
						<GoBackArrow boxSize={'2.5vh'} onClick={() => navigate(props.goBackTo)}/>
						{ props.screenID === 0 &&
                            <Home boxSize={'2.7vh'} onClick={() => navigate(props.goBackTo)}/>
						}
						{ props.screenID === 1 &&
                            <Home boxSize={'2.7vh'} onClick={() => navigate(props.goBackTo)}/>
						}
					</Flex>

					<Text fontFamily={'work sans'} fontWeight={'bold'} fontSize={'2.5vh'}>{props.title}</Text>

					{ props.screenID === 0 ? 
						<Text mr={'2'} fontFamily={'Work sans'} fontSize={'1.5vh'} fontWeight={'bold'} color={props.buttonColor} onClick={() => props.buttonAction()}>
							{props.buttonName}
						</Text> :
						<Box width={'15%'}></Box>
					}
				</Flex>

			</Box>
		</Box>
	);
}
