import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginRegister from '../screens/LoginRegister';
import TermsAndConditions from '../screens/TermsAndConditions';
import RefundPolicy from '../screens/RefundPolicy';
import Home from '../screens/Home';
import Cart from '../screens/Cart';
import Profile from '../screens/Profile';
import FAQs from '../screens/FAQs';
import Orders from '../screens/Orders';

import CartProvider from '../contexts/CartContext';
import TranslationProvider from '../contexts/TranslationContext';
import SearchProduct from '../screens/SearchProduct';
import OrderDetails from '../screens/OrderDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchProvider from '../contexts/SearchContext';
import GoogleAnalytics from '../components/GoogleAnalytics';

function MainRoutes() {
	return (
		<div>
			<TranslationProvider>
				<SearchProvider>
					<CartProvider>
						<BrowserRouter>
							{/* <NavBar/> */}
							<GoogleAnalytics/>

							<div>
								<Routes>
									<Route path='/' element={<Home companyId={2}/>} />
									<Route path='/signin' element={<LoginRegister />} />
									<Route path='/terms' element={<TermsAndConditions />} />
									<Route path='/cart' element={<Cart />} />
									<Route path='/refund' element={<RefundPolicy />} />
									<Route path='/profile' element={<Profile />} />
									<Route path='/faqs' element={<FAQs />} />
									<Route path='/orders' element={<Orders />} />
									<Route path='/orderDetails/:orderId' element={<OrderDetails />} />
									<Route path='/search' element={<SearchProduct />} />
								</Routes>

							</div>
							<ToastContainer />

						</BrowserRouter>
					</CartProvider>
				</SearchProvider>
			</TranslationProvider>
		</div>
	);
}

export default MainRoutes;
