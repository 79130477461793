import { GetTranslationsEndpoint } from '../variables/Connections';

export async function getTranslations(): Promise<any> {
	try {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};

		const response = await fetch(GetTranslationsEndpoint, requestOptions);

		if (response.ok) {
			const translations = await response.json();
			// console.log('translationsAPI', translations);
			return translations.data;
		} else {
			return {};
		}

	} catch (error) {
		console.log('error getTranslations ', error);
		return [];
	}
}