import topProduct1 from '../assets/topProduct1.png';
import topProduct2 from '../assets/topProduct2.png';
import topProduct3 from '../assets/topProduct3.png';
import topProduct4 from '../assets/topProduct4.jpg';
import topProduct5 from '../assets/topProduct5.jpg';

export const categoriesMenu = [
	{
		id: 0,
		category: '全部',
		translation: 'Todo',
		imageTitle: '全',
		image: '',
		categoriesNames: []
	},
	{
		id: 1,
		category: '米/面/粉',
		translation: 'Arroz/Harina/Harina',
		imageTitle: '',
		image: topProduct1,
		categoriesNames: ['米面']
	},
	{
		id: 2,
		category: '罐头/咸菜',
		translation: 'Conservas/Ensalada de mariscos',
		imageTitle: '',
		image: topProduct2,
		categoriesNames: ['咸菜']
	},
	{
		id: 3,
		category: '调料/酱料',
		translation: 'Condimentos/Salsas',
		imageTitle: '',
		image: topProduct3,
		categoriesNames: ['酱料', '调料']
	},
	{
		id: 4,
		category: '干货/保健品',
		translation: 'Secos/Suplementos',
		imageTitle: '',
		image: topProduct4,
		categoriesNames: ['干货', '保健品']
	},
	{
		id: 5,
		category: '零食/饮料',
		translation: 'Snacks/Bebidas',
		imageTitle: '',
		image: topProduct5,
		categoriesNames: ['零食', '饮料']
	},
	// {
	//     id: 6,
	//     category: "即将上架",
	//     translation: "Próximamente",
	//     imageTitle: "新货",
	//     image: ""
	// },
	// {
	//     id: 7,
	//     category: "上货架",
	//     translation: "En el estante",
	//     imageTitle: "超市",
	//     image: ""
	// },
	// {
	//     id: 8,
	//     category: "特价活动",
	//     translation: "Oferta especial",
	//     imageTitle: "特价",
	//     image: ""
	// }
];
