// Esto lo valida el back tambien pero para no mandar 95 request un par de cosas las hago en front..

import { CartRules, RuleResult } from '../../interfaces/interfaces';
import { fireAlert } from './TichAlert';

type TichCartMessages = {
	[key: string]: string;
  }

const TICH_CART_MESSAGES: TichCartMessages = {
	minPurchase: '未达到最低额度',
	group: '购物车已有此产品',
	maxQuantity: '产品数量调整到限购数'
};

export function validateMaxQty(qty: number, maxQty: number): number {
	return !maxQty || qty <= maxQty  ? qty : maxQty;
}

export function ruleValidationPasses(type: string, rules: CartRules): boolean {
	const ruleFound  = rules.rulesResult.find((rule: RuleResult) => rule.ruleDetails.type === type);
	return ruleFound ? ruleFound.ruleDetails.ok : false;
}

export function showRulesAlert(rules?: CartRules): void {
	if(!rules) return;
	console.log('holis',rules.rulesResult);
	
	rules.rulesResult.forEach((rule) => {
		
		if(rule.status !== 0) fireAlert(TICH_CART_MESSAGES[rule.ruleDetails.type],'error', true);
	});
}