import React, { useState } from 'react';
import { Box, Input, InputGroup, InputRightAddon, Text, Flex, Icon, Checkbox, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import '@fontsource/work-sans';

// import { PasswordVisibleIcon } from '../assets/icons/iconList';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { register } from '../api/auth';
import { fireAlert } from '../helpers/Tich/TichAlert';
import { ERROR_MSG } from '../helpers/ErrorHandler';
import { useTranslation } from '../contexts/TranslationContext';

type Props = {
	setSelected: React.Dispatch<React.SetStateAction<number>>,
	isMobile: boolean
}

export default function Register({ setSelected, isMobile }: Props) {

	const { translations, language } = useTranslation();
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [registerData, setRegisterData] = useState({ username: '', password: '', address: '', province: '', phone: '', whatsapp: '', wechat: '', roleId: 1, taxId: '', languageId: 1 });

	const navigate = useNavigate();

	async function checkRegisterData() {

		if (registerData.username === '') {
			fireAlert(translations['Please']?.[language] + translations['Enter a valid name']?.[language] || 'Por favor ingrese un nombre válida', 'error');
			return;
		}
		if (registerData.phone === '') {
			fireAlert(translations['Please']?.[language] + translations['Enter a valid phone number']?.[language] || 'Por favor ingrese un número de teléfono válido', 'error');
			return;
		}
		if (registerData.password === '') {
			fireAlert(translations['Please']?.[language] + translations['Enter a valid password']?.[language] || 'Por favor ingrese una contraseña válida', 'error');
			return;
		}
		if (!acceptedTerms) {
			fireAlert(translations['Please']?.[language] + translations['Accept the terms and conditions']?.[language] || 'Por favor acepte los términos y condiciones', 'error');
			return;
		}

		const registerResult = await register(registerData);
		
		if (registerResult) {
			if(registerResult.error){
				fireAlert(ERROR_MSG[registerResult.error.code], 'error');
				return;
			}
			fireAlert(translations['Successful registration']?.[language] ||'Registro exitoso', 'success');
			navigate('/');
		}
	}

	return (

		<Box>
			{isMobile ?
				<Box backgroundColor={'white'} marginTop={'4vh'}>
					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}> {translations['Name']?.[language] || 'Nombre'} </Text>
						<Input fontFamily={'Work sans'} borderColor={'#A7A6A6'} type='text' onChange={(e) => setRegisterData({ ...registerData, username: e.target.value })} />
					</Box>
					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}> {translations['Cellphone number']?.[language] || 'Número de celular'} </Text>
						<Input fontFamily={'Work sans'} borderColor={'#A7A6A6'} type='tel' onChange={(e) => setRegisterData({ ...registerData, phone: e.target.value })} />
					</Box>
					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'} > {translations['Password']?.[language] || 'Contraseña'} </Text>

						<InputGroup>
							<Input
								type={showPassword ? 'text' : 'password'}
								fontFamily={'Work Sans'}
								borderRightWidth={'0px'}
								min={4}
								borderColor={'#A7A6A6'}
								onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
							/>
							<InputRightAddon
								backgroundColor={'white'}
								borderLeftWidth={'0px'}
								borderColor={'#A7A6A6'}
							>
								<Icon as={showPassword ? ViewIcon : ViewOffIcon} px={1} boxSize={'3.5vh'} onClick={() => setShowPassword(!showPassword)} />
								{/* <PasswordVisibleIcon boxSize={'3.5vh'} marginTop={'1vh'} onClick={() => setShowPassword(!showPassword)} /> */}
							</InputRightAddon>
						</InputGroup>

					</Box>
					{/* <Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}> {translations['Address']?.[language] || 'Dirección'} </Text>
						<Input fontFamily={'Work sans'} borderColor={'#A7A6A6'} type='text' onChange={(e) => setRegisterData({ ...registerData, address: e.target.value })} />
					</Box>
					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}> {translations['City/Provicen']?.[language] || 'Localidad/Provincia'} </Text>
						<Input fontFamily={'Work sans'} borderColor={'#A7A6A6'} type='text' onChange={(e) => setRegisterData({ ...registerData, province: e.target.value })} />
					</Box> */}
					
					{/* <Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}> WhatsApp </Text>
						<Input fontFamily={'Work sans'} borderColor={'#A7A6A6'} type='tel' onChange={(e) => setRegisterData({ ...registerData, whatsapp: e.target.value })} />
					</Box> */}

					{/* <Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}> 微信ID号: (*) </Text>
						<Input fontFamily={'Work sans'} borderColor={'#A7A6A6'} type='number' onChange={(e) => setRegisterData({ ...registerData, wechat: e.target.value })} />
					</Box>

					<Box backgroundColor={'white'} marginTop={'2vh'} >
						<Text fontFamily={'Work sans'} >* WhatsApp 或 微信 至少一个</Text>
					</Box> */}


					<Flex marginTop={'3vh'}>
						<Text fontSize={'2vh'}>{translations['Do you already have an account?']?.[language] || '¿Ya tienes una cuenta?'}</Text>
						<Text fontSize={'2vh'} color={'#0993ff'} onClick={() => setSelected(0)}> {translations['Log in']?.[language] || 'Iniciar sesión'}</Text>
					</Flex>
					
					<Flex alignSelf={'center'} justifyContent={'center'} marginTop={'2vh'}>
						<Checkbox isChecked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)}>
							<Text> {translations['I accept'][language] || 'Acepto los'}
								<Link color="#0993ff" paddingLeft={'2'} onClick={() => navigate('/terms')} textDecor="underline" style={{ cursor: 'pointer' }}>
									{translations['terms and conditions']?.[language] || 'términos y condiciones'}
								</Link>
							</Text>
						</Checkbox>
					</Flex>

					<Box
						onClick={() => checkRegisterData()}
						backgroundColor={'#0993ff'}
						textAlign={'center'}
						marginLeft={'4vh'}
						marginRight={'4vh'}
						marginTop={'3vh'}
						borderRadius={'1vh'}
						paddingY={'.5vh'}
					>
						<Text color={'white'} fontSize={'2.5vh'}>{translations['Sign up']?.[language] || 'Registrarme'}</Text>
					</Box>
				</Box>
				:

				<Box backgroundColor={'white'} marginTop={'4vh'}>

					<Box backgroundColor={'white'} marginTop={'2vh'}>
						<Text fontFamily={'Work sans'}>{translations['Name']?.[language] || 'Nombre'}</Text>
						<Input fontFamily={'Work sans'} height={'4vh'} borderColor={'#A7A6A6'} type='text' onChange={(e) => setRegisterData({ ...registerData, username: e.target.value })} />
					</Box>

					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'} > {translations['Cellphone number']?.[language] || 'Número de celular'} </Text>
						<Input fontFamily={'Work sans'} height={'4vh'} borderColor={'#A7A6A6'} type='tel' onChange={(e) => setRegisterData({ ...registerData, phone: e.target.value })} />
					</Box>

					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'}>{translations['Password']?.[language] || 'Contraseña'}</Text>
						<InputGroup>
							<Input
								type={showPassword ? 'text' : 'password'}
								fontFamily={'Work Sans'}
								min={4}
								height={'4vh'}
								fontSize={'2.3vh'}
								borderColor={'#A7A6A6'}
								onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
							/>
							<InputRightAddon
								justifyContent={'center'}
								backgroundColor={'white'}
								borderLeftWidth={'0px'}
								height={'4vh'}
								borderColor={'#A7A6A6'}
							>
								<Icon as={showPassword ? ViewIcon : ViewOffIcon} px={1} boxSize={'3.5vh'} onClick={() => setShowPassword(!showPassword)} />
								{/* <PasswordVisibleIcon boxSize={'3vh'} marginLeft={'1'} marginTop={'1vh'} onClick={() => setShowPassword(!showPassword)} /> */}
							</InputRightAddon>
						</InputGroup>
					</Box>

					{/* 
					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'} > {translations['Address']?.[language] || 'Dirección'} </Text>
						<Input fontFamily={'Work sans'} height={'4vh'} borderColor={'#A7A6A6'} type='text' onChange={(e) => setRegisterData({ ...registerData, address: e.target.value })} />
					</Box>
					
					<Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'} > {translations['City/Province']?.[language] || 'Localidad/Provincia'} </Text>
						<Input fontFamily={'Work sans'} height={'4vh'} borderColor={'#A7A6A6'} type='text' onChange={(e) => setRegisterData({ ...registerData, province: e.target.value })} />
					</Box> */}
					

					{/* <Box backgroundColor={'white'} marginTop={'4vh'}>
						<Text fontFamily={'Work sans'} > WhatsApp </Text>
						<Input fontFamily={'Work sans'} height={'4vh'} borderColor={'#A7A6A6'} type='tel' onChange={(e) => setRegisterData({ ...registerData, whatsapp: e.target.value })} />
					</Box> */}
					{/* <Flex>
						<Box backgroundColor={'white'} marginTop={'4vh'}>
							<Text fontFamily={'Work sans'} > 微信ID号: (*) </Text>
							<Input fontFamily={'Work sans'} height={'4vh'} borderColor={'#A7A6A6'} type='number' onChange={(e) => setRegisterData({ ...registerData, wechat: e.target.value })} />
						</Box>
					</Flex>

					<Box backgroundColor={'white'} marginTop={'2vh'} >
						<Text fontFamily={'Work sans'} >* WhatsApp 或 微信 至少一个</Text>
					</Box> */}


					<Flex marginTop={'3vh'}>
						<Text fontSize={'2vh'} mr={1}>{translations['Do you already have an account?']?.[language] || '¿Ya tienes una cuenta?'}</Text>
						<Text fontSize={'2vh'} color={'#0993ff'} onClick={() => setSelected(0)}>{translations['Log in']?.[language] || 'Iniciar sesión'}</Text>
					</Flex>
					
					<Flex alignSelf={'center'} justifyContent={'center'} marginTop={'3vh'}>
						<Checkbox isChecked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)}>
							<Text> {translations['I accept'][language] || 'Acepto los'}
								<Link color="#0993ff" paddingLeft={'2'} onClick={() => navigate('/terms')} textDecor="underline" style={{ cursor: 'pointer' }}>
									{translations['terms and conditions']?.[language] || 'términos y condiciones'}
								</Link>
							</Text>
						</Checkbox>
					</Flex>

					<Box
						cursor={'pointer'}
						onClick={() => checkRegisterData()}
						backgroundColor={'#0993ff'}
						textAlign={'center'}
						marginLeft={'4vh'}
						marginRight={'4vh'}
						marginTop={'3vh'}
						borderRadius={'1vh'}
						paddingY={'.5vh'}
					>
						<Text color={'white'}>{translations['Sign up']?.[language] || 'Registrarme'}</Text>
					</Box>
				</Box>
			}
		</Box>
	);
}
